import React from 'react';

export function GetColumnDataForProductType(type) {



    if (type == "Products") {
        return [
            {
                Header: ' ',
                Cell:row=><></>,     
                draggable: false,       
                id:"mainHeader",
                columns: [
                {
                    Header: 'Fnsku',
                    accessor: 'id'
                },
                {
                    Header: 'Asin',
                    accessor: 'asin'
                },                 
                {
                    Header: 'Image',
                    accessor: 'image',
                    Cell:row=><><img className="table-image" src={row.row.values.imageURL} />{row.row.values.imageURL}</>,            
                    draggable: false,
                    sortable: false,
                    disableSortBy:true,
                },
                {
                    Header: 'Price',
                    accessor: 'price'
                },                                                 
                {
                    Header: 'Condition',
                    accessor: 'condition'
                },                 
                {
                    Header: 'Fulfillment Channel',
                    accessor: 'fulfillmentChannel'
                },
                {
                    Header: 'Merchant Shipping Group',
                    accessor: 'merchantShippingGroup'
                },
                {
                    Header: 'Will Ship Internationally',
                    accessor: 'willShipInternationally'
                },
                {
                    Header: 'Expedited Shipping',
                    accessor: 'expeditedShipping'
                },
                {
                    Header: 'Bid for Featured Placement',
                    accessor: 'bidForFeaturedPlacement'
                },
                {
                    Header: 'Name',
                    accessor: 'name',
                    minWidth:300
                },    
                {
                    Header: 'Available',
                    accessor: 'afnFulfillableQuantity'
                },
                {
                    Header: 'Unsellable',
                    accessor: 'afnUnsellableQuantity'
                },                
                {
                    Header: 'Reserved',
                    accessor: 'afnReservedQuantity'
                },               
                {
                    Header: 'Warehouse',
                    accessor: 'afnWarehouseQuantity'
                },                
                {
                    Header: 'Total',
                    accessor: 'afnTotalQuantity'
                },                 
                {
                    Header: 'Inbound',
                    accessor: 'afnInboundWorkingQuantity'
                },                 
                {
                    Header: 'InboundShipped',
                    accessor: 'afnInboundShippedQuantity'
                },            
                {
                    Header: 'InboundReceiving',
                    accessor: 'afnInboundReceivingQuantity'
                },              
                {
                    Header: 'ReservedFuture',
                    accessor: 'afnReservedFutureSupply'
                },                      
                {
                    Header: 'Researching',
                    accessor: 'afnResearchingQuantity'
                },       
                {
                    Header: 'Future Supply Buyable',
                    accessor: 'afnFutureSupplyBuyable'
                }        
                ]
            }
        ];
    }
    

    if (type == "Orders") {
        return [
            {
                Header: ' ',
                Cell:row=><></>,     
                draggable: false,       
                id:"mainHeader",
                columns: [
                    {
                    Header: 'Purchase Date',
                    accessor: 'PurchaseDate'
                    },
                    {
                    Header: 'Order Id',
                    accessor: 'id'
                    },    
                    {
                    Header: 'Asin',
                    accessor: 'asin'
                    },  
                    {
                    Header: 'OrderState',
                    accessor: 'OrderState'
                    },  
                    {
                    Header: 'OrderZipCode',
                    accessor: 'OrderZipCode'
                    },      
                    {
                    Header: 'Number of Items Shipped',
                    accessor: 'NumberOfItemsShipped'
                    },                                                     
                    {
                    Header: 'Number of Items Unshipped',
                    accessor: 'NumberOfItemsUnshipped'
                    },        
                    {
                    Header: 'Order Status',
                    accessor: 'OrderStatus'
                    },                                                             
                    {
                    Header: 'Is Business Order',
                    accessor: 'IsBusinessOrder'
                    },
                    {
                    Header: 'Is Global Express',
                    accessor: 'IsGlobalExpress'
                    },
                    {
                    Header: 'Is Premium Order',
                    accessor: 'IsPremiumOrder'
                    },
                    {
                    Header: 'Item Note',
                    accessor: 'itemNote'
                    },    
                    {
                    Header: 'Item is Marketplace',
                    accessor: 'itemIsMarketplace'
                    },        
                    {
                    Header: 'Earliest Ship Date',
                    accessor: 'EarliestShipDate'
                    },    
                    {
                    Header: 'Is Prime',
                    accessor: 'IsPrime'
                    },        
                    {
                    Header: 'Is Replacement Order',
                    accessor: 'IsReplacementOrder'
                    },            
                    {
                    Header: 'Is Sold By AB',
                    accessor: 'IsSoldByAB'
                    },           
                    {
                    Header: 'Last Update Date',
                    accessor: 'LastUpdateDate'
                    },                              
                    {
                    Header: 'Latest Ship Date',
                    accessor: 'LatestShipDate'
                    },                                  
                    {
                    Header: 'MarketplaceId',
                    accessor: 'MarketplaceId'
                    }                                         
                ]
            }
        ];
    }


    return [
        {
            Header: ' ',
            Cell:row=><></>,     
            draggable: false,       
            id:"mainHeader",
            columns: [
            {
                Header: 'Name',
                accessor: 'name'
            }]
        }
    ];
}