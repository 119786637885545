import React, { useState, useContext } from "react"
import { UserProvider } from './UserContext'

import logo from './logo.svg';
import './App.css';
import 'semantic-ui-css/semantic.min.css';

import Loader from './components/Loader';
import Main from './components/Main';
import Menu from './components/Menu';
import useWindowDimensions from './hooks/useWindowDimensions';

import Amplify, {Analytics, API} from 'aws-amplify';
import awsconfig from './aws-exports.js';
import { ThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
// import Auth from '@aws-amplify/auth';
// import { Hub } from 'aws-amplify';

import { AmplifyAuthenticator, AmplifySignUp, AmplifySignOut, AmplifySignIn, AmplifyConfirmSignUp } from '@aws-amplify/ui-react';
import Button from '@material-ui/core/Button';

import { I18n } from "aws-amplify";
import { Translations } from "@aws-amplify/ui-components";

import UserContext from "./context/user";
import ReCAPTCHA from "react-google-recaptcha";

// window.LOG_LEVEL = 'DEBUG';

I18n.putVocabulariesForLanguage("en-US", {
  [Translations.SIGN_IN_WITH_AWS]: "Sign in using Amazon, Google, or Facebook"
});


Amplify.configure(awsconfig);
Analytics.disable();

// Display more detail in the console, leave this off for production
// window.LOG_LEVEL='DEBUG';

const primaryColor = "#2185d0"; //#54b4f8 // #2185d0 // rgb(84, 180, 248)

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: primaryColor,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#0066ff',
      main: '#0044ff',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  }
});


function App() {
    
    // const [user, updateUser] = React.useState(null);
    const { height, width } = useWindowDimensions();
    const { signedIn, user } = useContext(UserContext)
    const [ reCaptchaVerified, setReCaptchaVerified ] = useState(false);
    
    React.useEffect(() => {

    //   console.log("user",user);
    //   if (user == null) {
    //     console.log("Auth.currentAuthenticatedUser: ")
    //     Auth.currentAuthenticatedUser()
    //     .then(user => {
    //       updateUser(user);
    //     })
    //     .catch(() => console.log('No signed in user.'));

    //     Hub.listen('auth', data => {
    //     switch (data.payload.event) {
    //       case 'signIn':
    //         return updateUser(data.payload.data);
    //       case 'signOut':
    //         return updateUser(null);
    //     }
    //   });
    //   }
      console.log("signedIn", signedIn)
    }, [signedIn]);


  function getIsMobile() {
    if (width < 600) {
      return true;
    }
    return false;
  }

  async function onChange(token) {
    
    console.log("Onchange:", token);

    let postMsg = {
      body: {
        challengeAnswer:token
        }
    }
      
      //  const result = await API.post("LambdaAPIUser", "/app/VerifyMWSAuthToken", postMsg).then(async response => {

      try {

      
      const result = await API.post("PublicAPI", "/app/VerifyReCaptcha", postMsg).then(async response => {                   
          if (response && response.body && response.body.challengeAnswer) {
            if (response.body.challengeAnswer == token ) {
              setReCaptchaVerified(true);
            }
           }
          
          return response;
        });

      }
      catch (e) {
        console.log("Error:", e);
      }
  }

  
  function getDrawerWidthFromWindowDimensions() {      
    let minimized = false;
     if (getIsMobile()) {
        return "0px";
      } else {
        if (minimized) {
          return "64px";
        } else {
          return "165px";
        } 
      }
    }

   // If the user is log in render this portion
   if (user) {
    console.log("RETURN APP: ", user);
      return (
        <div>
          <ThemeProvider theme={theme} >
            <UserProvider>
              <Main drawerWidth={getDrawerWidthFromWindowDimensions()} isMobile={getIsMobile()} />
              <Menu drawerWidth={getDrawerWidthFromWindowDimensions()} isMobile={getIsMobile()} />
            </UserProvider>
          </ThemeProvider>
        </div>
      )
    }

    // If not logged in then render the following 
    return (

    <div className="App">      

      <Loader fadeOut={true}/>
      <ThemeProvider theme={theme} >
    
      <img style={{width:"120px"}} src="images/icons/icon.png"/>
        
    {/* <Button onClick={()=>{

      const grecaptcha = 
              grecaptcha.ready(async function() {
                
                grecaptcha.execute('reCAPTCHA_site_key', {action: 'submit'}).then(async function(token) {

                let postMsg = {
                    body: {
                      challengeAnswer:token
                    }
                }

                const result = await API.post("LambdaAPIUser", "/app/GetOrder", postMsg).then(async response => {                   
                    console.log("Response:", response);
                  });                  
                  console.log("ReCaptcha Result:", result);
                });
              });  

    }} class="continue-sign-in">
      Continue To Sign In
    </Button>
 */}

 {reCaptchaVerified
  ?  <div className="authenticator" style={{paddingTop:"20px"}}>    
  {/* <Button onClick={() => Auth.federatedSignIn({provider: 'Amazon'})}>Amazon</Button>
  <Button onClick={() => Auth.federatedSignIn({provider: 'Google'})}>Facebook</Button>
  <Button onClick={() => Auth.federatedSi   gnIn({provider: 'Facebook'})}>Google</Button> */}

  <AmplifyAuthenticator usernameAlias="email">
    
    {/* <AmplifyConfirmSignUp
              slot="confirm-sign-up"
              formFields={[
                { type: "username",   
                  label:"Email *",
                  required:true},
                  {
                    type:"code",
                    label:"Enter your code",
                    required:true  
                  }
                
                ]}      
    />
    */}

  <AmplifySignUp
      slot="sign-up"
      usernameAlias="email"
      formFields={[
        { type: "email",   
          label:"Email *",
          required:true,
          placeholder:"Email"
      },
        {
          type: "password",
          label: "Password *",
          required:true,
          placeholder: "Password"
        }            
      ]} 
    />             

    {/* <AmplifySignIn
      slot="sign-in"
      usernameAlias="email"
      formFields={[
        { type: "email",   
          label:"Email *",
          required:true,
          placeholder:"Email"
      }]}
    /> */}

  </AmplifyAuthenticator>
  </div> : <div>
      <div className="recaptcha-form">    
          <ReCAPTCHA
            sitekey="6Lf0aXciAAAAAB2vAUweocuu-9Z1QKcFQhQgLOhf"
            onChange={onChange}
          />          
      </div>
      </div> 

}
     

      </ThemeProvider>

    </div>);
}

export default App;