
import React, {useEffect} from 'react';
import styled from 'styled-components'
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { CircularProgress } from '@material-ui/core';
import Typography from "@material-ui/core/Typography";
import { API } from 'aws-amplify';


const Styles = styled.div`
    padding:40px;
`

let connectionStatus = {};

function AmazonMWSConnect(props) {

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [loading, updateLoading]  = React.useState(false);
    const [checkingKeys, updateCheckingKeys]  = React.useState(false);

    var cardStyle = {
        transitionDuration: '0.3s',        
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"    
    }
    
    useEffect(() => {
        console.log("connectionStatus>");
        console.log(connectionStatus);
        if (connectionStatus && connectionStatus.status == "success") {

        } else {
            checkConnectionStatus();
        }
        
    }, []); //Empty array as second argument
    
    async function checkConnectionStatus() {
        
        if (props.userProps.userSettings && !checkingKeys) {
            updateCheckingKeys(true);
            let postMsg  = {           
                body: {
                    MWSAuthToken:""+props.userProps.userSettings.mwsAuthToken,
                    SellerId:""+props.userProps.userSettings.sellerId
              }      
            };

            const result = await API.post("LambdaAPIUser", "/app/ListMarketplaceParticipations", postMsg).then(async response => {
    
                if (response.ErrorResponse) {
                    return response;
                }
                if (response && response.ListMarketplaceParticipationsResponse) {
   
                    if (response.ListMarketplaceParticipationsResponse.ListMarketplaceParticipationsResult) {
                        let result = response.ListMarketplaceParticipationsResponse.ListMarketplaceParticipationsResult;
                        for (let i = 0; i < result.ListParticipations.Participation.length; i++) {
                            let participation = result.ListParticipations.Participation[i]; 
                            if (participation.MarketplaceId == "ATVPDKIKX0DER") {
                                connectionStatus.status = "success"
                                forceUpdate();
                            }
                        }
                    }
                }    
            }).catch(e =>{console.log(e)})

            updateCheckingKeys(false);

            
        } else {
            console.log("Skip");
        }
    }

    function displayCard() {
        return (<>
            <Card style={cardStyle} className="standard-card">
            <CardHeader title="Amazon MWS" />
            
            {
                checkingKeys ? <>
                                <CardContent>                

                <div className="text-center">
                                    <CircularProgress size={44} color="primary" />
                            </div>
                            </CardContent>
                            <CardActions className="no-drag">            
                            </CardActions>
                </>:<>
                
                <CardContent>                
                <Typography variant="h5" component="h2">Connect</Typography>
                <Typography component="p">Connect to your Amazon Seller Central account</Typography>
                 {
                    loading ? <><div className="text-center">
                                    <CircularProgress size={44} color="primary" />
                                </div>
                                <p className="text-tiny-info text-center">Please wait...</p>
                                <br/>
                              </> : <></>
                }
                {
                    connectionStatus && connectionStatus.status == "success" ? <>Success</> :<></>
                }
            </CardContent>  
            <CardActions className="no-drag">            
                {
                    loading ? <>
                    <Button color="primary" onClick={()=>{updateLoading(false); forceUpdate();}}>Cancel</Button>
                    </>:<Button color="primary" onClick={()=>{updateLoading(true); forceUpdate();}}>
                        
                        {
                            connectionStatus && connectionStatus.status == "success" ? "Refresh" : "Connect"
                        }
                        
                        
                        </Button>
                }                                
            </CardActions>
            </>

            }

        
        </Card>
    </>)
    }
    
    return (
    <Styles>
        {
            displayCard()
        }
    </Styles>
)


}

export default AmazonMWSConnect
