
import React, { useEffect, useContext } from 'react';

import styled from 'styled-components'

import { API } from 'aws-amplify';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import CustomTextField from './CustomTextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';

import * as mutations from '../graphql/mutations';
import * as queries  from '../graphql/queries';

import { graphqlOperation } from "aws-amplify";
import moment from 'moment-timezone';

import AnnouncementOutlinedIcon from '@material-ui/icons/AnnouncementOutlined';

import PermDeviceInformationOutlinedIcon from '@material-ui/icons/PermDeviceInformationOutlined';

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import { Popup } from 'semantic-ui-react'


import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import UserContext from '../UserContext';

const MASTER_TIME = "1970-01-01T00:00:00.000Z";

const Styles = styled.div`

`
const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    buttonSuccess: {
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700],
      },
    },
    fabProgress: {
      color: green[500],
      position: 'absolute',
      top: -6,
      left: -6,
      zIndex: 1,
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  }));


  let initSyncFinished = false;
  let initCount = 0;

  let closed = false;
  let closedCount = 0;
  let hidden = false;

let animateOnLoad = true;
  
function Onboarding(props) {

    const userContext = useContext(UserContext);

    const {statusBarHidden, setStatusBarHidden, drawerWidth} = userContext;
    
    const classes = useStyles();

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);


    const [mwsAuthToken, setMwsAuthToken] = React.useState("");
    const [sellerId, setSellerId] = React.useState("");
    const [phoneNumber, setPhoneNumber] = React.useState("1");

    const [skValue, setSkValue] = React.useState("");

    const [activeStep, setActiveStep] = React.useState(0);
    const [success, setSuccess] = React.useState(false);
    const [init, setInit] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [skipped, setSkipped] = React.useState(new Set());
    const [hasSupportedMarketplace, setHasSupportedMarketplace] = React.useState(false);
    const [inputError, setInputError] = React.useState({mwsAuthToken:false, sellerId:false});
      
    const [state, setState] = React.useState({
        email: true,
        phone: false
      });


    const owner = props.userProps.username;
    const userEmail = props.userProps.email;
    const userPhone = props.userProps.phone_number;
    const userSettings = props.userProps.userSettings;
    const steps = getSteps();
  
    const buttonClassname = clsx({
        [classes.buttonSuccess]: success,
    });


    useEffect(() => {

        initCount++;
        closedCount++;

        if (animateOnLoad && initCount > 2) {
            animateOnLoad = false;
        }

        if (closed && closedCount > 2) {
            closed = false;
            hidden = true;
        }

        console.log("userSettings:", userSettings);

        if (userSettings && props.userProps && props.userProps.owner.length > 0) {
            if (userSettings.mwsAuthToken) {                
                if (activeStep == 0)
                {
                    let activeStep = 1;                    
                    API.graphql(graphqlOperation(queries.listingsByOwnerType, {owner:props.userProps.owner,type:"Sync", id:"master"})).then(async result=>
                    {
                        console.log(result.data);                        
                        if (result.data.listingsByOwnerType.items) {
                            if (result.data.listingsByOwnerType.items[0].status == "syncing") {
                                activeStep = 2;
                            }                               
                            if (result.data.listingsByOwnerType.items[0].status == "finished") {
                                activeStep = 2;
                                initSyncFinished = true;
                                props.updateInitialSyncFunc(true);
                            }
                        }
                        setActiveStep(activeStep);
                        setInit(true);
                    }).catch( e => {                 
                        setInit(true);
                        setActiveStep(activeStep);
                    })
                }
            }
        } else if (props.userProps.owner != undefined){
            // setInit(true);
        }
      }, []); //Empty array as second argument


    const isStepOptional = (step) => {
      return step === 1;
    };
 
  
    function supportedMarketplace(marketplaceId) {

        console.log("supportedMarketplace:"+ marketplaceId)
        //US
        if (marketplaceId == "ATVPDKIKX0DER") {
            return true;
        }

        return false;
     }

      function getSteps() {
        return ['MWS Auth Token', 'Setup Alerts', 'Sync Active Inventory'];
      }
      
    
      async function checkMWSKeysAreValid() {
        console.log("checkMWSKeysAreValid");
        let postMsg  = {           
            body: {
                MWSAuthToken:mwsAuthToken,
                SellerId:sellerId
          }      
        };
        
        const result = await API.post("LambdaAPIUser", "/app/VerifyMWSAuthToken", postMsg).then(async response => {

            console.log("Verify token response");
            console.log(response);

            if (response.ErrorResponse) {
                console.log(response.ErrorResponse.Error.Message);
                props.toastError(response.ErrorResponse.Error.Message);    
                // setLoading(false);
                // return response;
            }


        });

      }

      async function checkMWSParticipations() {                
        console.log("checkMWSParticipations");
        setLoading(true);

        let postMsg  = {           
            body: {
                MWSAuthToken:mwsAuthToken,
                SellerId:sellerId
          }      
        };
        
        const result = await API.post("LambdaAPIUser", "/app/ListMarketplaceParticipations", postMsg).then(async response => {

            if (response.ErrorResponse) {
                console.log(response.ErrorResponse.Error.Message);
                props.toastError(response.ErrorResponse.Error.Message);    
                setLoading(false);
                return response;
            }
// <?xml version="1.0"?>
// <ErrorResponse xmlns="lhttps://mws.amazonservices.com/Sellers/2011-07-01">
//   <Error>
//     <Type>Sender</Type>
//     <Code>AccessDenied</Code>
//     <Message>Access to Sellers.ListMarketplaceParticipations is denied</Message>
//   </Error>
//   <RequestID>32c61e45-c6ff-42fc-9f00-a077731c6f15</RequestID>
// </ErrorResponse>

                let timeNowString = moment().toISOString();
                console.log(timeNowString);

                let inputObject = {
                    input: 
                    {
                        owner:props.userProps.owner,
                        createdAt:MASTER_TIME,
                        type:"Settings",
                        id:"master",
                        mwsAuthToken:mwsAuthToken,
                        sellerId:sellerId
                    }
                };

                console.log(inputObject);

            if (response && response.ListMarketplaceParticipationsResponse) {
                console.log(response.ListMarketplaceParticipationsResponse);
                if (response.ListMarketplaceParticipationsResponse.ListMarketplaceParticipationsResult) {
                    let result = response.ListMarketplaceParticipationsResponse.ListMarketplaceParticipationsResult;
                    for (let i = 0; i < result.ListParticipations.Participation.length; i++) {
                        let participation = result.ListParticipations.Participation[i];                        
                        
                                if (supportedMarketplace(participation.MarketplaceId)) {
                                    console.log("Found supported market place");
                                    setHasSupportedMarketplace(true);
                                    await API.graphql(graphqlOperation(mutations.createUserItem, inputObject)).then(result=>{console.log(result);
                                        setActiveStep(1);
                                    }).catch(error => {
                                        console.log("ERROR");
                                        console.log(error);
                                        if (error.errors[0]) {
                                            props.toastError(error.errors[0].message);        
                                        }
                                    })

                                    setPhoneNumber(""); 
                                    

                                    return true;
                                }else {
                                    console.log("Did not find supported market place");
                                }
                            // }
                        // }
                    }
                }

            }
            return response;
          }).catch(e=>{              
            
            console.log(e);

        });                      
        setLoading(false);
      }


    function checkValuesAndSubmit() {        
            
        console.log("checkValuesAndSubmit");
        console.log("activeStep:"+activeStep);

        if (activeStep == 0) {
            if (sellerId == "") {
                setInputError({...inputError, sellerId:true});
                return;
            }else {
                setInputError({...inputError, sellerId:false});
            }
            if (mwsAuthToken == "") {
                setInputError({...inputError, mwsAuthToken:true});
                return;
            } else {
                setInputError({...inputError, mwsAuthToken:false});
            }
            checkMWSParticipations();
        }

        if (activeStep == 1) {
            setActiveStep(2);
            startSync();
        }

        if (activeStep == 2) {
            closed = true;
            setStatusBarHidden(false);
            setActiveStep(3);
        }

      }

    async function startSync() {

        await API.graphql(graphqlOperation(queries.listingsByOwnerType,
            {owner:props.userProps.owner,type:"Sync",id:"master"})).then(async result=>
            {
                if (result.data.getUserItem) {         

                await API.graphql(graphqlOperation(mutations.updateUserItem, {input: 
                    {
                        owner:props.userProps.owner,
                        type:"Sync",
                        id:"master",
                        status:"syncing"
                    }})).then(result=>{console.log(result)}).catch(error => {
                        console.log("ERROR");
                        console.log(error);
                        if (error.errors[0]) {
                            props.toastError(error.errors[0].message);        
                    }
                })                    
            } else {

                console.log("Start create user item")

                await API.graphql(graphqlOperation(mutations.createUserItem, {input: 
                    {
                        owner:props.userProps.owner,
                        type:"Sync",
                        id:"master",
                        status:"syncing",
                        createdAt:MASTER_TIME
                    }})).then(result=>{               
                        console.log("Finished create user item");
                        console.log(result)}).catch(error => {
                        console.log("ERROR");
                        console.log(error);
                        if (error.errors[0]) {
                            props.toastError(error.errors[0].message);        
                        }
                })
                
            }
            }).catch(e=>{console.log(e)});
      }


      function getStepClass(stepNumber) {
            if (hasSupportedMarketplace) {
                return "";
            }
         return "";
      }


      function getButtonNameFromStep() {
        if (activeStep == 0) {
            return "Next";
        }
        if (activeStep == 1) {
            return "Next";
        }
        if (activeStep == 2) {
            if (initSyncFinished) {
                return "Get Started";
            }
            return "Dismiss";
        }
      }

      function getStepText() {
          if(activeStep == 0) {
            return <p>To get started, we need to connect to your Amazon seller account using Amazon Merchant Web Services. You will provide Amazon our Developer Name and Developer Token by clicking "Get your MWS Auth Token". You will then receive a secret token From Amazon that you must enter below. Enter the Secret key below along with your Seller ID and click next.</p>;                    
          }
          if(activeStep == 1) {
            return<><p>Inventory Shark will alert you when you inventory has finished syncing.</p></>;
          }          
          if(activeStep >= 2) {
            return<div style={{textAlign:"center"}}>
                {
                    initSyncFinished ? <>Your inventory sync has finished! <br/> Click on get started!</> : <>
                    <h3>Congratulations!</h3>
                    <p>Inventory Shark is now securely connected to your Seller Account. Please wait up-to 24 hours for inventory to sync.</p></>
                }
                </div>;
          }
          return <p>&nbsp;</p>;
      }

      function getStepHeader() {
        if (activeStep == 0) {
            return "Setup your Amazon seller account to start tracking your inventory.";
        }

        if (activeStep == 1) {
            return "Choose your notification preferences.";
        }

        if (activeStep >= 2) {
            return "Inventory Shark Successfully Connected!";
        }

        return "";
      }


      function getStepImage() {
        if(activeStep == 0) {
            return <img style={{width:"130px"}} src="/images/icons/dashboard-selected.png"/>;
          }

          if(activeStep == 1) {
            return <AnnouncementOutlinedIcon className="onboarding-large-icon" />;
          }
          
          return <img style={{width:"130px"}} src="/images/icons/icon.png"/>;   
      }


      function getStepButtons() {
        
        if(activeStep == 0) {
            return <>                
                <Popup
                    trigger={<>
                    <p className="link">
                        What Does Providing Access Do?
                    </p>
                    <p className="link">
                        Don't have an Amazon Seller Account?
                    </p></>    
                }
                    content={<><p>Syncs Inventory</p> <p>Order History</p></>}
                    position='top center'
                    hideOnScroll
                />                             

                <button className="mwsAuthTokenButton" onClick={()=>{                    
                        window.open("https://sellercentral.amazon.com/gp/mws/registration/register.html?signInPageDisplayed=1&devAuth=1&developerName=Yay Fun, Inc.&devMWSAccountId=802046753722", "_blank");                    
                    }}>                    
                        Get your MWS Auth Token                    
                </button>
            </>
          }

          if(activeStep == 1) {
            return <>
                
                   <FormControl component="fieldset" className={classes.formControl} style={{width:"100%"}}>
                    <FormLabel component="legend">Available Notification Settings</FormLabel>
                    <FormGroup
                    style={{width:"100%", paddingTop:"30px"}}>
                    <FormControlLabel
                        style={{width:"100%"}}
                        control={<Checkbox 
                            style ={{
                                color: "#4183c4",
                              }}                              
                            checked={state.email} onChange={handleChange} name="email" />}                        
                        label={<p style={{width:"100%"}}><span>Email</span> <span style={{float:"right"}}>{userEmail}</span></p>}
                    />
                    <FormControlLabel
                        control={<Checkbox
                            style ={{
                                color: "#4183c4",
                              }}                              
                            checked={state.phone} onChange={handleChange} name="phone" />}
                        label={<p><span>Phone</span> <span style={{float:"right"}}>{userPhone}</span></p>}
                    />
                    </FormGroup>
                    <FormHelperText style={{textAlign:"center"}}>You can change your notification settings later.</FormHelperText>
                </FormControl>

                
            {/* <CustomTextField   
                    className="search-input"
                    style={{float:"left", minWidth:"300px"}}                         
                    properties={
                    {
                        name:"Phone Number",
                        error:inputError.phone
                    }
                    }
                    defaultValue={phoneNumber}
                    value={phoneNumber}
                    inputProps={{
                      'aria-label': 'Phone Number',
                    }}
                    onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        console.log("Key enter Phone Number");
                    }
                }}
                onChange= {            
                    e =>
                    { 
                        setPhoneNumber(e.target.value); 
                        if (e.target.value) {
                        }
                    }
                }/>    */}
            </>

          }

          return <>

            <div style={{paddingTop:"60px"}}>
                
                {
                    initSyncFinished ? <CheckCircleOutlineIcon className="checkmark" /> : <CircularProgress size={64} color="primary" /> 
                }
                
                
            </div>

          </>

      }

      const handleChange = (event) => {
          console.log("HANDLE CHANGE");
          console.log(event);
        setState({ ...state, [event.target.name]: event.target.checked });
      };
      

      function getFieldsForStep() {
        if(activeStep == 0) {
            return (<>
            <CustomTextField   
                    className="search-input"
                    style={{float:"left", minWidth:"300px"}}              
                    properties={
                    {
                        name:"Your Seller ID",
                        error:inputError.sellerId
                    }
                    }
                    defaultValue={sellerId}
                    inputProps={{
                        'aria-label': 'Seller ID',
                    }}
                    onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        console.log("Key enter Seller ID");
                    }
                }}
                onChange= {            
                    e =>
                    { 
                        setSellerId(e.target.value)                        
                        if (e.target.value) {
                            setInputError({...inputError, sellerId:false});
                        }
                    }
                }/>      

                <br/>
                <br/>

                <CustomTextField   
                    className="search-input"
                    style={{float:"left",width:"500px !important"}}              
                    properties={
                    {
                        name:"MWS Auth Token",
                        error:inputError.mwsAuthToken
                    }
                    }
                    defaultValue={mwsAuthToken}
                    inputProps={{
                    'aria-label': 'MWS Auth Token',
                    }}
                    onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        console.log("Key enter Auth Token");
                    }
                }}
                onChange= {            
                    e =>
                    { 
                        console.log("get Fields for step change");
                        setMwsAuthToken(e.target.value);
                        if (e.target.value) {
                         setInputError({...inputError, mwsAuthToken:false});
                        }
                    }
                }/>     

            </>);
        }
        if(activeStep == 1) {
            return (<>
   
                </>)
        }

        return (<></>);
      }



      function displayOnboardingStep() {
        console.log("Display onboarding step");
        return (<>Step</>);
      }


    function displayOnboarding() {
        
        return (<>
        {   
            initCount > 0 ? 
             <div className={"onboarding-container " + (animateOnLoad ? "moveIn1" : "moveInFinished") }> 
                <div className={"step1 " + getStepClass(1)}>
                    <div style={{height:"200px"}}>
                        <h2>{getStepHeader()}</h2>            
                        &nbsp;
                        {getStepImage()}                                           
                        &nbsp;
                    </div>
                    <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};
                            if (isStepOptional(index)) {    
                                labelProps.optional = <Typography variant="caption">Optional</Typography>;
                            }
                            return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                            );
                        })}
                    </Stepper>
                <span style={{display:"block", height:"30px"}}>&nbsp;</span>   
                <div className="justify">{getStepText()}</div>           
                &nbsp;                
                <div>{getStepButtons()}</div>
                <span style={{display:"block", height:"30px"}}>&nbsp;</span>       
                &nbsp;
                {
                    getFieldsForStep()
                }        
            
                <div style={{paddingTop:"50px"}}> 
                <Button className={buttonClassname} style={{minWidth:"110px"}} variant="contained" color="primary" onClick={()=>{
                    checkValuesAndSubmit();
                }}>

                {loading ? <CircularProgress size={24} color="white" /> : getButtonNameFromStep()}</Button>        
                </div>
            
                </div>
          
                </div> : <>
    
        <div style={{textAlign:"center",paddingTop:"200px"}}>
            {
                console.log("Draw circular progress")
            }
            <CircularProgress size={120}/>
        </div>
        </>
    }

    </>);
      }



return (
    <Styles>
        {
            <div style={{paddingLeft:drawerWidth}} className={closed ? "moveOut1" : " "}>
            {displayOnboarding()}
            </div>
        }
    </Styles>
)


}

export default Onboarding