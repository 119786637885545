import React from 'react';
import styled from 'styled-components'
import Quickbooks from '../components/connections/Quickbooks';
import AmazonMWSConnect from '../components/connections/AmazonMWSConnect';


const Styles = styled.div`
    text-align:center;    
`

function Settings(props) {
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);


    return (
    <Styles>
        <h2>Accounting</h2>
        <Quickbooks userProps={props.userProps}/>

        <h2>Sales Channel</h2>
        <AmazonMWSConnect userProps={props.userProps}/>

    </Styles>
)


}

export default Settings
