import React from 'react';
import styled from 'styled-components'
import { Auth, API } from "aws-amplify";
import { graphqlOperation } from "aws-amplify";
import * as queries from '../graphql/queries';
import {GetProductDataFromResults} from "./Data";
import TableObject from "./TableObject";

const Styles = styled.div`
  text-align:center;
  padding-top:5em;
`

let username = '';
let init = false;
let nextToken = '';
let productsPerPage = 10;
let products = [];

const PRODUCT_LIST_NEXT_TOKEN_LIMIT = 25;

function Products(props) {

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    if (username == '') {
        if (!init) {
          init = true;          
          authenticateLocalUser();
        }
      }
    
    async function authenticateLocalUser() {
        Auth.currentAuthenticatedUser({
          bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(async user => {
          username = user.username;
          let data = await startProductsQuery(username);
          let items = data.items;
          if (items) {
            products = await GetProductDataFromResults(items, products);      
            if (data.nextToken) {
              nextToken = data.nextToken;
              await continueListingProducts();
              forceUpdate();
            } else {
              nextToken = "";
            }
          }      
        }).catch(err => console.log(err));      
      }
      

      async function startProductsQuery(username) {
        let result = await API.graphql(graphqlOperation(queries.listingsByOwnerType, {owner:props.userProps.owner,type:"Listing"})).then(async result=>
          {
              console.log(result.data);                        
              if (result.data.listingsByOwnerType.items) {

                  // if (result.data.listingsByOwnerType.items[0].status == "syncing") {
                  //     activeStep = 2;
                  // }                               
                  // if (result.data.listingsByOwnerType.items[0].status == "finished") {
                  //     activeStep = 2;
                  //     initSyncFinished = true;
                  //     props.updateInitialSyncFunc(true);
                  // }

                  return {items:result.data.listingsByOwnerType.items, nextToken:result.data.listingsByOwnerType.nextToken};
              }
          }).catch( error => {                                       
            if (error.errors[0]) {
              props.toastError(error.errors[0].message);        
            }
            return [];
          });

        // let result = await API.graphql(graphqlOperation(queries.listUserItems,
        //   { limit: productsPerPage }
        // )).then(async response => {
        //   if (response.data.listUserItems.items && response.data.listUserItems.items.length > 0) {      
        //     return response.data.listUserItems;
        //   }
        // }).catch(e => {console.log("startProductsQuery ERROR"); console.log(e) });
      
        return result;
      }


      async function continueListingProducts() {  
        // console.log("continueListingProducts:");
        // console.log("nextToken:", nextToken);
        if (nextToken && nextToken != "") {      
          
         await API.graphql(graphqlOperation(queries.listingsByOwnerType, { type:"Listing", nextToken:nextToken, limit:PRODUCT_LIST_NEXT_TOKEN_LIMIT})).then(async response => {      
          let data = response.data.listingsByOwnerType;
          if (data.items) {
              products = await GetProductDataFromResults(data.items, products);
            }
            if (data.nextToken) {
              nextToken = data.nextToken;
              await continueListingProducts();
            } else {
             nextToken = "";
            }
          }).catch(err => {
            console.log(err);
            nextToken = "";
          });
        }
      }
return (
    <Styles>
      <TableObject data={products} type={"Products"}/>
    </Styles>
)
}

export default Products
