import React from "react";
import CloseIcon from '@material-ui/icons/Close';

function Alert(props){
    console.log("Show Alert:", props);
    return(<>
    <div className="alert-dropdown" style={{backgroundColor:props.backgroundColor, color:props.color}}>
        <div className="notification-main">
            <span>{props.message}</span>
            <span><CloseIcon style={{float:"right", scale:"1.5" }} onClick={() => {props.onCloseAlert()}} /></span>
        </div>
    </div>
    
    </>)
}

export default Alert;