// import React from 'react'

// const UserContext = React.createContext({})

// export const UserProvider = UserContext.Provider;

// export default UserContext

import React, { Component } from 'react'

const UserContext = React.createContext({});

class UserProvider extends Component {
  // Context state
  state = {
    statusBarHidden:true,
    drawerWidth:""
  }

  // Method to update state
  setStatusBarHidden = (statusBarHidden) => {
    this.setState((prevState) => ({ statusBarHidden }))
  }

  setDrawerWidth = (drawerWidth) => {
    this.setState((prevState) => ({ drawerWidth }))
  }

  render() {
    
    const { children } = this.props;
    const { statusBarHidden, drawerWidth } = this.state;
    const { setStatusBarHidden, setDrawerWidth } = this;
   

    return (

      <UserContext.Provider
        value={{
            statusBarHidden,
            setStatusBarHidden,
            drawerWidth,
            setDrawerWidth
        }}
      >
        {children}
      </UserContext.Provider>
    )
  }
}

export default UserContext
    
export { UserProvider }