
import React, {useState, useContext, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import { API } from 'aws-amplify';
import UiContext from "../context/ui";
import Alert from './Alert';
import { Link, useLocation, useHistory } from "react-router-dom";



function UiDisplay(props) {

    const [hasQuickbooksLink, setHasQuickbooksLink] = useState(false);
    const { alertMessage, closeAlertMessage, totalMessages } = useContext(UiContext);
    const location = useLocation();
    const navRef = React.useRef();
    const containerRef = React.useRef();
    const [showDropdown , setShowDropdown] = useState(true);

    useEffect(()=> {

        // const params = new URLSearchParams(location.search);
        // const s = params.get('k');    
        // if (s && s.length >= 0) {
        //   setSearchInput(s);
        // }else {
        //   // setSearchInput('');
        // }
        window.onscroll = () => {
          // setOffset(window.pageYOffset);
          updateNav();
        }
    
        updateNav();
    
      }, [location.search, location.pathname, showDropdown, alertMessage]);

      
      const onCloseAlert = ()=> {    
        closeAlertMessage();
      }
    
    
    const updateNav = ()=>{

        let yOffset = window.pageYOffset;
  
        if (yOffset == 0 && location.pathname == "/" && !showDropdown && (alertMessage == "" || alertMessage == undefined)) {
          
          if (!navRef.current.classList.contains("is-nav-open")) {
            navRef.current.classList.add("is-nav-open");
          }
          if (navRef.current.classList.contains("is-nav-closed")) {
            navRef.current.classList.remove("is-nav-closed");
          }
  
          if (!containerRef.current.classList.contains("container-nav-open")) {
            containerRef.current.classList.add("container-nav-open");
          }
          if (containerRef.current.classList.contains("container-nav-closed")) {
            containerRef.current.classList.remove("container-nav-closed");
          }
        } else {
          if (navRef.current.classList.contains("is-nav-open")) {
            navRef.current.classList.remove("is-nav-open");
          }
  
          if (!navRef.current.classList.contains("is-nav-closed")) {
            navRef.current.classList.add("is-nav-closed");
          }
  
          if (containerRef.current.classList.contains("container-nav-open")) {
            containerRef.current.classList.remove("container-nav-open");
          }
  
          if (!navRef.current.classList.contains("container-nav-closed")) {
            navRef.current.classList.add("container-nav-closed");
          }

       }
    }

    return (<>

    <div ref={containerRef}>
      <div ref={navRef} position="fixed" className={"sticky-nav"}>        
    {alertMessage && alertMessage != "" ? 
    <Alert backgroundColor="#e3296f" color="white" message={alertMessage} onCloseAlert={onCloseAlert}/>:<></> }
    </div>
    </div>
    </>)
}

export default UiDisplay;