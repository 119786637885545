import React, {useEffect, useCallback, useContext} from 'react';
import styled from 'styled-components'
import {withRouter, Redirect, useLocation } from 'react-router-dom';
import { API } from 'aws-amplify';
import UiContext from '../context/ui';

const Styles = styled.div`
    margin-top:100px;
`

// state	The state value from Step 1. The seller initiates authorization from.
// selling_partner_id	The identifier of the selling partner who is authorizing your application.
// mws_auth_token	The MWSAuthToken value that you use when you create a query string for a call to Amazon Marketplace Web Service. The mws_auth_token parameter is only passed when the selling partner is authorizing a hybrid Selling Partner API (SP-API) application. Note that if you are the selling partner authorizing the hybrid SP-API application and the application owner (meaning you self-authorized your own Amazon MWS application) you will not receive the MWSAuthToken. For more information, see Hybrid Selling Partner API applications. For seller applications only.
// spapi_oauth_code	The Login with Amazon (LWA) authorization code that you exchange for an LWA refresh token. For more information, see Step 4. Your application exchanges the LWA authorization code for a LWA refresh token.
// Note: An LWA authorization code expires after five minutes. Be sure to exchange it for an LWA refresh token before it expires.


function AuthorizeCallback(props) {

    const [accessToken, setAccessToken] = React.useState(null);
    const [refreshToken, setRefreshToken] = React.useState(null);

    const [state, changeState] = React.useState(1);
    const [, updateState] = React.useState();
    const location = useLocation();
    const { checkIsLoading, setIsLoading } = useContext(UiContext);

    // console.log("spapi_oauth_code:", spapi_oauth_code, "state:", state, "selling_partner_id:", selling_partner_id );
    // const isLoading = useCallback(() => {
    //     console.log("Is loading");
        // const params = new URLSearchParams(location.search);
        // const spapi_oauth_code = params.get('spapi_oauth_code');    
        // checkCode(spapi_oauth_code);
    // }, []);


    useEffect(()=> {

        //if (isLoading.current == 0) {

        // const {spapi_oauth_code, state, selling_partner_id} = useParams();
       
        //  }

        // isLoading();
        // checkCode()

        const params = new URLSearchParams(location.search);
        const spapi_oauth_code = params.get('spapi_oauth_code');    
        checkCode(spapi_oauth_code);

      }, []);
  


    async function checkCode (code) {
        
        console.log("isLoading.current: ", checkIsLoading());

        if (checkIsLoading() == false) {
            setIsLoading(true);
            // isLoading.current == true;

            // isLoading.current = 1;

            console.log("Check code",code);

            let postMsg = {
                body: {
                    code:code
                }
            }

            ///TODO Check State First
            try {
        
                const result = await API.post("LambdaAPIUser", "/app/GetLWARefreshToken", postMsg).then(async response => {
          
                console.log("Verify token response");
                console.log(response);
    
                console.log("response.result:", response.result);

                if (response && response.result) {
                  
                    let jsonResponse = JSON.parse(response.result);
    
                    console.log("jsonResponse:",jsonResponse);

                    if (jsonResponse) {
                        
                        if (jsonResponse.error_description) {
                            props.toastError(jsonResponse.error_description + " " + jsonResponse.error);    
                        }
    
                        if (jsonResponse.access_token) {
                            setAccessToken(jsonResponse.access_token);
                        }
                        if (jsonResponse.refresh_token) {
                            console.log("set refresh token:", jsonResponse.refresh_token);
                            setRefreshToken(jsonResponse.refresh_token);
                        }
                        
                    }
                }
    
                if (response.ErrorResponse) {
                    console.log(response.ErrorResponse.Error.Message);
                    props.toastError(response.ErrorResponse.Error.Message);    
                    // setLoading(false);
                    // return response;
                }
    
                return response;
            });
    
            return result;
        }
        catch (e) {
                console.log("ERROR:", e);
        }
        
        
    
        }
}

return (
    <Styles>     
   
        {/* <Redirect to={{
            pathname: '/dashboard',
            state: { code:code }
        }} />
         */}
         <div>
            <p>Access Token:{accessToken}</p>
            <p>Refresh Token:{refreshToken}</p>

         </div>
    </Styles>)

}

export default withRouter(AuthorizeCallback);
