import React, { useState, useEffect, useContext, useRef } from "react";
// import { listUserItems, listUserItemByOwnerType, getOrder, getUserItem} from "../graphql/queries";
// import { listOrdersByUserDate } from "../api/queries";
// import { createUserItem, deleteUserItem, updateUserItem } from "../graphql/mutations";

import { API, graphqlOperation } from "aws-amplify";
import { Analytics, Auth } from 'aws-amplify'
// import isoCountries from '../config/isoCountries'
// import AppContext from '../context/AppContext'
import { Hub } from 'aws-amplify';
import { listingsByOwnerType } from "../graphql/queries";
// import UiContext from "../context/ui";
import {GetGroupsOfItems}  from '../components/Helper';

const delay = ms => new Promise(res => setTimeout(res, ms));

const UserContext = React.createContext({});
const UserProvider = ({ children }) => {
  
  const [signedIn, setSignedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [shippingAddresses, setShippingAddresses] = useState([]);
  const [nextToken, setNextToken] = useState('');
  const [userId, setUserId] = useState('empty');
  const fetchingSettlementReports = useRef(false);
  const settlementReportsRef = useRef({nextToken:"", reports:[]});
  const [settlementReports, setSettlementReports] = useState([]);

  // const { sendAlertMessage } = useContext(UiContext);


  useEffect(() => {

// //    fetchOrders();
//     // fetchShippingAddresses();
    fetchSettlementReports();

  }, [signedIn]);


//   const fetchSettlementReports = async () =>{

//     console.log("fetchingSettlementReports.current:", fetchingSettlementReports.current);

//     if (!fetchingSettlementReports.current) {
//       fetchingSettlementReports.current = true;
//       try {     
        
//         console.log("listingsByOwnerType:", fetchingSettlementReports.current);
//         await API.graphql(graphqlOperation(listingsByOwnerType, 
//          {owner:userId,type:"SettlementReport"})).then(async result=>{
//            console.log("result:", result);

//          }).catch(e=>{console.log(e)});

//          return settlementReportsRef.current;
//      } catch (err) {
//        console.log(err);
//      }
//     }
    
// }


const createQuickbookItems = async(items) =>{

  
}


const batchGetItems = async (productSkus) => {
  
  // console.log("batchGetItems:", productSkus);

  let groups = GetGroupsOfItems(productSkus, 20);       
  let results = [];
try {


  for (let i = 0; i < groups.length; i++) {
    let group = groups[i];          
    
    // console.log("BATCH GET:", group);

    let  batch = await API.post("LambdaAPIUser", "/app/batchGetProducts", { body:
      {            
        ids:encodeURIComponent(group) 
      } 
     }).then(result => {
       console.log("Result:", result);
     return result;
    });
    results = [...results, ...batch];
    delay(1800);
  }   
  return results;
}
catch(e) {
  console.log("batch get error:", e);
}

  return [];
}

const fetchSettlementReports = async () =>{

  console.log("fetchingSettlementReports.current:", fetchingSettlementReports.current);

  if (!fetchingSettlementReports.current) {
    fetchingSettlementReports.current = true;
    try {     
      
      console.log("listingsByOwnerType:", fetchingSettlementReports.current);
      await API.graphql(graphqlOperation(listingsByOwnerType, 
       {owner:userId,type:"SettlementReport", limit:1000})).then(async result=>{
         console.log("result:", result);
         settlementReportsRef.current.reports.push(...result.data.listingsByOwnerType.items);
         settlementReportsRef.current.nextToken = result.nextToken;
         setSettlementReports(result.data.listingsByOwnerType.items);
       }).catch(e=>{console.log(e)});


       return settlementReportsRef.current;

   } catch (err) {
     console.log(err);
   }
  }
  
}

const fetchOrdersBetween = async (between) =>{
  console.log("fetchOrdersBetween.between:", between);
    try {           
      return await API.graphql(graphqlOperation(listingsByOwnerType, 
       {owner:userId, type:"Order", createdAt:{between:between}, limit:1000})).then(async result=>{
         console.log("result:", result);
         
         let items = result.data.listingsByOwnerType.items;
      
         if (result.data.listingsByOwnerType.nextToken) {
             let itemsFetched = await fetchOrdersBetweenNextToken(between, items, result.data.listingsByOwnerType.nextToken);

             if (itemsFetched) {
              items = itemsFetched
             } else {
              console.log("Error fetching items with next token")
             }
         }

        //  console.log("items:", items);
        //  console.log("Total Items:", items.length);

         return items ? items : [];
        //  settlementReportsRef.current.reports.push(...result.data.listingsByOwnerType.items);
        //  settlementReportsRef.current.nextToken = result.nextToken;
        //  setSettlementReports(result.data.listingsByOwnerType.items);

       }).catch(e=>{console.log(e)});

      } catch (err) {
     console.log(err);
   }  
}

const fetchOrdersBetweenNextToken = async (between, items, nextToken) =>{
  try {         

    return await API.graphql(graphqlOperation(listingsByOwnerType, {type:"Order", createdAt:{between:between}, nextToken:nextToken, limit:1000})).then(async result=>{
      items = items.concat(result.data.listingsByOwnerType.items);
      if (result.data.listingsByOwnerType.nextToken) {
        items = await fetchOrdersBetweenNextToken(between, items, result.data.listingsByOwnerType.nextToken);
      }
      return items;
//        return result.data.listingsByOwnerType.items;
      //  settlementReportsRef.current.reports.push(...result.data.listingsByOwnerType.items);
      //  settlementReportsRef.current.nextToken = result.nextToken;
      //  setSettlementReports(result.data.listingsByOwnerType.items);
     }).catch(e=>{console.log(e)});
 } catch (err) {
   console.log(err);
 }  
}


const fetchSettlementsBetween = async (between) =>{
  console.log("fetchingSettlementReports.between:", between);
    try {           
      console.log("listingsByOwnerType:", fetchingSettlementReports.current);
      return await API.graphql(graphqlOperation(listingsByOwnerType, 
       {owner:userId, type:"Settlement", createdAt:{between:between}, limit:1000})).then(async result=>{
         console.log("result:", result);
         let items = result.data.listingsByOwnerType.items;
         if (result.data.listingsByOwnerType.nextToken) {
             items = await fetchSettlementsBetweenNextToken(between, items, result.data.listingsByOwnerType.nextToken);
         }

         console.log("items:", items);
         console.log("Total Items:", items.length);

         return items;
       }).catch(e=>{console.log(e)});

      } catch (err) {
     console.log(err);
   }  
}


const fetchSettlementsBetweenNextToken = async (between, items, nextToken) =>{
    try {         

      return await API.graphql(graphqlOperation(listingsByOwnerType, {owner:userId, type:"Settlement", createdAt:{between:between}, nextToken:nextToken, limit:1000})).then(async result=>{
        items = items.concat(result.data.listingsByOwnerType.items);
        if (result.data.listingsByOwnerType.nextToken) {
          items = await fetchSettlementsBetweenNextToken(between, items, result.data.listingsByOwnerType.nextToken);
        }
        return items;
//        return result.data.listingsByOwnerType.items;
        //  settlementReportsRef.current.reports.push(...result.data.listingsByOwnerType.items);
        //  settlementReportsRef.current.nextToken = result.nextToken;
        //  setSettlementReports(result.data.listingsByOwnerType.items);
       }).catch(e=>{console.log(e)});
   } catch (err) {
     console.log(err);
   }  
}



  useEffect(() => {

    console.log("User.js use effect:");
    
    Hub.listen('auth', data => {

      console.log("data:",data);

        switch (data.payload.event) {
          case 'signIn':                    
            setUserSignedIn(true);
            break;
          case 'signOut':
            setUserSignedIn(false);
            break;
          case 'signIn_failure': {
            // sendAlertMessage(data.payload.data.message);
            break;
          }
          case 'signUp_failure': {
            // sendAlertMessage("There was an error signing up. Please try again.")            
            break;
          }
          case 'forgotPassword_failure':
            // sendAlertMessage(data.payload.data.message)            
            break;
          case 'forgotPassword_failure':
            // sendAlertMessage(data.payload.message)            
          break;
        }
    });
    
    Auth.currentUserInfo();   
    Auth.currentAuthenticatedUser()
        .then(data => {
            if (user === null) {

                if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                    console.log(data)
                }
                var countryCode = null
                if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                    console.log('Updating Pinpoint endpoint [InitState]')
                }
                // if (isoCountries.hasOwnProperty(data.attributes['custom:country'])) {
                //     countryCode = isoCountries[data.attributes['custom:country']]
                // }
                // Analytics.updateEndpoint({
                //     ChannelType: 'EMAIL',
                //     Address: data.attributes.email,
                //     location: {
                //         city: data.attributes['custom:city'],
                //         country: countryCode,
                //         postalCode: data.attributes['custom:postcode'],
                //         region: data.attributes['custom:state']
                //     },
                //     optOut: 'NONE',
                //     attributes: {
                //         firstName: [data.attributes.given_name],
                //         hasShoppingCart: ['false'],
                //         completedOrder: ['false']
                //     },
                //     metrics: {
                //         itemsInCart: 0,
                //         orderNumber: "0"
                //     }
                // })
              
                data.attributes["cognito:groups"] = data.signInUserSession.accessToken.payload["cognito:groups"];
                setUserObject(data.attributes)
                setUserId(data.attributes.sub);
                setUserSignedIn(true);
            }
            if (user != null) {
                    
                // let id = "anon" + uuid();
                // Analytics.updateEndpoint({
                //     ChannelType: 'EMAIL',
                //     Address: `anon-${id}@yayfuninc.com`,
                //     location: {
                //         city: "city",
                //         country: "country",
                //         postalCode: "postal",
                //         region: "region"
                //     },
                //     optOut: 'NONE',
                //     attributes: {
                //         firstName: "name",
                //         hasShoppingCart: ['false'],
                //         completedOrder: ['false']
                //     },
                //     metrics: {
                //         itemsInCart: 0,
                //         orderNumber: "0"
                //     }
                // })

            }

        }).catch(e=>{
        
          
          // console.log(e)
        
        });

      },[]);


    
  const isAdmin = () => {    
    if (user) {
      let groups = user["cognito:groups"];
      if (groups) {
        for (let i = 0; i < groups.length; i++) {
          let group = groups[i];
          if (group == "Admin") {
            return true;
          }
        }
      }else {
        return false
      }
      // let groups = user.signInUserSession.accessToken.payload["cognito:groups"];
      // console.log("GROUOPS:", groups);

      // if (user.signInUserSession) {
      // }
    }else {
      return false;
    }
  }

  const setUserSignedIn = (signedin) => {
    setSignedIn(signedin)
  };

  const setUserObject = (userObject) => {
    setUser(userObject)
  };

  // const fetchMoreOrders = async () => {
        
  //   if (!loading && nextToken != '' && user != 'empty') {
  //     setLoading(true);
  //     try {      
  //       const { data } = await API.graphql({
  //         query: listOrdersByUserDate,
  //         variables:{nextToken, user:userId, sortDirection:"DESC"}
  //       } );      

  //       if (data.listOrdersByUserDate.nextToken) {
  //         setNextToken(data.listOrdersByUserDate.nextToken);
  //       } else {
  //         setNextToken('');
  //       }

  //       const _orders = data.listOrdersByUserDate.items;                
  //       let oldOrders = [];
  //       oldOrders.push(...orders)
  //       oldOrders.push(..._orders)
  //       setOrders(oldOrders);
  //       setLoading(false);
  //     } catch (err) {        
  //       setNextToken('');
  //       console.log(err);
  //       setLoading(false);
  //     }
  //   }
  // };


  // const getOrderById = async (id)=>{
  //   if (!loading) {    
  //     try {      
  //       const { data } = await API.graphql({
  //         query: getOrder,
  //         variables:{id:id} 
  //       });      

  //       return data;
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   }
  // }


//   const addUserItem = async (input) => {
//      console.log("addUserItem", input, " user.sub:", user.sub);
//     try {      
//       let finalResult =  await API.graphql({
//         query: createUserItem,
//         variables:{input:{...input, owner:user.sub, id:input.id, type:input.type}}
//           } ).then(async result=>{console.log("addUserItem:", result);        
//       }).catch(e=>{console.log(e)});            
//       return finalResult;
//   } catch (err) {
//       console.log(err);  
//     }  
//     return null;
//   }


//   const updateCreateUserItem = async (input, create) => {
//     console.log("updateUserItem", input, " user.sub:", user.sub);
//    try {      
//      let finalResult =  await API.graphql({
//        query: updateUserItem,
//        variables:{input:{...input, owner:user.sub, id:input.id, type:input.type}}
//          } ).then(async result=>{console.log("updateUserItem:", result);        
//      }).catch(async e=>{
      
//       console.log(e);
      
//       if (create) {
//         return await addUserItem(input);
//       }
            
//       });            
//      return finalResult;
//  } catch (err) {
//      console.log(err);  
//    }  
//    return null;
//  }


// const fetchUserItem = async (input) => {
//   console.log("fetchUserItem:", user.sub);
//  try {      
//    let inputObj = {owner:user.sub,id:input.id,type:input.type};
//    console.log("INPUT:", inputObj);
//    let finalResult =  await API.graphql({
//      query: getUserItem,
//      variables:inputObj
//        } ).then(async result=>{console.log("fetchUserItem RESULT:", result);
//        return result;
//    }).catch(e=>{console.log(e)});          
//    return finalResult;
// } catch (err) {
//    console.log(err);  
//  }  
//  return null;
// }



  const fetchOrders = async ()=>{

    console.log("orders.current:", fetchingSettlementReports.current);

    if (!fetchingSettlementReports.current) {
      fetchingSettlementReports.current = true;
      try {     
        
        console.log("listingsByOwnerType:", fetchingSettlementReports.current);
        await API.graphql(graphqlOperation(listingsByOwnerType, 
         {owner:userId,type:"Order", limit:1000})).then(async result=>{
           console.log("result:", result);
           settlementReportsRef.current.reports.push(...result.data.listingsByOwnerType.items);
           settlementReportsRef.current.nextToken = result.nextToken;
           setSettlementReports(result.data.listingsByOwnerType.items);
         }).catch(e=>{console.log(e)});
  
  
         return settlementReportsRef.current;
  
     } catch (err) {
       console.log(err);
     }
    }
    

    
    // if (!loading && userId !== "empty") {
    //   setLoading(true);

    //   try {      
    //     const { data } = await API.graphql({
    //       query: listOrdersByUserDate,
    //       variables:{user:userId, sortDirection:"DESC"} 
    //     });      

    //     if (data.listOrdersByUserDate.nextToken) {
    //       setNextToken(data.listOrdersByUserDate.nextToken);
    //     } else {
    //       setNextToken('');
    //     }
    //     const orders = data.listOrdersByUserDate.items;

    //     setOrders(orders);
    //     setLoading(false);
    //   } catch (err) {
    //     console.log(err);
    //     setLoading(false);
    //   }
    // }
  }




  // const fetchShippingAddresses = async () => {
  //   try {
  //       if (!loading) {
  //           // setLoading(true);
  //           const { data } = await API.graphql({
  //             query: listUserItemByOwnerType,
  //             variables:{type:"ShippingAddress"}              
  //           });      
  //           console.log("fetchShippingAddresses data:",data)
  //           const addresses = data.listUserItemByOwnerType.items;
  //           console.log("addresses:", addresses);
  //           setShippingAddresses(addresses);
  //           // setLoading(false);      
  //       }
  //   } catch (err) {
  //       console.log(err);
  //   }
  // };


//   const updateUserShippingAddress = async (input)=> {
            
//       if (!loading) {
//         setLoading(true);

//         let finalResult = null;          

//         try {     
//           //First delete then add
//           if (input.temp) {
//             delete(input.temp);
//             input["owner"] = user.sub;
//             let result = await API.graphql({
//                 query: createUserItem,
//                 variables:{input:input}
//               }).then(result=>{ 
            
//               finalResult = result;      

//             }
//               ).catch(e=>{console.log("ERR:", e)})              
//           }else {

//             let deleteItem = await API.graphql({
//               query:deleteUserItem, 
//               variables:{input:{owner:input.owner, id:input.id, type:input.type}}}).then(async r => {
//               let result = await API.graphql({
//                 query: createUserItem,
//                 variables:{input:input}
//               }).then(result=>{
//                               finalResult = result;
//             }).catch(e=>{console.log("ERR:", e)})                                    
//             }).catch(e=>{console.log(e)});    
//           }
//           setLoading(false);
//           return finalResult;
//         } catch (err) {
//           console.log(err);
//           setLoading(false);
//         }
//       }
//   }
  
  
// const deleteUserItemWithId = async (input) => {
//   console.log("deleteUserItemWithId", input);
//   if (!loading) {
//     setLoading(true);
//     try { 
//       await API.graphql({
//         query: deleteUserItem,
//         variables:{input:{owner:input.owner, id:input.id, type:input.type}}
//       } ).then(async result=>{console.log("Delete User Item Result:", result);
//         console.log("deleteTempShippingAddress:")
//         await removeShippingAddress(input.id);
//     }).catch(e=>{console.log(e)});      
//       setLoading(false);
//     } catch (err) {
//       console.log(err);
//       setLoading(false);
//     }
//   }
// }


//   const hasMoreOrdersToLoad = async () => {
//     if (nextToken != '') {
//       return false;
//     }
//     return true;
//   }

//   const addTempShippingAddress = async () =>{
//     let current = [];
//     if (shippingAddresses && shippingAddresses.length > 0) {
//       current = [...shippingAddresses];
//     } 
//     let newAddress = {
//       owner:user,
//       id:new Date().toISOString(),
//       type:"ShippingAddress",
//       shippingAddress: {
//         firstName:"",
//         lastName:"",
//         name:"",
//         addressLine1:"",
//         addressLine2:"",
//         country:"",
//         postalCode:"",
//         companyName:"",
//         phoneNumber:"",
//         city:"",
//         state:""
//       }
//     };
//     current.push(newAddress);
//     setShippingAddresses(current);
//     return newAddress
//   }

//   const removeShippingAddress = async (id)=>{
//     let current = [];
//     let updated = [];
//     current = [...shippingAddresses];
//     for (let i = 0; i < current.length; i++) {
//       if (current[i].id == id) {
//         //ignore
//       }else {
//         updated.push(current[i]);
//       }
//     }
//     setShippingAddresses(updated);
//   }


//   const deleteTempShippingAddress = async (id)=>{
//     let current = [];
//     let updated = [];
//     current = [...shippingAddresses];
//     for (let i = 0; i < current.length; i++) {
//       if (current[i].id == id && current[i].temp) {
//         //ignore
//       }else {
//         updated.push(current[i]);
//       }
//     }
//     setShippingAddresses(updated);
  // }

  // $owner: String
  // $typeCreatedAtId: ModelUserItemPrimaryCompositeKeyConditionInput
  // $filter: ModelUserItemFilterInput
  // $limit: Int
  // $nextToken: String
  // $sortDirection: ModelSortDirection




  return (
    <UserContext.Provider 
      value={{user, signedIn, isAdmin, fetchOrdersBetween, fetchSettlementReports, settlementReports, fetchSettlementsBetween, batchGetItems, createQuickbookItems }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
export {UserProvider};