import React, { Component } from "react";
import { AmplifyAuthenticator, AmplifyConfirmSignIn, AmplifySignUp, AmplifySignOut } from '@aws-amplify/ui-react';


export default class Signout extends Component {

  constructor(props) {
    super(props);      
    this.handleChange = this.handleChange.bind(this);

    }

    handleChange(event, {name, value}) { 
        this.setState({ [name]: value });
        this.props.searchTerm.term = value;
      }

      
      render() {
        return(       
            <>
            <div className="full-bg">

            <div style={{display:"inline-block",  backgroundColor:"white", zIndex:"100", height:"100vh", minWidth:"460px", paddingTop:"70px"}}>
            
            <img src="/images/icons/icon.png" style={{width:"60px"}}/>

            <>

            </>
            
            {/* <AmplifyAuthenticator federated={federated} /> */}
            
            <AmplifySignOut ></AmplifySignOut> 

<AmplifyAuthenticator>


</AmplifyAuthenticator>

            <p style={{padding:"50px"}}> Have an existing Amazon.com Account? Sign in to create your account.</p>
            
            </div>
            <a href="https://aws.amazon.com/what-is-cloud-computing"><img src="https://d0.awsstatic.com/logos/powered-by-aws.png" alt="Powered by AWS Cloud Computing"/></a>

            </div>

             {/* <AmplifyConfirmSignIn headerText="Inventory shark sign up" slot="confirm-sign-up">

            </AmplifyConfirmSignIn> */}



            </>
            
        );
      }

    }
