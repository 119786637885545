import React, { Component, useContext } from 'react';

import AccountButton from "./AccountButton";

import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';

import { withRouter } from "react-router-dom";
import UserContext from '../UserContext';


const menuItems = [
    {
      text: "Dashboard",
      url:"dashboard",
      imageName: "dashboard.png",
      selectedImageName:"dashboard-selected.png"
    },
    {
      text: "Orders",
      url:"orders",
      imageName: "orders.png",
      selectedImageName:"orders-selected.png"
    },
    {
      text: "Products",
      url:"products",
      imageName: "products.png",
      selectedImageName:"products-selected.png",
      // subMenu:[{
      //   text:"Search Products",
      //   subUrl:"products/add",    
      //   imageName: "",
      //   iconName:"search",    
      //   selectedImageName:""
      // }]
    },
    {
      text: "Purchases",
      url:"purchases",
      imageName: "purchases.png",
      selectedImageName:"purchases-selected.png",
    },
    {
      text: "Research",
      url:"research",
      imageName: "search.png",
      selectedImageName:"search-selected.png",
    },
  
    {
      text: "Uploads",
      url:"uploads",
      imageName: "uploads.png",
      selectedImageName:"uploads-selected.png"
    },
    {
      text: "Finances",
      url:"finances",
      imageName: "expenses.png",
      selectedImageName:"expenses-selected.png"
    },
    {
      text: "Reports",
      url:"reports",
      imageName: "reports.png",
      selectedImageName:"reports-selected.png"
    },
    {
      text: "Settings",
      url:"settings",
      imageName: "settings.png",
      selectedImageName:"settings-selected.png"
    }  
  ]

let showStatusBar = false;
  
class AppToolbar extends Component {

  static contextType = UserContext;

    constructor(props) {
        
        super(props);
        
        this.accountButtonRef = React.createRef();

        this.getCurrentSelectedFromPath = this.getCurrentSelectedFromPath.bind(this);
        this.path = props.path;
        
        this.state = {
           currentIndex:0,
           currentSelected:this.path? this.getCurrentSelectedFromPath(this.path) : "Dashboard",
           isMenuOpen:false,
           minDrawer: false          
       }

        
        this.minDrawer = props.minToolbar;
        this.isMobile = props.isMobile;
        this.toolbarCallback = props.toolbarCallback;
        this.drawerWidth = props.drawerWidth;
        this.userSetting = props.userSetting;
        this.isSelectedItem = this.isSelectedItem.bind(this);

    }

    
    getCurrentSelectedFromPath(path) {

      if (path.toLowerCase().includes("dashboard")){
        return "Dashboard";
      }     
      if (path.toLowerCase().includes("products")){
        return "Products";
      } 
      if (path.toLowerCase().includes("purchases")){
        return "Purchases";
      } 
      if (path.toLowerCase().includes("orders")){
        return "Orders";
      } 
      if (path.toLowerCase().includes("research")){
        return "Research";
      } 
      if (path.toLowerCase().includes("settings")){
        return "Settings";
      } 
      if (path.toLowerCase().includes("expenses")){
        return "Expenses";
      } 
      if (path.toLowerCase().includes("reports")){
        return "Reports";
      } 
      if (path.toLowerCase().includes("uploads")){
        return "Uploads";
      } 

      return "Dashboard";
    }

   isSelectedItem(itemName) {
     
    if (this.state && this.state.currentSelected && this.state.currentSelected == itemName) {
      return true;
    }    
    return false;
  }


  componentDidMount() {

        if (this.userSetting && this.accountButtonRef && this.accountButtonRef.current) {
            this.accountButtonRef.current.updateUserSetting(this.userSetting)
        }
    }
    
  
    render() {

    // const drawerWidth = 600;
    
    const { statusBarHidden } = this.context;

    const classes = {    
        root: {
          display: 'flex',
        },
        appBar: {
         zIndex:  1401,
        //  backgroundColor:'#082d4b'
        backgroundImage:"linear-gradient(to bottom right, #232f3e , #232f3e)"
        },
        drawer: {
          backgroundImage:"linear-gradient(to bottom right, #092462 , #1b97d3)"
        },
        drawerPaper: {
          width: this.drawerWidth,
        },  
        content: {
          flexGrow: 1,
          padding: 1 * 3,  
        },
        toolbar: {
          paddingTop:0,
          minHeight:"auto !important"
        },
        paper: {
          backgroundColor:"#353535"
        },
        search: {
          position: 'relative',
          borderRadius: "4px",
          marginLeft: 10,
          width: '100%',    
        },
        
        inputRoot: {
          backgroundColor:"white"
        },
        inputInput: {
          backgroundColor:"white",
          width: '100%',
        },
        grow: {
          flexGrow: 1,
        }
      }

      let self = this;
      const props  = this.props;


  
  return <>
<AppBar position="fixed" style={classes.appBar}>

<Toolbar className="appToolBar">      
{ 
this.isMobile ?        
  <IconButton className={classes.menuButton} color="inherit" aria-label="Menu">
    <MenuIcon onClick={()=>{this.setState({showDrawer:!this.state.showDrawer})}}/>
  </IconButton> : <div></div>
}

<img src="/images/icons/icon.png" style={{width:"36px"}}/>

<Typography variant="p" color="inherit"  style={{fontSize:"14px", flexGrow:1, textAlign:"left", paddingLeft:"10px"}}noWrap>
  &nbsp;Inventory
  &nbsp;
  <br/>
  <span style={{color:"white", fontSize:"24px",letterSpacing:"0.05em", fontWeight:"bold"}}>Shark</span>
</Typography>

<AccountButton userSetting={this.userSetting} ref={this.accountButtonRef} />

</Toolbar>

</AppBar>

{
!self.isMobile || self.state.showDrawer? 
 <Drawer className="drawer" variant="permanent" anchor="left" >
    <List className="menuList" style={{ width:self.drawerWidth }}>                
          {
            menuItems.map((item, index) => (                        
            <>
              <ListItem style={{paddingLeft:"20px", borderLeft:self.isSelectedItem(item.text) ? "3px solid #54b4f8" : "0px" }} button key={item.text} onClick={()=> 
                 { 
                    props.history.push('/'+item.url);
                    self.setState({currentSelected:item.text});
                  }}>
                
                {
                  item.ionIcon ? <span >
                      
                      <ion-icon class={self.isSelectedItem(item.text) ? "blue":"white" }  name={item.iconName}></ion-icon> </span> 
                      :
                      <img src={"/images/icons/" + (self.isSelectedItem(item.text) ? item.selectedImageName : item.imageName ) }  style={{width:"20px",  flexShrink:0}}/>
                }
                
                  &nbsp; &nbsp;
              {
                  !self.minDrawer ? <ListItemText disableTypography primary={<Typography type="body2" className="menuList-item" style={{color: self.isSelectedItem(item.text) ? '#54b4f8' : '#FFFFFF'}}> {item.text} </Typography>}/> : <> </>
              }
            </ListItem>
              {
                item.subMenu ? <div style={{minWidth:"20px"}}>
                {
                    item.subMenu.map((subItem)=>(<>                         
                     <ListItem style={{paddingLeft:"34px", borderLeft:self.isSelectedItem(subItem.text) ? "3px solid #54b4f8" : "0px" }} button key={subItem.text} onClick={()=> 
                      { 
                          props.history.push('/'+subItem.subUrl);
                          self.setState({currentSelected:subItem.text});
                        }}>
                  
                  {/* <img src={"images/icons/" + (self.isSelectedItem(subItem.text) ? subItem.selectedImageName : subItem.imageName ) }  style={{width:"20px",  flexShrink:0}}/> */}
                &nbsp; &nbsp;

                <ion-icon name={subItem.iconName}></ion-icon>

              {
                  !self.minDrawer ? <ListItemText disableTypography primary={<Typography type="body2" className="menuList-item" style={{color: self.isSelectedItem(subItem.text) ? '#54b4f8' : '#FFFFFF'}}> {subItem.text} </Typography>}/> : <> </>
              }
              </ListItem>                
            </>
                    ))
                  }
                </div>:<></>                    
                }
              </>


          ))}

<div style={{flexGrow:"1"}}>&nbsp;</div>        


{
         
         this.minDrawer ? 
           <><ArrowForwardIcon style={{cursor:"pointer", fill: "white", float:"right", marginRight:"2px"}} 
             onClick={()=>{
             // self.toolbarCallback(false);                
         }}/></> : <>
           <ArrowBackIcon style={{cursor:"pointer", fill: "white", float:"right", marginRight:"0px"}}
            onClick={()=>{
           // self.toolbarCallback(true);
       }}/></>
     }         
        </List>
           
      <div class="footer">
        <p class="footertext">
            {
                // this.minDrawer ? <>            
                // <a class="footer-link" title="Privacy Policy" href="">P</a>
                // <a class="footer-link" title="Contact" href="mailto:contact@yayfuninc.com">C</a>        
                //   </>:<>
                //   <a class="footer-link" href="">Privacy Policy</a>
                //   <a class="footer-link" href="mailto:contact@yayfuninc.com">Contact</a>        
                // </>
            }
              
        </p>
      </div> 

 </Drawer> : <div></div>
}  

{
  !statusBarHidden ? <div className="statusBar">
    <div className="statusBarIcon"><CircularProgress size={24} color="white" /></div>
    <p className="statusBarText">Inventory Syncing</p>      
    <div className="statusBarIconClose" style={{float:"right"}}><CloseIcon fontSize="large" size={34} onClose={()=>{
    }}/></div>
  </div> : <></>
}

  </>

    }
}


export default withRouter(AppToolbar);