
import React, { useContext } from 'react'
import CustomTextField from './CustomTextField';
// import ProductContext from "../../context/products";
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import { ConvertStringToDollarsCents } from '../Helper';
import moment from 'moment';


const CustomTextFieldInput = ({name, fieldName, id, defaultValue, currentValue, showLabel, updateProductFunc})=> {    
    return (<CustomTextField   
    className="search-input"
    style={{float:"left"}}                         
    key={id}
    properties={
    {
        name:name,
        labelName:showLabel ? name : '',
        error:'',
        variant:'outlined',
        key:id,
        currentValue:currentValue
    }
    }
    // defaultValue={defaultValue}    
    inputProps={{
        'aria-label': showLabel ? name : '',
    }}
    onKeyPress={(e) => {
    if (e.key === 'Enter') {
        console.log("Key enter " + name);
        //TODO: End propagation
        }
    }}
    onChange={
        e =>
        { 
            console.log("On Change", e.target.value)
            // console.log();
            if (e.target.value) {
                currentValue = e.target.value; 
                updateProductFunc(id, {[fieldName]:currentValue});
                //forceUpdate();
            }else {
                currentValue = ''; 
                updateProductFunc(id, {[fieldName]:''});
            }
        }
    }

/>)
}

const customInputTextField = (name, fieldName, id, defaultValue, currentValue, showLabel, updateProductFunc) => {    
    return (
       <CustomTextFieldInput name={name} fieldName={fieldName} id={id} defaultValue={defaultValue} currentValue={currentValue} showLabel={showLabel} updateProductFunc={updateProductFunc} />
    );
}


export function GetColumnDataForProductType(type, updateProductFunc, saveProductFunc) {
    // id: ID!
    // title: String!
    // description: String
    // image: String
    // author: String
    // featured: Boolean
    // price: Float
    // quantity: Int
    function customButton(values) {
        return (
            <Button className="checkout-place-order" color="primary" variant="contained" onClick={ async() => {
                // console.log("updateProduct with values:",values);
                // console.log("finished updateProduct");
                // updateProductFunc(values.id, values)
                saveProductFunc(values.id, values);
        }}> Save
          {/* {loading ? <CircularProgress style={{scale:"1"}} color="white" /> : 'Order Now'} */}
        </Button>
        )
    }

    // settlementId:String
    // settlementStartDate:String
    // settlementEndDate:String	
    // depositDate:String	
    // totalAmount:String
    // currency:String	
    // transactionType:String	
    // orderId:String	
    // merchantOrderId:String
    // adjustmentId:String	
    // shipmentId:String	
    // marketplaceName:String	
    // amountType:String	
    // amountDescription:String	
    // amount:String	
    // fulfillmentId:String	
    // postedDate:String	
    // postedDateTime:String	
    // orderItemCode:String	
    // merchantOrderItemId:String	
    // merchantAdjustmentItemId:String	
    // sku:String	
    // quantityPurchased:String	
    // promotionId:String	

    
    if (type == "Settlement") {
        return [
            {
                Header: ' ',
                Cell:row=><></>,     
                draggable: false,       
                id:"mainHeader",
                columns: [
                {
                    Header: 'settlementId',
                    accessor: 'settlementId'
                },
                {
                    Header: 'orderId',
                    accessor: 'orderId'
                },
                {
                    Header: 'sku',
                    accessor: 'sku'
                },

                {
                    Header: 'transactionType',
                    accessor: 'transactionType',
                },
                {
                    Header: 'amountType',
                    accessor: 'amountType',
                },
                {
                    Header: 'amountDescription',
                    accessor: 'amountDescription',
                },
                {
                    Header: 'amount',
                    accessor: 'amount',
                },            
                {
                    Header: 'postedDateTime',
                    accessor: 'postedDateTime',
                },
                {
                    Header: 'quantityPurchased',
                    accessor: 'quantityPurchased',
                }
            ]
            }]}
            
    if (type == "Products") {
        return [
            {
                Header: ' ',
                Cell:row=><></>,     
                draggable: false,       
                id:"mainHeader",
                columns: [
                {
                    Header: 'ID',
                    accessor: 'id'
                },
                {
                    Header: 'Main Image',
                    accessor: 'image',
                    Cell:row=><>             
                    <img className="table-image" src={row.row.values.image} /></>
                },
                {
                    Header: 'Title',
                    accessor: 'title',
                    Cell:row=><>{ customInputTextField('Title', 'title', row.row.values.id, row.row.values.title, row.row.values.title, false, updateProductFunc)}</>
                },                 
                {
                    Header: 'Price',
                    accessor: 'price',
                    Cell:row=><>{ customInputTextField('Price', 'price', row.row.values.id, row.row.values.price, row.row.values.price, false, updateProductFunc)}</>                    
                },                                                 
                {
                    Header: 'Description',
                    accessor: 'description',
                    Cell:row=><>{ customInputTextField('Description', 'description', row.row.values.id, row.row.values.description, row.row.values.description, false, updateProductFunc)}</>
                },                 
                {
                    Header: 'Author',
                    accessor: 'author',
                    Cell:row=><>{ customInputTextField('Author', 'author', row.row.values.id, row.row.values.author, row.row.values.author, false, updateProductFunc)}</>
                },
                {
                    Header: 'Brand',
                    accessor: 'brand',
                    Cell:row=><>{ customInputTextField('Brand', 'brand', row.row.values.id, row.row.values.brand, row.row.values.brand, false, updateProductFunc)}</>
                },
                {
                    Header: 'Units',
                    accessor: 'units',
                    Cell:row=><>{ customInputTextField('Units', 'units', row.row.values.id, row.row.values.units, row.row.values.units, false, updateProductFunc)}</>
                },  
                {
                    Header: 'Quantity',
                    accessor: 'quantity',
                    Cell:row=><>{ customInputTextField('Quantity', 'quantity', row.row.values.id, row.row.values.quantity, row.row.values.quantity, false, updateProductFunc)}</>
                },  
                {
                    Header: 'Type',
                    accessor: 'type',
                    Cell:row=><>{ customInputTextField('Type', 'type', row.row.values.id, row.row.values.type, row.row.values.type, false, updateProductFunc)}</>
                },
                {
                    Header: 'Subtype',
                    accessor: 'subtype',
                    Cell:row=><>{ customInputTextField('Subtype', 'subtype', row.row.values.id, row.row.values.subtype, row.row.values.subtype, false, updateProductFunc)}</>
                },
                {
                    Header: 'Status',
                    accessor: 'status',
                    Cell:row=><>{ customInputTextField('Status', 'status', row.row.values.id, row.row.values.status, row.row.values.status, false, updateProductFunc)}</>
                },
                {
                    Header: 'Featured',
                    accessor: 'featured',
                    Cell:row=><>{ customInputTextField('Featured', 'featured', row.row.values.id, row.row.values.featured, row.row.values.featured, false, updateProductFunc)}</>
                },
                {
                    Header: 'Edited',
                    accessor: 'edited',
                    Cell:row=><>
                        {
                            row.row.values.edited ?                        
                            customButton(row.row.values) : customButton(row.row.values)
                        }
                    </>
                }    
                ]
            }
        ];
    }
    

    if (type == "Orders") {
        return [
            {
                Header: ' ',
                Cell:row=><></>,     
                draggable: false,       
                id:"mainHeader",
                columns: [
                    {
                    Header: 'Date',
                    accessor: 'date',
                    Cell:row=><>{ moment(row.row.values.date).format("MMMM DD, YYYY")}</>
                    },
                    {
                    Header: 'Id',
                    accessor: 'id',
                    Cell:row=><>{ "Order #" + row.row.values.id}</>                    
                    },  
                    {
                    Header: 'Total',
                    accessor: 'total',
                    Cell:row=><>{ ConvertStringToDollarsCents(row.row.values.total)}</>
                    },
                    {
                    Header: 'User',
                    accessor: 'user'
                    }                                              
                ]
            }
        ];
    }


    return [
        {
            Header: ' ',
            Cell:row=><></>,     
            draggable: false,       
            id:"mainHeader",
            columns: [
            {
                Header: 'ID',
                accessor: 'id'
            },
            {
                Header: 'Main Image',
                accessor: 'image',
                Cell:row=><>             
                <img className="table-image" src={row.row.values.image} /></>
            },
            {
                Header: 'Title',
                accessor: 'title',
                Cell:row=><>{ customInputTextField('Title', 'title', row.row.values.id, row.row.values.title, row.row.values.title, false, updateProductFunc)}</>
            },                 
            {
                Header: 'Price',
                accessor: 'price',
                Cell:row=><>{ customInputTextField('Price', 'price', row.row.values.id, row.row.values.price, row.row.values.price, false, updateProductFunc)}</>                    
            },                                                 
            {
                Header: 'Description',
                accessor: 'description',
                Cell:row=><>{ customInputTextField('Description', 'description', row.row.values.id, row.row.values.description, row.row.values.description, false, updateProductFunc)}</>
            },                 
            {
                Header: 'Author',
                accessor: 'author',
                Cell:row=><>{ customInputTextField('Author', 'author', row.row.values.id, row.row.values.author, row.row.values.author, false, updateProductFunc)}</>
            },
            {
                Header: 'Brand',
                accessor: 'brand',
                Cell:row=><>{ customInputTextField('Brand', 'brand', row.row.values.id, row.row.values.brand, row.row.values.brand, false, updateProductFunc)}</>
            },
            {
                Header: 'Units',
                accessor: 'units',
                Cell:row=><>{ customInputTextField('Units', 'units', row.row.values.id, row.row.values.units, row.row.values.units, false, updateProductFunc)}</>
            },  
            {
                Header: 'Quantity',
                accessor: 'quantity',
                Cell:row=><>{ customInputTextField('Quantity', 'quantity', row.row.values.id, row.row.values.quantity, row.row.values.quantity, false, updateProductFunc)}</>
            },  
            {
                Header: 'Type',
                accessor: 'type',
                Cell:row=><>{ customInputTextField('Type', 'type', row.row.values.id, row.row.values.type, row.row.values.type, false, updateProductFunc)}</>
            },
            {
                Header: 'Subtype',
                accessor: 'subtype',
                Cell:row=><>{ customInputTextField('Subtype', 'subtype', row.row.values.id, row.row.values.subtype, row.row.values.subtype, false, updateProductFunc)}</>
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell:row=><>{ customInputTextField('Status', 'status', row.row.values.id, row.row.values.status, row.row.values.status, false, updateProductFunc)}</>
            },
            {
                Header: 'Featured',
                accessor: 'featured',
                Cell:row=><>{ customInputTextField('Featured', 'featured', row.row.values.id, row.row.values.featured, row.row.values.featured, false, updateProductFunc)}</>
            },
            {
                Header: 'Edited',
                accessor: 'edited',
                Cell:row=><>
                    {
                        row.row.values.edited ?                        
                        customButton(row.row.values) : customButton(row.row.values)
                    }
                </>
            }    
            ]
        }
    ];
}