
function getTotalForProductAsin(product) {    
    
    let totalCount = 0;
    let totalInbound = 0;
    let totalReserved = 0;
    let avgPrice = 0;
    let avgPriceCount = 0;
    
    if (product.listings && product.listings.items) {
      for (let i = 0; i < product.listings.items.length; i++) {
          let listing = product.listings.items[i];
              if (listing.supplyDetail) {
                for (let x = 0; x < listing.supplyDetail.length; x++) {
                  let supplyDetail = listing.supplyDetail[x];                
                  // supplyDetail.quantity;
                  // supplyDetail.supplyType;
                  // supplyDetail.earliestAvailableToPick;
                  // supplyDetail.latestAvailableToPick;
                  // console.log(supplyDetail);
                  
                  if (supplyDetail.supplyType == "InStock") {                  
                    totalCount += supplyDetail.quantity                  
                  }
  
                  if (supplyDetail.supplyType == "Transfer") {                  
                    totalCount += supplyDetail.quantity;
                  }
                }
              }
                        
            let totalInboundListing = (parseInt(listing.afnInboundShippedQuantity ? listing.afnInboundShippedQuantity: 0 ) + parseInt(listing.afnInboundWorkingQuantity ? listing.afnInboundWorkingQuantity : 0));
            totalInbound += totalInboundListing;
            totalReserved += (listing.afnReservedQuantity ? parseInt(listing.afnReservedQuantity) : 0 );
            avgPrice += parseFloat(listing.yourPrice);
            avgPriceCount++;
      }
    }
  
    if (avgPriceCount > 0) {
      avgPrice = avgPrice/avgPriceCount;
    }
    
    return {totalCount, totalInbound, totalReserved, avgPrice};
  }
  

  function getStockDataForProduct(product, retailer) {
    if (product.itemStock) {      
      for (let i = 0; i < product.itemStock.items.length; i ++) {
        let stock = product.itemStock.items[i];
        if (stock.retailer == retailer) {
          if (typeof stock.data === 'string') {
            if (stock.data != "INITIAL") {
              let jsonData = JSON.parse(stock.data);
              if (jsonData) {
                if (retailer == "walmart"){
                  stock.data = jsonData.data;
                }
                if (retailer == "target"){
                  stock.data = jsonData;
                }
              }
            }          
          }
          return stock;
        }
      }
    }
    return undefined;
  }


  function getOnlineWalmartStockStatus(product) {
    
    if (product.upc == undefined || product.upc == " ")
    return false;
    
    let data = getStockDataForProduct(product, "walmart");
    if (data == undefined || data.data == undefined) {
      return false;
    }
    let onlineData = data.data.online;
    let status = onlineData && onlineData.inventory && onlineData.inventory.status == "In Stock" ? true : false;
    return status;
  }

  
  function getAvailableTotalForProductAsin(product) {
    let totalCount = 0;
    for (let i = 0; i < product.listings.items.length; i++) {
          totalCount += product.listings.items[i].inStockSupplyQuantity;
    }
    return totalCount;
  }

  function showStockValueForProduct(product, fbaTotal, warehouseTotal) {    
    let totalFBA = fbaTotal;//product.asin ? this.getTotalForProductAsin(product.asin) : 0;
    let totalProductValue = (warehouseTotal + totalFBA) * product.cost;    
    return totalProductValue;
  }
  
  function getTotalMonthlyStorageCost(product) {
    let total = 0;
    for (let i = 0; i < product.listings.items.length; i++) {
      let totalCost = 0;   
      let fbaStorage = product.listings.items[i].fbaStorage;
      if (fbaStorage) {
        for (let y = 0; y < fbaStorage.length; y++) {
          let storage = fbaStorage[y];
          totalCost += parseFloat(storage.estimatedMonthlyStorageFee);
        }
        total += totalCost;
      }       
    }    
    return total;
  }

  
export function GetProductDataFromResults(items, products) {
    // let items = [];      
    products = products.concat(items);  
    return products;

    // for (let i = 0; i < items.length; i++) {
    //     let item = items[i];
    //     let fbaData = getTotalForProductAsin(item);
    //     let fbaTotal = fbaData.totalCount;
    //     let fbaInbound = fbaData.totalInbound;
    //     let fbaReserved = fbaData.totalReserved;
    //     let yourPrice = fbaData.avgPrice;
    //     let warehouseTotal = 0;
    //     let data = getStockDataForProduct(item, "walmart");
    //     let isInStock = getOnlineWalmartStockStatus(item);
    //     let walmartLastChecked = item.walmartLastChecked;
    //     let available = 0;
    //     let status =  "UNAVAILABLE";
    //     let targetLastChecked = item.targetLastChecked;
    //     let dataT = getStockDataForProduct(item, "target");
    //     let last3DaysTotal  = 0;
    //     let last7DaysTotal  = 0;
    //     let last15DaysTotal = 0;
    //     let last30DaysTotal = 0;
    //     let last60DaysTotal = 0;
    //     let last90DaysTotal = 0;
    //     let selectedListing = {};
    //     if (item.listings) {
    //       for (let x = 0; x < item.listings.items.length; x++) {
   
    //         let listing = item.listings.items[x];     
    //       if (listing.salesHistory) {
    //           last3DaysTotal  += (listing.salesHistory.last3DaysSales  ? parseInt(listing.salesHistory.last3DaysSales)  : 0);
    //           last7DaysTotal  += (listing.salesHistory.last7DaysSales  ? parseInt(listing.salesHistory.last7DaysSales)  : 0);
    //           last15DaysTotal += (listing.salesHistory.last15DaysSales ? parseInt(listing.salesHistory.last15DaysSales) : 0);
    //           last30DaysTotal += (listing.salesHistory.last30DaysSales ? parseInt(listing.salesHistory.last30DaysSales) : 0);
    //           last60DaysTotal += (listing.salesHistory.last60DaysSales ? parseInt(listing.salesHistory.last60DaysSales) : 0);
    //           last90DaysTotal += (listing.salesHistory.last90DaysSales ? parseInt(listing.salesHistory.last90DaysSales) : 0);        
    //       }
    //       }
    //     }


    //     if (data && data.data) {
    //     walmartLastChecked = data.scanTime;
    //     }
    //     if (dataT && dataT.data && dataT.data.product) {
    //     available = dataT.data.product.available_to_promise_network.available_to_promise_quantity ? dataT.data.product.available_to_promise_network.available_to_promise_quantity : 0
    //     status = dataT.data.product.available_to_promise_network.availability_status ? dataT.data.product.available_to_promise_network.availability_status : "UNAVAILABLE";
    //     targetLastChecked = dataT.scanTime;
    //     }
    //     let totalOfferCount = 0;
    //     if (item.competitivePricing && item.competitivePricing.NumberOfOfferListings) {
    //     for (let n = 0; n < item.competitivePricing.NumberOfOfferListings.length; n++) {
    //         if (item.competitivePricing.NumberOfOfferListings[n].condition == "Any") {
    //         totalOfferCount += item.competitivePricing.NumberOfOfferListings[n].count;
    //         }        
    //     }
    //     }

    //     let salesRank = 0;
    //     if (item.amazonBuyBox && !item.amazonBuyBox.includes("ProductCategoryId=")) {
    //     try {
    //     let amazonBuyBoxDataSalesRank = JSON.parse(item.amazonBuyBox);
        
    //     if (amazonBuyBoxDataSalesRank) {
    //         for (let a = 0; a < amazonBuyBoxDataSalesRank.length; a++) {
    //         let categoryRank = amazonBuyBoxDataSalesRank[a];
    //         if (categoryRank.ProductCategoryId) {
    //             if (categoryRank.ProductCategoryId.includes("display_on_website")) {
    //             salesRank = parseInt(categoryRank.Rank);
    //             }
    //         }        
    //         }
    //     }
    
    //     }
    //     catch(err) {
    //         console.log(err);
    //     }
    //         // [{"ProductCategoryId":"toy_display_on_website","Rank":"7351"},{"ProductCategoryId":"2514571011","Rank":"197"}]
    //         // [{ProductCategoryId=toy_display_on_website, Rank=32356}, {ProductCategoryId=251914011, Rank=704}, {ProductCategoryId=3245219011, Rank=915}]
    //         // [{ProductCategoryId=toy_display_on_website, Rank=107090}, {ProductCategoryId=11350120011, Rank=3408}]
    //     }

    //     items.push({
    //     image:{url:item.imageURL},
    //     total:fbaTotal,
    //     inbound:fbaInbound,
    //     reserved:fbaReserved,
    //     price:yourPrice,
    //     available:getAvailableTotalForProductAsin(item),
    //     amazon:{asin:item.asin, competitivePricing:item.competitivePricing},
    //     walmart:{id:item.id, upc:item.upc, walmartLastChecked:walmartLastChecked, productCheckWalmart:item.productCheckWalmart, data:data, isInStock:isInStock},
    //     target:{id:item.id, targetTcin:item.targetTcin, targetLastChecked:targetLastChecked, available:available, status:status, productCheckTarget:item.productCheckTarget},
    //     rank:salesRank, //item.salesRank && item.salesRank.length > 0 ?  parseInt(item.salesRank[0] ? item.salesRank[0].Rank : 0): "0",
    //     asin:item.asin,
    //     upc:item.upc,
    //     targetTcin:item.targetTcin,
    //     offers:totalOfferCount,
    //     brand:item.brand,
    //     title:item.name,            
    //     sales3:last3DaysTotal,
    //     sales7:last7DaysTotal,
    //     sales15:last15DaysTotal,
    //     sales30:last30DaysTotal,
    //     sales60:last60DaysTotal,
    //     sales90:last90DaysTotal,
    //     last7daysSales:last7DaysTotal ? last7DaysTotal : 0,
    //     last15daysSales:last15DaysTotal ? last15DaysTotal : 0,
    //     last30daysSales:last30DaysTotal ? last30DaysTotal : 0,
    //     last60daysSales:last60DaysTotal ? last60DaysTotal : 0,
    //     last90daysSales:last90DaysTotal ? last90DaysTotal : 0,
    //     graphData0:item,
    //     graphData1:item,
    //     graphData2:item,
    //     graphData3:item,
    //     graphData4:item,
    //     value:showStockValueForProduct(item, fbaTotal, warehouseTotal),
    //     imageWidth:"100",
    //     storageCost:getTotalMonthlyStorageCost(item),
    //     listings:{...item.listings},
    //     edit:""
    //     });
    // }
    // products = products.concat(items);    
    // return products;
  }

  