import React from 'react';
import styled from 'styled-components'
import AmazonSettlement from '../components/connections/AmazonSettlement';

const Styles = styled.div`

`

function Reports(props) {

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
return (
    <Styles>
        <h2>Amazon Settlement Reports</h2>
        <AmazonSettlement userProps={props.userProps}  />
    </Styles>
)


}

export default Reports
