import React, { Component } from 'react';
import Amplify, { Auth, Hub, Analytics} from 'aws-amplify';


import styled from 'styled-components'
import {withRouter, Redirect, BrowserRouter, Route, Switch } from 'react-router-dom';

//Page components
import Signin from './Signin';
import Signout from './Signout';
import Products from './Products';
import Orders from './Orders';
import Dashboard from './Dashboard';
import Authorize from './Authorize';
import AuthorizeCallback from './AuthorizeCallback';
import Reports from './Reports';
import Settings from './Settings';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API } from 'aws-amplify';
import { graphqlOperation } from "aws-amplify";
import Loader from './Loader';
import * as queries from '../graphql/queries';
import * as customQueries from '../customQueries/queries';
import * as mutations from '../graphql/mutations';
import moment from 'moment-timezone';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Tooltip from '@material-ui/core/Tooltip';
import UserContext from '../UserContext';
import UiDisplay from '../components/UiDisplay';

var _ = require('lodash/core');

const Styles = styled.div`
    
`

const mapObj = f => obj =>
  Object.keys(obj).reduce((acc, key) => ({ ...acc, [key]: f(obj[key]) }), {});
const toArrayOfStrings = value => [`${value}`];
const mapToArrayOfStrings = mapObj(toArrayOfStrings);


Analytics.autoTrack('pageView', {
    enable: true,
    type: 'SPA',
  });


// Decide what to do when an error happens
const onErrorListener = (err) => {
    console.info('A new JS error: ' + JSON.stringify(err, null, 2));
    Analytics.record({
      name: 'js_error',
      // Attribute values must be strings
      attributes: {
        code: _.toString(err.payload.code),
        message: _.toString(err.payload.message),
        url: _.toString(err.payload.url)
      }
    });
  }


class Main extends Component {
  
    static contextType = UserContext;

    constructor(props) {
  
        super(props);
        
        this.userProps = {
            owner:"",
            email : "",
            phone_number: "",
        }        
  
        if (this.context) {
            console.log(this.context);
        }else {
            console.log("No context found");
        }

        // console.log(statusBarHidden);

        this.drawerWidth = props.drawerWidth;

        this.redirectToSignin = false;
        this.state = {};
        this.getAuthUser = this.getAuthUser.bind(this);
        this.toastError = this.toastError.bind(this);
        this.toastSuccess = this.toastSuccess.bind(this);
        this.logUserEvent = this.logUserEvent.bind(this);
        this.convertToFriendlyMessage = this.convertToFriendlyMessage.bind(this);

        const urlParams = new URLSearchParams(window.location.search);
        this.code = urlParams.get('code');
        this.code = urlParams.get('state');
        this.code = urlParams.get('realmId');

    }

    componentDidMount() {

        const { statusBarHidden, setStatusBarHidden, drawerWidth } = this.context;
        console.log("Component Did mount");
        // this.getAuthUser();

        // Hub.listen("auth", ({ payload: { event, data } }) => {        
        //   switch (event) {
        //     case "signIn":
        //     console.log(data);
        //     break;
        //     case "signOut":
        //     console.log("Signout");
        //     break;
        //     case "customOAuthState":
        //     console.log(data);
        //   }
        // });
        
        // Hub.listen('error', onErrorListener);
        // Analytics.record({ name: 'Launch-Main' });
      
    }

    componentWillUnmount() {
    
        // Hub.remove('auth', this.hubListener);
    }

    convertToFriendlyMessage(message){
        if (message.includes("ConditionalCheckFailedException")) {
            return "The conditional request failed."
        }
        return message;
    }

    logUserEvent(userEventMsg, msgType) {        
        let logId = moment().toISOString();
        API.graphql(graphqlOperation(mutations.createUserItem, {input: 
            {
                owner:this.userProps.owner,
                createdAt:moment().toISOString(),
                type:"Log",
                id:logId,
                messageType:msgType,
                message:userEventMsg
        }})).then(result=>{console.log(result)}).catch(error => {
            // if (error.errors[0]) {
                // this.toastError(error.errors[0].message);        
            // }
        })
        return logId;
    }

    toastError(errorMsg){
        console.log(errorMsg);
        // toast.update(toastId, {
        //     type: toast.TYPE.ERROR,
        //     render: <Img foo={bar}/>
        //   });

        Hub.dispatch(
            'error',
            {               
                error:errorMsg,
                code:"400",
                url:"https://app.inventoryshark.io"   
            });


        errorMsg = this.convertToFriendlyMessage(errorMsg);
        let logId = this.logUserEvent(errorMsg, "error");

        toast.error(<>

        <ErrorOutlineIcon className="toastify-icon"/><h3 style={{paddingLeft:"2em"}}>Error</h3><p>{errorMsg}</p> <p>{logId}</p>

            <Tooltip arrow title="Notify the developer of the issue.">

                <div className="toastify-button" style={{color:"blue !important"}} onClick={async ()=> {
                    let postMsg  = {           
                        body: {
                            email:this.userProps.email,
                            title:"Inventory Shark Error Support",
                            message:errorMsg
                    }      
                };
    
                const result = await API.post("LambdaAPIUser", "/app/SendToSupport", postMsg).then(async response => {
                    console.log(response);
                }).catch(e=>{console.log(e)});
                        toast.dismiss();
                    }}>Send to Support</div>
            
            </Tooltip></>,
             {
                    position: toast.POSITION.BOTTOM_RIGHT,            
                    closeOnClick:false,
                    hideProgressBar:true,
                    pauseOnFocusLoss: true,
                    autoClose: false
                });
            }

            toastSuccess(successMsg){
                toast.success(successMsg, {
                    position:toast.POSITION.TOP_RIGHT
                });   
    }


    async getAuthUser() {

        await Auth.currentAuthenticatedUser({
            bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
          }).then(async user => {                    

            let userSettings = {};
            let attributes = user.attributes;
            const userAttributes = mapToArrayOfStrings(attributes);

            Analytics.updateEndpoint({
                address: attributes.email,     
                 channelType: 'EMAIL',   
                 optOut: 'NONE',      
                 userId: attributes.sub,     
                 userAttributes,    
            });

            console.log("listingsByOwnerType:")

            await API.graphql(graphqlOperation(customQueries.settingsByOwnerType, { 
                    owner:user.username,                    
                    type:"Settings",
                    id:"master",    
            })).then(result=>{      
                console.log(result);        
                if (result.data.listingsByOwnerType.items) {
                    for (let i = 0; i < result.data.listingsByOwnerType.items.length; i++) {
                        let item = result.data.listingsByOwnerType.items[i];
                        if (item.id == "master") {
                            userSettings = result.data.listingsByOwnerType.items[0]
                        }
                    }
                }
            }).catch(error => {
                if (error.errors[0]) {
                    this.toastError(error.errors[0].message);        
                }
            })

            this.userProps = {
                email : user.attributes.email,
                phone_number : user.attributes.phone_number,
                owner: user.username,
                userSettings:userSettings
            }            
            
            this.setState({dirty:!this.state.dirty});
            
        }).catch(e=>{
            
            console.log(e);
            this.redirectToSignin = true;
            this.setState({dirty:!this.state.dirty});

        })    

    }
    
    
    render() {
        
        return (            
            <Styles>                
                <Loader fadeOut={true}/>
                <UiDisplay/>
                <div style={{marginLeft:this.drawerWidth}}>                
                    <Switch>                
                        <Redirect exact path="/" to='/dashboard'/>
                        <Route exact path="/signin" component={Signin} />
                        <Route exact path="/signout" component={Signout} />
                        <Route exact path="/reports" component={()=> <Reports toastError={this.toastError} toastSuccess={this.toastSuccess}  userProps={this.userProps}/>} />
                        <Route exact path="/products" component={()=> <Products toastError={this.toastError} toastSuccess={this.toastSuccess} userProps={this.userProps}/>} />                        
                        <Route exact path="/orders" component={()=> <Orders toastError={this.toastError} toastSuccess={this.toastSuccess} userProps={this.userProps}/>} />                        
                        <Route exact path="/dashboard" component={()=> <Dashboard toastError={this.toastError} toastSuccess={this.toastSuccess} userProps={this.userProps}/>} />                        
                        <Route exact path="/authorize" component={()=> <Authorize toastError={this.toastError} toastSuccess={this.toastSuccess} userProps={this.userProps}/>} />                        
                        <Route exact path="/authorizecallback" component={()=> <AuthorizeCallback toastError={this.toastError} toastSuccess={this.toastSuccess} userProps={this.userProps}/>} />                                                
                        <Route exact path="/settings" component={()=> <Settings toastError={this.toastError} toastSuccess={this.toastSuccess} userProps={this.userProps}/>} />
                    </Switch>
                </div>
                <ToastContainer limit={1} />                              
            </Styles>
        )
    }
}

export default withRouter(Main);