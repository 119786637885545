
import React, {useState, useContext} from 'react';
import Button from '@material-ui/core/Button';
import { API } from 'aws-amplify';
import UiContext from "../../context/ui";
import {itemsByOwnerId} from "../../graphql/queries";
import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";
import UserContext from "../../context/user";
import  {GetDbItemObject} from "../Helper";


import { graphqlOperation } from "aws-amplify";
const moment = require('moment');

function QuickbooksLink(props) {

    const [hasQuickbooksLink, setHasQuickbooksLink] = useState(false);
    const { sendAlertMessage } = useContext(UiContext);
    const { user } = useContext(UserContext);


// amazonClosingFee: 0
// amazonReferralFee: -3.28​
// fbaFees: 0
// fbaFulfillmentFee: -3.31
// giftWrap: 0
// giftWrapChargeback: 0
// giftWrapCommission: 0
// id: "111-1754732-3567434"
// itemTax: 0
// itemTaxShipping: 0
// itemTaxWithheld: -0.88
// itemTaxWithheldShipping: 0
// postedDateTime: "2020-07-10 10:38:27 UTC"
// productCharge: 21.88
// promotionCharge: 0
// settlementId: "13117227311"
// shippingCharge: 0
// shippingChargeback: 0
// skus: Array [ {…} ]
// totalFees: -6.59
// totalPromotionShipping: 0
// totalRefundCommission: 0
// totalRefundExpenses: 0
// totalRefundSales: 0
// totalRevenue: 14.409999999999997

        
    const createOrderInput = async (orderInput, item) => {

        if (orderInput, item) {

            let order = GetDbItemObject(orderInput, "Order");
            // let orderItemArray = [];
            // for (let i = 0; i < result.result.length; i++) {
            //   let item = result.result[i];
              let orderItem = {
                ASIN: item.ASIN,
                IsGift: item.IsGift,
                IsTransparency: item.IsTransparency,
                ConditionId: item.ConditionId,
                ConditionSubtypeId: item.ConditionSubtypeId,
                ConditionNote: item.ConditionNote,
                OrderItemId: item.OrderItemId,
                GiftWrapPrice: item.GiftWrapPrice,
                GiftWrapTax: item.GiftWrapTax,
                ProductInfo: item.ProductInfo,
                TaxCollection: item.TaxCollection ? {
                  ResponsibleParty: item.TaxCollection.ResponsibleParty,
                  Model: item.TaxCollection.Model
                } : null,
                QuantityOrdered: item.QuantityOrdered,
                QuantityShipped: item.QuantityShipped,
                Title: item.Title,
                ShippingPrice: item.ShippingPrice,
                ShippingTax: item.ShippingTax,
                ShippingDiscount: item.ShippingDiscount,
                ShippingDiscountTax: item.ShippingDiscountTax,
                PromotionDiscount: item.PromotionDiscount,
                SellerSKU: item.SellerSKU,
                SerialNumberRequired: item.SerialNumberRequired,
                PromotionDiscountTax: item.PromotionDiscountTax,
                ItemPrice: item.ItemPrice ? {
                  Amount: item.ItemPrice.Amount,
                  CurrencyCode: item.ItemPrice.CurrencyCode
                } : null,
                ItemTax: item.ItemTax ? {
                  Amount: item.ItemTax.Amount,
                  CurrencyCode: item.ItemTax.CurrencyCode
                } : null,
                GiftMessageText: item.GiftMessageText,
              }
    
              if (item.PromotionIds) {
                let promotionIdsArray = [];
                for (let i = 0; i < item.PromotionIds.length; i++) {
                  promotionIdsArray.push({ PromotionId: item.PromotionIds[i] })
                }
                orderItem['PromotionIds'] = promotionIdsArray;
              }
              
              let keys = Object.keys(orderItem);
              for (let i = 0; i < keys.length; i++) {
                if (orderItem[keys[i]]) {
                  if (keys[i] == 'IsGift' || keys[i] == 'IsTransparency') {
                    orderItem[keys[i]] = parseInt(orderItem[keys[i]]) >= 1 ? false : true;
                  }
                } else {
                  delete (orderItem[keys[i]])
                }
            }
            //   }
    
              
            //orderItemArray.push(orderItem);
    
            order.OrderItem = [orderItem];

            if (order.ShippingAddress) {                
                // Zip code and state for tax obligation calculations
                order['OrderZipCode'] = order.ShippingAddress.PostalCode ? order.ShippingAddress.PostalCode : "";
                order['OrderState'] = order.ShippingAddress.StateOrRegion ? order.ShippingAddress.StateOrRegion : "";
                order['OrderCountry'] = order.ShippingAddress.CountryCode ? order.ShippingAddress.CountryCode : "";
                order['OrderCity'] = order.ShippingAddress.City ? order.ShippingAddress.City : "";
              } 

            
            return order;
        }

        return null;
    }

    const updateInvoiceFromInput = async (invoiceInput, invoiceTypeFound, quickBooksRealmId, quickBooksToken)=>{

        if (invoiceInput.qbInvoice) {
            delete(invoiceInput.qbInvoice);
        }
        

        await API.get("LambdaAPIUser","/app/updateInvoice?token=" + quickBooksToken + "&realmId="+quickBooksRealmId+"&invoice="+encodeURIComponent(JSON.stringify(invoiceInput))).then(async response => {
    
            if (response.Error) {
                let errorJson = JSON.parse(response.Error)
                console.log("ERROR :", errorJson);
                sendAlertMessage("There was an issue creating the invoice", invoiceInput.id);
            } else {
                       
                console.log("SUCCESS:", response);

                if (response.body) {
                    // let invoiceBody = JSON.parse(response.body);                    
                    // if (invoiceBody) {
                    //     let invoice = invoiceBody.Invoice;
                    //     if (invoice) {
    
                    //             delete(invoiceInput.ShipAddr);
                    //             delete(invoiceInput.ShipFromAddr);
    
                    //             if (invoiceTypeFound) {         
    
                    //                 await API.graphql(graphqlOperation(mutations.updateUserItem, {input: 
                    //                     {
                    //                         owner:user.sub,
                    //                         ...invoiceInput,
                    //                         type:"Invoice",
                    //                         id:invoiceInput.id,
                    //                         invoiceId:invoice.Id,
                    //                         createdAt:moment(invoiceInput.postedDateTime.replace(" UTC", "")).toISOString(),
                    //                     }})).then(result=>{               
                                        
                    //                     }
                    //                         ).catch(error => {
                    //                         console.log("ERROR");
                    //                         console.log(error);
                    //                 });
                    //             }else {
    
                    //                 await API.graphql(graphqlOperation(mutations.createUserItem, {input: 
                    //                     {
                    //                         owner:user.sub,
                    //                         ...invoiceInput,
                    //                         type:"Invoice",
                    //                         id:invoiceInput.id,
                    //                         invoiceId:invoice.Id,
                    //                         createdAt:moment(invoiceInput.postedDateTime.replace(" UTC", "")).toISOString(),
                    //                     }})).then(result=>{               
                                        
                    //                     }
                    //                         ).catch(error => {
                    //                         console.log("ERROR");
                    //                         console.log(error);
                    //                 });
                    //             }                    
                    //     }
                    // }
                }
            }
    
            if (response.error) {
    
                if (response.error && response.error.error == "Unauthorized") {
                    // getAccessToken();    
                }
                if (response.error && response.error.error == "Forbidden") {
                    // getAccessToken();    
                }
            }else {
    
                
            }
            // if (response.CompanyInfo) {
            //     companyInfoObject = response.CompanyInfo;
            //     window.localStorage.setItem("companyInfo", JSON.stringify(companyInfoObject))
            //     forceUpdate();
            // }
        }).catch(e=>{
            console.log("ERROR FOUND:",e);            
        });
        
        }
    

    const createInvoiceFromInput = async (invoiceInput, invoiceTypeFound, quickBooksRealmId, quickBooksToken)=>{

        await API.get("LambdaAPIUser","/app/createInvoice?token=" + quickBooksToken + "&realmId="+quickBooksRealmId+"&invoice="+encodeURIComponent(JSON.stringify(invoiceInput))).then(async response => {
    
            if (response.Error) {
                let errorJson = JSON.parse(response.Error)
                console.log("ERROR :", errorJson);
                sendAlertMessage("There was an issue creating the invoice", invoiceInput.id);
            } else {
                        
                if (response.body) {
                    let invoiceBody = JSON.parse(response.body);
                    
                    if (invoiceBody) {
                        let invoice = invoiceBody.Invoice;
                        if (invoice) {
    
                                delete(invoiceInput.ShipAddr);
                                delete(invoiceInput.ShipFromAddr);
    
                                if (invoiceTypeFound) {         
    
                                    await API.graphql(graphqlOperation(mutations.updateUserItem, {input: 
                                        {
                                            owner:user.sub,
                                            ...invoiceInput,
                                            type:"Invoice",
                                            id:invoiceInput.id,
                                            invoiceId:invoice.Id,
                                            createdAt:moment(invoiceInput.postedDateTime.replace(" UTC", "")).toISOString(),
                                        }})).then(result=>{               
                                        
                                        }
                                            ).catch(error => {
                                            console.log("ERROR");
                                            console.log(error);
                                    });
                                }else {
    
                                    await API.graphql(graphqlOperation(mutations.createUserItem, {input: 
                                        {
                                            owner:user.sub,
                                            ...invoiceInput,
                                            type:"Invoice",
                                            id:invoiceInput.id,
                                            invoiceId:invoice.Id,
                                            createdAt:moment(invoiceInput.postedDateTime.replace(" UTC", "")).toISOString(),
                                        }})).then(result=>{               
                                        
                                        }
                                            ).catch(error => {
                                            console.log("ERROR");
                                            console.log(error);
                                    });
                                }                    
                        }
                    }
                }
            }
    
            if (response.error) {
    
                if (response.error && response.error.error == "Unauthorized") {
                    // getAccessToken();    
                }
                if (response.error && response.error.error == "Forbidden") {
                    // getAccessToken();    
                }
            }else {
    
                
            }
            // if (response.CompanyInfo) {
            //     companyInfoObject = response.CompanyInfo;
            //     window.localStorage.setItem("companyInfo", JSON.stringify(companyInfoObject))
            //     forceUpdate();
            // }
        }).catch(e=>{
            console.log("ERROR FOUND:",e);            
        });
        
        }
    

    const createRefundFromInput = async (invoiceInput, refundTypeFound,  quickBooksRealmId, quickBooksToken)=>{
    
        console.log("createRefundFromInput:", invoiceInput, refundTypeFound, quickBooksRealmId, quickBooksToken)
        await API.get("LambdaAPIUser","/app/createRefund?token=" + quickBooksToken + "&realmId="+quickBooksRealmId+"&invoice="+encodeURIComponent(JSON.stringify(invoiceInput))).then(async response => {
    
            if (response.Error) {
                let errorJson = JSON.parse(response.Error)
                console.log("ERROR :", response);
                sendAlertMessage("There was an issue creating the invoice", invoiceInput.id);
            } else {
                        
                if (response.body) {
                    let invoiceBody = JSON.parse(response.body);
                    
                    if (invoiceBody) {
                        let invoice = invoiceBody.Invoice;
                        if (invoice) {
    
                                delete(invoiceInput.ShipAddr);
                                delete(invoiceInput.ShipFromAddr);
    
                                if (refundTypeFound) {         
    
                                    await API.graphql(graphqlOperation(mutations.updateUserItem, {input: 
                                        {
                                            owner:user.sub,
                                            ...invoiceInput,
                                            type:"Refund",
                                            id:invoiceInput.id,
                                            invoiceId:invoice.Id,
                                            createdAt:moment(invoiceInput.postedDateTime.replace(" UTC", "")).toISOString(),
                                        }})).then(result=>{               
                                                
                                                }
                                            ).catch(error => {
                                            console.log("ERROR");
                                            console.log(error);
                                    });

                                }else {
    
                                    await API.graphql(graphqlOperation(mutations.createUserItem, {input: 
                                        {
                                            owner:user.sub,
                                            ...invoiceInput,
                                            type:"Refund",
                                            id:invoiceInput.id,
                                            invoiceId:invoice.Id,
                                            createdAt:moment(invoiceInput.postedDateTime.replace(" UTC", "")).toISOString(),
                                        }})).then(result=>{               
                                        
                                        }
                                            ).catch(error => {
                                            console.log("ERROR");
                                            console.log(error);
                                    });
                                }                    
                        }
                    }
                }
            }
    
            if (response.error) {
    
                if (response.error && response.error.error == "Unauthorized") {
                    // getAccessToken();    
                }
                if (response.error && response.error.error == "Forbidden") {
                    // getAccessToken();    
                }
            }

            // if (response.CompanyInfo) {
            //     companyInfoObject = response.CompanyInfo;
            //     window.localStorage.setItem("companyInfo", JSON.stringify(companyInfoObject))
            //     forceUpdate();
            // }
        }).catch(e=>{
            console.log("ERROR FOUND:",e);            
        });
        
        }
        
    const createQuickbooksInvoiceFromCreatedOrder = async(invoiceInput, itemOrder) => {


        await createInvoiceQuickbookInput(invoiceInput, itemOrder, false );

    }

    const createQuickbooksRefundFromCreatedOrder = async(invoiceInput, itemOrder) => {

        

        await createInvoiceQuickbookInput(invoiceInput, itemOrder, false );

    }


    const updateInvoiceQuickbookInput  = async (invoiceInput, item, invoiceTypeFound)=>{

        let orderState = item.OrderState;
        let orderZipCode = item.OrderZipCode;
        let orderCity = item.OrderCity;
        let orderCountry = item.OrderCountry;
        let orderFulfillmentChannel = item.FulfillmentChannel;
        let orderItem = item.OrderItem;
        // let orderItem = [];

        invoiceInput.ShipAddr = {
            City:orderCity,
            Country:orderCountry,
            PostalCode:orderZipCode,
            CountrySubDivisionCode:orderState,
        }
    
        if (orderFulfillmentChannel == "MFN") {
            invoiceInput.ShipFromAddr = {
                Line1:orderFulfillmentChannel,
                Line2:"",
                City:"Austin",
                CountrySubDivisionCode:"TX",
                PostalCode:"78731",
                Lat:"",
                Long:""                                
            }
        
        }else {
            invoiceInput.ShipFromAddr = {
                Line1:orderFulfillmentChannel,
                Line2:"",
                City:"",
                CountrySubDivisionCode:"",
                PostalCode:"",
                Lat:"",
                Long:""                                
            }
        
        }
    
        if (invoiceInput.skus) {
            for (let y = 0; y < invoiceInput.skus.length; y++) {
                let sku = invoiceInput.skus[y].sku;
                for (let x = 0; x < orderItem.length; x++) {
                    let item = orderItem[x];
                    if (item.SellerSKU == sku) {
                        invoiceInput.skus[y].asin = item.ASIN;                                            
                        invoiceInput.skus[y].title = item.Title;
                    }
                }                                    
            }                                
        }

        invoiceInput.orderFulfillmentChannel = orderFulfillmentChannel;

        let quickBooksRealmId = window.localStorage.getItem("quickBooksRealmId");
        let quickBooksToken = window.localStorage.getItem("quickBooksToken");    
        
        if (quickBooksToken && quickBooksToken != undefined && quickBooksToken != "undefined" && quickBooksToken.length > 1) {
            quickBooksRealmId = quickBooksRealmId.replace(/\"/g,"");           

            await updateInvoiceFromInput(invoiceInput, invoiceTypeFound, quickBooksRealmId, quickBooksToken);
            
        }else {
            // getAccessToken();    
        }

    }


    const createInvoiceQuickbookInput = async (invoiceInput, item, invoiceTypeFound)=>{

        let orderState = item.OrderState;
        let orderZipCode = item.OrderZipCode;
        let orderCity = item.OrderCity;
        let orderCountry = item.OrderCountry;
        let orderFulfillmentChannel = item.FulfillmentChannel;
        let orderItem = item.OrderItem;
        // let orderItem = [];

        invoiceInput.ShipAddr = {
            City:orderCity,
            Country:orderCountry,
            PostalCode:orderZipCode,
            CountrySubDivisionCode:orderState,

        }
    
        if (orderFulfillmentChannel == "MFN") {
            invoiceInput.ShipFromAddr = {
                Line1:orderFulfillmentChannel,
                Line2:"",
                City:"Austin",
                CountrySubDivisionCode:"TX",
                PostalCode:"78731",
                Lat:"",
                Long:""                                
            }
        
        }else {
            invoiceInput.ShipFromAddr = {
                Line1:orderFulfillmentChannel,
                Line2:"",
                City:"",
                CountrySubDivisionCode:"",
                PostalCode:"",
                Lat:"",
                Long:""                                
            }
        
        }
    
        if (invoiceInput.skus) {
            for (let y = 0; y < invoiceInput.skus.length; y++) {
                let sku = invoiceInput.skus[y].sku;
                for (let x = 0; x < orderItem.length; x++) {
                    let item = orderItem[x];
                    if (item.SellerSKU == sku) {
                        invoiceInput.skus[y].asin = item.ASIN;                                            
                        invoiceInput.skus[y].title = item.Title;
                    }
                }                                    
            }                                
        }

        invoiceInput.orderFulfillmentChannel = orderFulfillmentChannel;

        let quickBooksRealmId = window.localStorage.getItem("quickBooksRealmId");
        let quickBooksToken = window.localStorage.getItem("quickBooksToken");    
        
        if (quickBooksToken && quickBooksToken != undefined && quickBooksToken != "undefined" && quickBooksToken.length > 1) {
            quickBooksRealmId = quickBooksRealmId.replace(/\"/g,"");           

            await createInvoiceFromInput(invoiceInput, invoiceTypeFound, quickBooksRealmId, quickBooksToken);
            
        }else {
            // getAccessToken();    
        }

    }

    const createRefundQuickbookInput = async (invoiceInput, item, refundTypeFound)=>{

        // let orderState = item.OrderState;
        // let orderZipCode = item.OrderZipCode;
        // let orderCity = item.OrderCity;
        // let orderCountry = item.OrderCountry;
        // let orderFulfillmentChannel = item.FulfillmentChannel;
        let orderItem = item.OrderItem;
     
        if (invoiceInput.skus) {
            for (let y = 0; y < invoiceInput.skus.length; y++) {
                let sku = invoiceInput.skus[y].sku;
                for (let x = 0; x < orderItem.length; x++) {
                    let item = orderItem[x];
                    if (item.SellerSKU == sku) {
                        invoiceInput.skus[y].asin = item.ASIN;                                            
                        invoiceInput.skus[y].title = item.Title;
                    }
                }                                    
            }                                
        }

        // invoiceInput.orderFulfillmentChannel = orderFulfillmentChannel;

        let quickBooksRealmId = window.localStorage.getItem("quickBooksRealmId");
        let quickBooksToken = window.localStorage.getItem("quickBooksToken");    
        
        if (quickBooksToken && quickBooksToken != undefined && quickBooksToken != "undefined" && quickBooksToken.length > 1) {
            quickBooksRealmId = quickBooksRealmId.replace(/\"/g,"");           
            await createRefundFromInput(invoiceInput, refundTypeFound, quickBooksRealmId, quickBooksToken);            
        }

    }

    const checkForInvoice = async (id)=>{
        let quickBooksRealmId = window.localStorage.getItem("quickBooksRealmId");
        let quickBooksToken = window.localStorage.getItem("quickBooksToken");    
        let foundInvoice = false;

        console.log("Check for invoice:", quickBooksRealmId, " quickBooksToken:", quickBooksToken );

        await API.get("LambdaAPIUser","/app/queryForInvoice?token=" + quickBooksToken + "&realmId="+quickBooksRealmId+"&id="+id).then(async response => {
            if (response.ErrorResponse) {
                // console.log("get order response:", response.ErrorResponse); 
            } else {
                // console.log("get order response:", response); 
                if (response && response.Invoice) {
                    // console.log("Found Invoice:", response.Invoice)
                    foundInvoice = true;
                }
            }
        }).catch(e =>{console.log(e)});

        return foundInvoice;
    }




    const updateInvoice = async (invoiceInput, isRefund)=>{
 
        let foundInvoice = await checkForInvoice(invoiceInput.id);

        if ((foundInvoice && window.confirm("An invoice was found, would you like to continue to update?")) || !foundInvoice) {
        
            let result = await API.graphql(graphqlOperation(itemsByOwnerId, {id:invoiceInput.id})).then(async result=>{ 

                if (result.data.itemsByOwnerId) {
    
                    let orderTypeFound = false;
                    let invoiceTypeFound = false;
                    let refundTypeFound = false;
                    let invoiceId = 0;
                    let itemOrder = null;
                
                    for (let i = 0; i < result.data.itemsByOwnerId.items.length; i++) {
                        let item = result.data.itemsByOwnerId.items[i];                    
                        if (item.type == "Order") {
                            orderTypeFound = true;
                            itemOrder = item;
                        }
                        if (item.type == "Invoice") {
                            invoiceTypeFound = true;
                            invoiceId = item.invoiceId
                        }
                        if (item.type == "Refund") {
                            refundTypeFound = true;
                            invoiceId = item.orderId;
                        }
                    }
    
                    if (orderTypeFound) {
                        
                        if (isRefund) {
                            // await createRefundQuickbookInput(invoiceInput, itemOrder, refundTypeFound );
                        }else {
                            if (!invoiceTypeFound) {                            
                                // await updateInvoiceQuickbookInput(invoiceInput, itemOrder, invoiceTypeFound );
                            }else {
                                console.log("Invoice Found:", invoiceId);
                                await updateInvoiceQuickbookInput(invoiceInput, itemOrder, invoiceTypeFound );
                            }
                        }
                    } else {
    
                        // let order = await getOrderFromId(invoiceInput.id);
                        // console.log("getOrderFromId:", order);                        
                        // let orderItems = getOrderListItems(invoiceInput.id);
                        // console.log("order items:", orderItems)
                        // let orderInput = await createOrderInput(order, orderItems);
                        // console.log("orderInput:", orderInput);
                        // let quickbooksData = await createOrderFromData(invoiceInput, orderInput, isRefund);
                        // console.log("createOrderFromData:", quickbooksData)
                    }  
                }        
            }).catch(e=>{
                console.log("There was an error", e);
            })
        } 
    }
    const createInvoice = async (invoiceInput, isRefund)=>{
 
        let foundInvoice = await checkForInvoice(invoiceInput.id);

        if ((foundInvoice && window.confirm("An invoice was found, would you like to continue to update?")) || !foundInvoice) {
        
            let result = await API.graphql(graphqlOperation(itemsByOwnerId, {id:invoiceInput.id})).then(async result=>{ 

                if (result.data.itemsByOwnerId) {
    
                    let orderTypeFound = false;
                    let invoiceTypeFound = false;
                    let refundTypeFound = false;
                    let invoiceId = 0;
                    let itemOrder = null;
                
                    for (let i = 0; i < result.data.itemsByOwnerId.items.length; i++) {
                        let item = result.data.itemsByOwnerId.items[i];                    
                        if (item.type == "Order") {
                            orderTypeFound = true;
                            itemOrder = item;
                        }
                        if (item.type == "Invoice") {
                            invoiceTypeFound = true;
                            invoiceId = item.invoiceId
                        }
                        if (item.type == "Refund") {
                            refundTypeFound = true;
                            invoiceId = item.orderId;
                        }
                    }
    
                    if (orderTypeFound) {
                        
                        if (isRefund) {
                            await createRefundQuickbookInput(invoiceInput, itemOrder, refundTypeFound );
                        }else {
                            if (!invoiceTypeFound) {                            
                                await createInvoiceQuickbookInput(invoiceInput, itemOrder, invoiceTypeFound );
                            }else {
                                console.log("Invoice Found:", invoiceId);
                            }
                        }
                    } else {
    
                        let order = await getOrderFromId(invoiceInput.id);
                        console.log("getOrderFromId:", order);                        
                        let orderItems = await getOrderListItems(invoiceInput.id);
                        console.log("order items:", orderItems)
                        let orderInput = await createOrderInput(order, orderItems);
                        console.log("orderInput:", orderInput);
                        let quickbooksData = await createOrderFromData(invoiceInput, orderInput, isRefund);
                        console.log("createOrderFromData:", quickbooksData)
                    }  
                }        
            }).catch(e=>{
                console.log("There was an error", e);
            })
        } 
    }

    const createOrderFromData = async (invoiceInput, orderInput, isRefund)=> {
        let result = await API.graphql(graphqlOperation(mutations.createUserItem, {input: 
            {
                ...orderInput,
                owner:user.sub,
                type:"Order"
            }})).then(async result=>{               
                
                if (isRefund) {
                    await createQuickbooksInvoiceFromCreatedOrder(invoiceInput, result.data.createUserItem);
                }else {
                    await createQuickbooksInvoiceFromCreatedOrder(invoiceInput, result.data.createUserItem);
                }
                
                }).catch(error => {
                console.log("ERROR creating order");
                console.log(error);
        });
        return result;
    }


    const getOrderListItems = async (id)=> {

        let postMsg = {
            body: {
                MWSAuthToken:"amzn.mws.8aaffafc-261b-087-b310-8dd98eeec4c5",
                SellerId:"A3LW268EJNMT20",
                AmazonOrderId:id
            }
        }

        const result = await API.post("LambdaAPIUser", "/app/ListOrderItems", postMsg).then(async responseListOrder => {

            if (responseListOrder.ListOrderItemsResponse && responseListOrder.ListOrderItemsResponse.ListOrderItemsResult && responseListOrder.ListOrderItemsResponse.ListOrderItemsResult.OrderItems)
            {
                return responseListOrder.ListOrderItemsResponse.ListOrderItemsResult.OrderItems.OrderItem;                            
            }
        }).catch(e=>{
            console.log("Error:", e);
        })

        return result;
    }


    const getOrderFromId = async (id)=>{

        let postMsg = {
            body: {
                MWSAuthToken:"amzn.mws.8aaffafc-261b-087-b310-8dd98eeec4c5",
                SellerId:"A3LW268EJNMT20",
                AmazonOrderId:id
            }
        }

        const result = await API.post("LambdaAPIUser", "/app/GetOrder", postMsg).then(async response => {
    
            if (response.ErrorResponse) {
                console.log("get order response:", response.ErrorResponse); 
                return null;
            } else {
                if (response.GetOrderResponse && response.GetOrderResponse.GetOrderResult && response.GetOrderResponse.GetOrderResult.Orders && response.GetOrderResponse.GetOrderResult.Orders.Order) {
                     return response.GetOrderResponse.GetOrderResult.Orders.Order;
                }            
            }
        }).catch(e =>{
            console.log(e);
        });

        return result;
    }

    const getTypeNameFromAmountDescription = (amountDescription) => {
        if (amountDescription == 'COMPENSATED_CLAWBACK') {
            return "Amazon Reversal Clawback";
        }        

        if (amountDescription == 'REVERSAL_REIMBURSEMENT') {
            return "Amazon Reversal Reimbursement";
        }        

        if (amountDescription == 'FREE_REPLACEMENT_REFUND_ITEMS') {
            return "Amazon Replacement Refund";
        }   
        
        if (amountDescription == 'REVERSAL_REIMBURSEMENT') {
            return "Amazon Reversal Reimbursement";
        }        

        if (amountDescription == 'PREPFEE_REFUND') {
            return "Amazon Prep Fee Refund";
        }        

        if (amountDescription == 'FREE_REPLACEMENT_REFUND_ITEMS') {
            return "Amazon Free Replacement Refund";
        }        

        if (amountDescription == 'WAREHOUSE_LOST') {
            return "Amazon Warehouse Lost Reimbursement";
        }        

        if (amountDescription == 'WAREHOUSE_DAMAGE') {
            return "Amazon Warehouse Damage Reimbursement";
        }
        if (amountDescription == 'Amazon Inventory FBA Fee') {
            return "Amazon Inventory FBA Fee";
        }        

        if (amountDescription == 'Amazon Inventory Placement Fee') {
            return "Amazon Inventory Placement Fee";
        }        

        if (amountDescription == 'Amazon Shipping Label Purchase') {
            return "Amazon Shipping Label Purchase";
        }        

      return "Amazon Expense";
    }
    
    const createExpense = async (expense) => {

        let quickBooksRealmId = window.localStorage.getItem("quickBooksRealmId");
        let quickBooksToken = window.localStorage.getItem("quickBooksToken");    
        
        if (quickBooksToken && quickBooksToken != undefined && quickBooksToken != "undefined" && quickBooksToken.length > 1) {
            quickBooksRealmId = quickBooksRealmId.replace(/\"/g,"");           
            
            let expenseInput = {
                TxnDate:expense.postedDateTime,
                totalAmt:expense.totalAmt,
                type:getTypeNameFromAmountDescription(expense.amountDescription),
                sku:expense.sku,
                orderId:expense.orderId,
                postedDate:expense.postedDateTime
            }

            await API.get("LambdaAPIUser","/app/createExpense?token=" + quickBooksToken + "&realmId="+quickBooksRealmId+"&expense="+encodeURIComponent(JSON.stringify(expenseInput))).then(async response => {
            


            }).catch(e=>{
                
                console.log(e);
            
                if (e.Error) {
                    sendAlertMessage(e.Error)
                }
                
            });
        }else {
            // getAccessToken();    
        }

    }
    
    const fixInvoice = async (invoice, order, orderItems) => {

        console.log("TRUE ORDER:", order, " DB INVOICE:", invoice, " ORDER DETAILS:", orderItems);



    }


    const checkInvoice = async (invoice)=>{
        
        console.log("Will fix dupe produce entries:", invoice);

         let totalRevenue = parseFloat(invoice.totalRevenue);
         let tax = parseFloat(invoice.itemTax);
         let total = (totalRevenue + tax).toFixed(2);
         let totalItems = invoice.skus.length;

         console.log("total:", total, "totalItems:", totalItems);

        let order = await getOrderFromId(invoice.id);
         
        console.log("getOrderFromId:", order);       

          if (order && order.OrderTotal) {
            let orderTotal = parseFloat(order.OrderTotal.Amount).toFixed(2);

            if (orderTotal && orderTotal > 0 && total > 0 && total && orderTotal != total) {
                console.log("ORDER TOTAL DOES NOT MATCH TOTAL:", orderTotal, total);
                let orderItems = await getOrderListItems(invoice.id);
                if (Array.isArray(orderItems)) {
                    if (orderItems.length == totalItems) {
                        console.log("correct length")
                    }else {
                        console.log("incorrect length");
                    }
                }else {
                    console.log("order items:", orderItems);
                    if (totalItems > 1) {
                        console.log("fix incorrect length");
                        await fixInvoice(invoice, order, orderItems);
                    } else {
                        console.log("correct length");
                    }
                }                
            } else {
                console.log("ALL GOOD:", orderTotal, total);
            }      
          }    
    }



    return (<>
        {
            hasQuickbooksLink ? <>Has Link</>:<>
                        
                          <div style={{textAlign:"right"}}>
                        
                            {
                                props.isExpense ? 
                                <Button onClick={()=>{
                                  createExpense(props.invoice)
                                }}>
                                Create Expense {props.invoice.id}
                                </Button> 
                                :
                                <>

                                    {/* <Button onClick={async ()=>{
                                        
                                        await checkForInvoice(props.invoice, false)
                                        }}>
                                        Check For Invoice {props.invoice.id}
                                        </Button>  */}

                                {
                                    props.isRefund ? <>
                                    
                                    <Button onClick={()=>{
                                        createInvoice(props.invoice, true)
                                    }}>
                                    Create Refund {props.invoice.id}
                                    </Button> 
                                    </>:<>
                                 
                                 {/* {
                                     console.log("props.invoice:", props.invoice)
                                 } */}
                                  <Button onClick={()=> {
                                                
                                                checkInvoice(props.invoice);

                                            }}>
                                                Check Invoice
                                                </Button>

                                    {
                                        props.invoice.hasQuickbooksInvoice ? <>                                                                                                                        
                                        {
                                            props.invoice.hasQuickbooksInvoice && props.invoice.qbInvoice ? <>
                                            
                                            <Button onClick={()=> {
                                                console.log("props.invoice:", props.invoice.qbInvoice);
                                                updateInvoice(props.invoice, false);                                                                                                
                                            }}>                                               
                                                Update QB Invoice
                                            </Button>

                                            </> :<></>
                                        }
                                           
                                        
                                        </> : <>
                                        <Button onClick={()=>{
                                        
                                        createInvoice(props.invoice, false)
                                        }}>
                                        Create Invoice {props.invoice.id}
                                        </Button> 
                                        </>
                                    }
                                    
                                    </>
                                }
                                </>                                
                            }                      


                      </div>
                </>
        }
    </>)
}

export default QuickbooksLink
