
import React,{useContext} /*, { useState } */from 'react'
import styled from 'styled-components'
import { useTable, useColumnOrder, useBlockLayout, useSortBy, usePagination, useResizeColumns, useFilters, useGlobalFilter } from 'react-table'
import matchSorter from 'match-sorter'
import {GetColumnDataForProductType} from "./TableColumns";
import { Pagination } from '@material-ui/lab';
import TableSortLabel from '@material-ui/core/TableSortLabel'
// import ProductContext from "../../context/products";

const Styles = styled.div`

height:80vh;
width:100%;
display:inline-block;
text-align:left;
margin-bottom:3em;

.table-pagination {
  position:sticky;
  top:0;
  background-color:white !important;
  z-index:2;
  display:block;
  height:3em;
 } 


.table-header-body {
  overflow:scroll;
  height:80vh;
}

table {
    font-size:1em;
    thead {
        display:inline-block;
        cursor: pointer;
        padding-bottom: 0px !important;
        border-top: 1px solid #d1d1d1;
        background: rgb(233,233,233);
        background: linear-gradient(0deg, rgba(233,233,233,1) 0%, rgba(255,255,255,1) 70%, rgba(240,240,240,1) 100%) !important;
        color: #306f9c;
        font-weight: 500;
    }
   
  .table-header {
    position:sticky;
    top:0em;
    background-color:white !important;
    z-index:1;
    display:block;
  }
  .table-image {
    max-height:5em;
  }

  tbody {
    display: inline-block;
    border-spacing: 0;
    padding-left:0 !important;
    padding-right:0 !important;
    padding-bottom:3em;
    border-bottom: 0px !important;      

    :last-child {
      border-right: 1px solid #d1d1d1 !important;    
    }
    tr { 
        :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }
    th {
      font-size:12px;
      padding-top:6px;
    },
    td {
      overflow:hidden;
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #d1d1d1;      
      border-right: 0px solid #d1d1d1;
      ${'' /* In this example we use an absolutely position resizer,
       so this is required. */}
      position: relative;
      :last-child {
        border-right: 0px;
        border-bottom: 1px solid #d1d1d1;      
      }
      .resizer {
        display: inline-block;
        background: transparent;
        width: 4px;
        height: 90%;
        margin-top:1%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;
        ${'' /* prevents from scrolling while dragging on touch devices */}
        touch-action:none;
        &.isResizing {
          background: blue;
        }
      }
      .resizer:hover {
        background: #ccc;
      }
    }
    }
  }
`

let nextToken = '';
let username = '';
let init = false;
let products = [];
let productsFiltered = [];
let productsPerPage = 25;
let searchText = "";
let showStatus = "all";
let showFulfilledBy  = "all";
let currentEditViewColumns = {};
let openedFilterPopover = false;
let openedFilterPopoverTarget = null;
let openedFilterPopoverColumn = null;
let highlightedColumn = "";
let hasRestoredColumnOrder = false;

function saveColumnPrefsToStorage(columnPrefs) {
    window.localStorage.setItem("columnPrefs", JSON.stringify(columnPrefs));
  }
  
  function getColumnPrefsFromStorage() {
    let jsonData = JSON.parse(window.localStorage.getItem("columnPrefs"))
    if (jsonData == null) {
      jsonData = {};
    }
    return jsonData;
  }
  
function getAllInitHiddenColumn() {
    let hiddenColumns = [];
    currentEditViewColumns = getColumnPrefsFromStorage();
    let keys = Object.keys(currentEditViewColumns);
      for (let i = 0; i < keys.length; i++) {        
          let key = keys[i];      
          if (currentEditViewColumns[key] == false) {
              hiddenColumns.push(key.toLowerCase());
          }      
      }    
    return hiddenColumns;
  }

  function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
  }
  
  // Let the table remove the filter if the string is empty
  fuzzyTextFilterFn.autoRemove = val => !val

  
function Table({columns, data, type}) {
    
    const [, updateState] = React.useState();

    const defaultColumn = React.useMemo(
        () => ({
          minWidth: 80,
          width: 140,
          maxWidth: 400,
          height:200,
          canFilter:false,
          Filter: DefaultColumnFilter
        }),
        []
      )
    
      const filterTypes = React.useMemo(
        () => ({
          // Add a new fuzzyTextFilterFn filter type.
          fuzzyText: fuzzyTextFilterFn,
          // Or, override the default text filter to use
          // "startWith"
          number: (rows, id, filterValue) => {
            return rows.filter(row => {
              const rowValue = row.values[id];
              let min = filterValue[0] ? filterValue[0] : 0;
              let max = filterValue[1] ? filterValue[1] : 99999999999;
              return rowValue !== undefined ? rowValue >= min && rowValue <= max : true
            })
          },
          textplus:(rows, id, filterValue) => {
            return rows.filter(row => {
              const rowValue = row.values[id];
      
              if (filterValue[0] == undefined) {
                filterValue[0] = "Contains";
              }
      
              if (filterValue[1] == undefined || filterValue[1] ===  "") {
                clearFilterForColumnId(id);
                return true;
              }
              
              if (filterValue[0] == "Contains") {
                return rowValue !== undefined
                ? String(rowValue)
                    .toLowerCase()
                    .includes(String(filterValue[1]).toLowerCase())
                : true          
              }
              if (filterValue[0] == "Not contains") {
                return rowValue !== undefined
                ? !String(rowValue)
                    .toLowerCase()
                    .includes(String(filterValue[1]).toLowerCase())
                : true          
              }
              if (filterValue[0] == "Equals") {
                return rowValue !== undefined
                ? String(rowValue)
                    .toLowerCase() == (String(filterValue[1]).toLowerCase())
                : true          
              }
              if (filterValue[0] == "Not Equal") {
                return rowValue !== undefined
                ? String(rowValue)
                    .toLowerCase() != (String(filterValue[1]).toLowerCase())
                : true          
              }
              if (filterValue[0] == "Start with") {
                return rowValue !== undefined
                ? String(rowValue)
                    .toLowerCase()
                    .startsWith(String(filterValue[1]).toLowerCase())
                : true          
              }
              if (filterValue[0] == "Ends with") {
                return rowValue !== undefined
                ? String(rowValue)
                    .toLowerCase()
                    .endsWith(String(filterValue[1]).toLowerCase())
                : true                    
              }
      
              return rowValue !== undefined
                ? String(rowValue)
                    .toLowerCase()
                    .startsWith(String(filterValue).toLowerCase())
                : true
            })
          },
          text: (rows, id, filterValue) => {
            return rows.filter(row => {
              const rowValue = row.values[id];
      
              if (filterValue == undefined) {
                clearFilterForColumnId(id);
              }
              
              return rowValue !== undefined
                ? String(rowValue)
                    .toLowerCase()
                    .startsWith(String(filterValue).toLowerCase())
                : true
            })
          },
        }),
        []
      )
    
                 
  function clearFilterForColumnId(id) {
    for (let y = 0; y < allColumns.length; y++) {
      let column = allColumns[y];
      if (column.id == id) {
        column.setFilter(undefined);      
      }      
    }
  }


  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
    }) {
    const count = preFilteredRows.length
    
    return (
        <input
        value={filterValue || ''}
        onChange={e => {
            setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
        }}
        placeholder={`Search ${count} records...`}
        />
    )
}


const {
    getTableProps,
    getTableBodyProps,
    getToggleHiddenProps,
    headerGroups,
    rows,
    allColumns,
    visibleColumns,
    prepareRow,
    setColumnOrder,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    // Get the state from the instance
    state : { pageIndex, pageSize, sortBy, globalFilter }
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: {
        pageSize : productsPerPage,
        hiddenColumns:getAllInitHiddenColumn()
      },
      // manualSortBy:false,
      // manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount:getTotalPages(products.length)
    },
    useBlockLayout,
    useResizeColumns,
    useColumnOrder,    
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  )

//   React.useEffect(() => {
//     gotoPage(0);
//   }, [sortBy]);


  function getTotalPages(items) {
    if (items.length > productsPerPage) {
      let pages = items.length / productsPerPage;
      let addPage = false;
      
      if (items.length % 1 == 0) {
        addPage = 1;
      }

      pages = parseInt(pages) + addPage;
      return pages;
    }      
  return 0;
}


return (
    <Styles>
        
        <div className="table-pagination">

        <Pagination style={{display:"inline-block", float:"right"}} count={getTotalPages(rows)} page={pageIndex + 1} showFirstButton showLastButton 
                onChange={(e,page)=>{                  
                  gotoPage(page-1);
              }}
        />     
        </div> 

        <div className="table-header-body">
        
        <table {...getTableProps()}>
            <thead className="table-header">
                {// Loop over the header rows
                    headerGroups.map(headerGroup => (
                        // Apply the header row props
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {// Loop over the headers in each row
                                headerGroup.headers.map((column, index) => (
                                    // Apply the header cell props
                                    <th {...column.getHeaderProps()}> 
                                        {// Render the header
                                            // column.render('Header')
                                          }

                        <p {  ...column.getSortByToggleProps()} className="header-product-p" data-column-index={index} >{column.render('Header')}                                            
                          <span ><TableSortLabel active={column.isSorted} direction={column.isSortedDesc ? 'desc' : 'asc'} /></span>      

                               </p>

                                    </th>
                                ))}
                        </tr>
                    ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {// Loop over the table rows
                    page.map((row, index) => {
                        // Prepare the row for display
                        prepareRow(row)
                        return (
                            // Apply the row props
                            <tr key={`row-${index}`} {...row.getRowProps()}>
                                {// Loop over the rows cells
                                    row.cells.map(cell => {
                                        // Apply the cell props
                                        return (
                                            <td {...cell.getCellProps()}>
                                                {// Render the cell contents
                                                    cell.render('Cell')}
                                            </td>
                                        )
                                    })}
                            </tr>
                        )
                    })}
            </tbody>
        </table>
        </div>
    </Styles>
)
}

function TableObject(props) {
  // const [, updateState] = React.useState();
  // const forceUpdate = React.useCallback(() => updateState({}), []);
  // const {products } = useContext(ProductContext);
  // console.log("PRODUCTS>", products);
  // const columns = React.useMemo(() => GetColumnDataForProductType(props.type, props.updateData));
  // console.log('Table object', products);
  const columns = GetColumnDataForProductType(props.type, props.updateData, props.saveData);
  return (<Table columns={columns} data={props.data} type={props.type} />)
}

export default TableObject