export const settingsByOwnerType = /* GraphQL */ `
  query ListingsByOwnerType(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listingsByOwnerType(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        owner
        type
        id
        createdAt
        updatedAt
        lastChecked
        mwsAuthToken
        sellerId
        phone
        name                
      }
      nextToken
    }
  }
`;
