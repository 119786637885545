
import React, {useEffect, useContext, useState} from 'react';
import styled from 'styled-components'
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { CircularProgress } from '@material-ui/core';
import Typography from "@material-ui/core/Typography";
import { API } from 'aws-amplify';
import CsvParser from "../yayfun/CsvParser";
import { graphqlOperation } from "aws-amplify";
import { createUserItem, updateUserItem} from "../../graphql/mutations";
import moment from 'moment';
import UserContext from "../../context/user";
import { uniqueId } from 'lodash';
import { getUserItem } from '../../graphql/queries';
import AmazonSettlementList from './AmazonSettlementList';
import { GetGroupsOfItems } from '../Helper'
import AmazonSettlementSelector from './AmazonSettlementSelector';
import MomentUTCUtils from '../yayfun/MomentUTCUtils';



const Styles = styled.div`
    padding:40px;
`

let connectionStatus = {};
let dataColumnNameIndexes = {};
let uniqueids = {};
const utils = new MomentUTCUtils();

// Amazon settlement reports
// Drag and drop cvs settlement reports
// Build quickbook invoices 

function AmazonSettlement(props) {

    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [loading, updateLoading]  = useState(false);
    const [checkingKeys, updateCheckingKeys]  = useState(false);
    const { signedIn, user, fetchSettlementReports, settlementReportsRef } = useContext(UserContext)
    const [settlementReports, setSettlementReports] = useState([]);


    console.log("AmazonSettlement props", props);

    var cardStyle = {
        transitionDuration: '0.3s',        
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"    
    }
    
    useEffect(() => {
        // async function fetchMyAPI() {

        //     console.log("fetchMyAPI:")
        //     let reports = await fetchSettlementReports();
        
        //     if (reports) {
        //         console.log("setSettlementReports:", reports);
        //         setSettlementReports(reports);
        //         forceUpdate();
        //     }
    
        // }
      
        //   fetchMyAPI();


//         // console.log("connectionStatus>");
//         // console.log(connectionStatus);
//         // if (connectionStatus && connectionStatus.status == "success") {

//         // } else {
//         //     checkConnectionStatus();
//         // }
// //        loadLocalSettlmentReports();


//         startFetchReports();

    }, []); //Empty array as second argument
    
    async function checkConnectionStatus() {
        
        if (props.userProps.userSettings && !checkingKeys) {
            updateCheckingKeys(true);
            let postMsg  = {           
                body: {
                    MWSAuthToken:""+props.userProps.userSettings.mwsAuthToken,
                    SellerId:""+props.userProps.userSettings.sellerId
              }      
            };

            const result = await API.post("LambdaAPIUser", "/app/ListMarketplaceParticipations", postMsg).then(async response => {
    
                if (response.ErrorResponse) {
                    return response;
                }
                if (response && response.ListMarketplaceParticipationsResponse) {
   
                    if (response.ListMarketplaceParticipationsResponse.ListMarketplaceParticipationsResult) {
                        let result = response.ListMarketplaceParticipationsResponse.ListMarketplaceParticipationsResult;
                        for (let i = 0; i < result.ListParticipations.Participation.length; i++) {
                            let participation = result.ListParticipations.Participation[i]; 
                            if (participation.MarketplaceId == "ATVPDKIKX0DER") {
                                connectionStatus.status = "success"
                                forceUpdate();
                            }
                        }
                    }
                }    
            }).catch(e =>{console.log(e)})

            updateCheckingKeys(false);

            
        } else {
            console.log("Skip");
        }
    }


    function loadLocalSettlmentReports() {
        
        // window.localStorage.getItem("")
        
        // setSettlementReports(reports);
    }

// 0: "settlement-id"
// 1: "settlement-start-date"
// 2: "settlement-end-date"
// 3: "deposit-date"
// 4: "total-amount"
// 5: "currency"
// 6: "transaction-type"
// 7: "order-id"
// 8: "merchant-order-id"
// 9: "adjustment-id"
// 10: "shipment-id"
// 11: "marketplace-name"
// 12: "amount-type"
// 13: "amount-description"
// 14: "amount"
// 15: "fulfillment-id"
// 16: "posted-date"
// 17: "posted-date-time"
// 18: "order-item-code"
// 19: "merchant-order-item-id"
// 20: "merchant-adjustment-item-id"
// 21: "sku"
// 22: "quantity-purchased"
// 23: "promotion-id"


function columnIndex(columnName) {          
    
  let result = dataColumnNameIndexes[columnName]; 

  if (result >= 0) {
      return result 
  }

  return -1;
}

    function uniqueId(dateTime) {
        if (uniqueids[dateTime] == undefined) {
            uniqueids[dateTime] = 1;   
        }else {
            uniqueids[dateTime] = uniqueids[dateTime] + 1;
        }
        return uniqueids[dateTime];
    }


    //TransactionType
//      Authorization – Funds debited from the Merchant account for an uncaptured authorization.
//  Capture – Funds captured against an authorization using the Capture operation.
//  Refund – Funds refunded against a previous Capture using the Refund operation.
//  Debt – Funds debited from a Merchant account when the merchant does not have a sufficient balance to cover a refund.
//  A-to-z Guarantee Claim – Funds debited from the Merchant account to resolve a consumer dispute filed with Amazon.
//  Chargeback – Funds debited from the Merchant account to resolve a consumer dispute filed with their financial institution.
//  Dispute – Funds debited from the Merchant account to initiate a dispute.
//  Adjustments – A miscellaneous credit or debit made by Amazon to your account.
//  Reserve – Funds held in your account to
// cover future refunds, A-to-z claims or
// chargebacks (see policy).
//  Carryover – Undisbursed funds carried over
// from previous settlement periods.
//  Transfer – Funds transferred by Amazon to
// your bank account.


// Alphanumeric, one of:
//  Authorization
//  Capture
//  Refund
//  Debt
//  A-to-z Guarantee Claim
//  Chargeback
//  Dispute
//  Adjustments
//  Reserve
//  Carryover
//  Transfer

    function convertSettlementItem(input) {
        console.log("convertSettlementItem:", input);

        if (input[columnIndex("posted-date-time")]) {

        }else {
            console.log("ERROR for:", input)
        }
         return {
            id:uniqueId(input[columnIndex("posted-date-time")]),
            settlementId :input[columnIndex("settlement-id")],
            settlementStartDate: input[columnIndex("settlement-start-date")],
            settlementEndDate: input[columnIndex("settlement-end-date")],
            depositDate:input[columnIndex("deposit-date")],
            totalAmount: input[columnIndex("total-amount")],
            currency: input[columnIndex("currency")],
            transactionType: input[columnIndex("transaction-type")],
            orderId: input[columnIndex("order-id")],
            merchantOrderId: input[columnIndex("merchant-order-id")],
            adjustmentId: input[columnIndex("adjustment-id")],
            shipmentId: input[columnIndex("shipment-id")],
            marketplaceName: input[columnIndex("marketplace-name")],
            amountType: input[columnIndex("amount-type")],
            amountDescription: input[columnIndex("amount-description")],
            amount: input[columnIndex("amount")],
            fulfillmentId: input[columnIndex("fulfillment-id")],
            postedDate: input[columnIndex("posted-date")],
            postedDateTime: input[columnIndex("posted-date-time")],
            orderItemCode: input[columnIndex("order-item-code")],
            merchantOrderItemId: input[columnIndex("merchant-order-item-id")],
            merchantAdjustmentItemId: input[columnIndex("merchant-adjustment-item-id")],
            sku: input[columnIndex("sku")],
            quantityPurchased: input[columnIndex("quantity-purchased")],
            promotionId: input[columnIndex("promotion-id")]
        }
    }

    function checkForValidData(row) {
        let validData = false;
        if (row[0] == "settlement-id" && row[1] == "settlement-start-date"
        && row[2] == "settlement-end-date") {
            validData = true;
        }
        return validData;
      }

    const delay = ms => new Promise(res => setTimeout(res, ms));


    const batchDeleteItems = async (results) => {
        

        console.log("batchDeleteItems results:", results);
        // console.log("batchCreateItems[0]:", results[0]);

        let items = [];
        // return;

        for(let i = 0; i < results.length; i++) {
            
            try {
                        
                let item = results[i];
                // console.log("item:", item);
                // if (!item[0] || !item[1]){
                //     continue;         
                // } 
                
                if (item[4] && parseFloat(item[4]) > 0) {
                    //If this has a value then it's the settelment number 
                    console.log("SETTLEMENT NUMBER", item);
                } else {
            
                    // let settlementInput = convertSettlementItem(item);
                    // let createdAt = moment(settlementInput["postedDateTime"].replace(" UTC", ""));
                    // console.log("settlementInput:", settlementInput);

                    items.push(item);

                    // API.graphql(graphqlOperation(createUserItem, {input: 
                    //     {
                    //         owner:user.sub,
                    //         createdAt:createdAt.toISOString(),
                    //         type:"Settlement",
                    //         ...settlementInput
                    // }})).then(result=>{console.log(result);}).catch(error => {
                    //     console.log(error);
                    //     // if (error.errors[0]) {
                    //         // this.toastError(error.errors[0].message);        
                    //     // }
                    // })
            
                }

            
            
                // await performActionOnItem(results[i]);
                
            } catch (err) {
                console.log('error performaing action on item:', err)
            }
        }


        // console.log("items:", items);

        let groups = GetGroupsOfItems(items, 10);

        console.log("Groups to upload:", groups.length);

        for (let i = 0; i < groups.length; i++) {

            let postMsg  = {           
                body: {
                    groups:groups[i]
              }      
            };
        
            console.log("postMsg:", postMsg);

            const result = await API.post("LambdaAPIUser", "/app/batchDeleteSettlementItem", postMsg).then(async response => {
    
                // console.log("RESULT:", response);
    
                return response;
            }).catch(e=>{console.log(e)});
                
            await delay(1000);

            console.log("batchDeleteSettlementItem result ::::", result);

        }

        console.log("Finished deleting")

    }
    
    const batchCreateItems = async (results) => {


        console.log("batchCreateItems[0]:", results[0]);

        let items = [];

        for(let i = 0; i < results.length; i++) {
            
            try {
                        
                let item = results[i];
                // console.log("item:", item);

                // if (!item[0] || !item[1]){
                //     continue;         
                // } 
                
                if (item[4] && parseFloat(item[4]) > 0) {
                    //If this has a value then it's the settelment number 
                    console.log("SETTLEMENT NUMBER", item);
                } else {
            
                    let settlementInput = convertSettlementItem(item);
                    let createdAt = moment(settlementInput["postedDateTime"].replace(" UTC", ""));

                    console.log("settlementInput:", settlementInput);

                    items.push(settlementInput);

                    // API.graphql(graphqlOperation(createUserItem, {input: 
                    //     {
                    //         owner:user.sub,
                    //         createdAt:createdAt.toISOString(),
                    //         type:"Settlement",
                    //         ...settlementInput
                    // }})).then(result=>{console.log(result);}).catch(error => {
                    //     console.log(error);
                    //     // if (error.errors[0]) {
                    //         // this.toastError(error.errors[0].message);        
                    //     // }
                    // })
            
                }

            
            
                // await performActionOnItem(results[i]);
                
            } catch (err) {
                console.log('error performaing action on item:', err)
            }
        }


        // console.log("items:", items);

        let groups = GetGroupsOfItems(items, 10);

        console.log("Groups to upload:", groups.length);

        for (let i = 0; i < groups.length; i++) {

            let postMsg  = {           
                body: {
                    groups:groups[i]
              }      
            };
    
            const result = await API.post("LambdaAPIUser", "/app/BatchUpdateSettlementItem", postMsg).then(async response => {
    
                // console.log("RESULT:", response);
    
                return response;
            }).catch(e=>{console.log(e)});
                
            await delay(1000);

            console.log("result ::::", result);

        }

        console.log("Finished uploading")

        
        // for (let x = 0; x < groups.length; x++) {
        //     let group = groups[x];
        //     let productOrders = [];
        //     for (i = 0; i < group.length; i++) {
        //         const cartItem = group[i];
        //         productOrders.push({
        //         PutRequest: {
        //             Item: {
                    
        
        
        //             }
        //         }
        //         });
        //     }
            
        //     let params = {
        //         RequestItems: {}
        //         };
        //         params["RequestItems"][PRODUCT_ORDER_TABLE] = productOrders;
        //         await documentClient.batchWrite(params).promise();
        // }
}

    async function dataHandler(data) {
        console.log("Data handler:", data);
        
        let validData = false;
        let results = [];
        dataColumnNameIndexes = {};

        let depositDate = '';
        let startDate = '';
        let endDate = '';
        let settlementId = '';
        let previousAmountBalance = '';
        let totalAmount = '';

        for (let i = 0; i < data.length; i++) {
            let row = data[i];
            if (i == 0) {                                          
                for (let x = 0; x < row.length; x++) {                        
                    dataColumnNameIndexes[row[x]] = x;
                }
                validData = checkForValidData(row);
            } else {
                if (validData) {
                    if (i == 1) {

                        // console.log("row:", row);
                        // console.log("row[columnIndex:", row[columnIndex("settlement-start-date")]);
                        totalAmount = row[columnIndex("total-amount")]
                        depositDate = utils.date(row[columnIndex("deposit-date")].replace(" UTC", "")).toISOString();
                        startDate = utils.date(row[columnIndex("settlement-start-date")].replace(" UTC", "")).toISOString();
                        endDate = utils.date(row[columnIndex("settlement-end-date")].replace(" UTC", "")).toISOString();
                        settlementId = row[columnIndex("settlement-id")];

                    }else {
                        if (row[columnIndex("amount-description")] == "Previous Reserve Amount Balance") {
                            previousAmountBalance = row[columnIndex("amount")];
                        } else {
                            results.push(row);
                        }
                    }                    
                }
            }
        }

        console.log("depositDate:", depositDate);
        console.log("startDate:", startDate);
        console.log("endDate:", endDate);

        const performActionOnResults = async (results)=>{

            console.log("performActionOnResults RESULTS", results);

            await batchCreateItems(results);
            
        }

        await API.graphql(graphqlOperation(createUserItem, {input: 
            {
                owner:user.sub,
                createdAt:depositDate,
                type:"SettlementReport",
                id:settlementId,
                status:'loading',
                settlementStartDate:startDate,
                settlementEndDate:endDate,
                totalAmount:totalAmount
        }})).then(async result=> {
            
            console.log(result);
            console.log("depositDate:", depositDate, " previousAmountBalance:",previousAmountBalance, "startDate:", startDate, "endDate:", endDate );

            await performActionOnResults(results);
    
            API.graphql(graphqlOperation(updateUserItem, {input: 
                {
                    owner:user.sub,
                    createdAt:depositDate,
                    type:"SettlementReport",
                    id:settlementId,
                    status:'complete',
                    settlementStartDate:startDate,
                    settlementEndDate:endDate,
                    totalAmount:totalAmount
            }})).then(result=> {
                console.log("updateUserItem:", result);
            })
        
        }).catch(error => {

            console.log("ERROR:::::",error);
            
            if (error.errors[0] && error.errors[0].errorType == "DynamoDB:ConditionalCheckFailedException") {            
                console.log("Found DynamoDB:ConditionalCheckFailedException");
                // $owner: String!
                // $type: String!
                // $createdAt: AWSDateTime!
                // $id: ID!
            let input =  {
                owner:user.sub,
                createdAt:depositDate,
                type:"SettlementReport",
                id:settlementId,
                settlementStartDate:startDate,
                settlementEndDate:endDate,
                totalAmount:totalAmount
            };

            console.log("get input", input);

                API.graphql(graphqlOperation(getUserItem, {...input })).then(async result=> {        
                    console.log("Found duplicate:", result);
                    let userItem = result.data.getUserItem;
                    if (userItem) {
                        if (userItem.status == "complete") {
                            console.log("Good, want to overwrite?");

                            await performActionOnResults(results);

                            API.graphql(graphqlOperation(updateUserItem, {input: 
                                {
                                    owner:user.sub,
                                    createdAt:depositDate,
                                    type:"SettlementReport",
                                    id:settlementId,
                                    status:'complete',
                                    settlementStartDate:startDate,
                                    settlementEndDate:endDate,
                                    totalAmount:totalAmount
                            }})).then(result=> {
                                console.log("updateUserItem:", result);
                            })
                
                        }
    
                        if (userItem.status == "loading") {
                            console.log("Still, loading want to cancel?");
                        }
                    }
                }).catch(e=>{console.log("ERROR GETTING INPUT", e)});
                
            }

            // this.toastError(error.errors[0].message);        
            // }
        })


        forceUpdate();
  }

  async function performActionOnItem(item) {

    // console.log("dataColumnNameIndexes:",dataColumnNameIndexes);
    // console.log("performActionOnItem:", item);

    if (item["4"] && parseFloat(item["4"]) > 0) {
            //If this has a value then it's the settelment number 

    } else {

        let settlementInput = convertSettlementItem(item);
        let createdAt = moment(settlementInput["postedDateTime"].replace(" UTC", ""));
        API.graphql(graphqlOperation(createUserItem, {input: 
            {
                owner:user.sub,
                createdAt:createdAt.toISOString(),
                type:"Settlement",
                ...settlementInput
        }})).then(result=>{console.log(result);}).catch(error => {
            console.log(error);
            // if (error.errors[0]) {
                // this.toastError(error.errors[0].message);        
            // }
        })

    }
    
    // let action = item[columnIndex("action")];
    // let id     = item[columnIndex("id")];

    // console.log("Start perform ",id, " - action :", action);

    // if (action == "A") {
    //     await API.graphql(graphqlOperation(createProduct, { input:inputObjectFromResults(item) }))
    // }else if (action == "U") {
    //     let inputObject = inputObjectFromResults(item);        
    //     await API.graphql(graphqlOperation(updateProduct, { input:inputObject })).then(result=>{console.log(result)}).catch(e=>{console.log(e)})            
    // }
    // else if (action == "D") {
    //     await API.graphql(graphqlOperation(deleteProduct, { input:{id:id}}))
    // } else {
    //     console.log("No action found")
    // }
    
    console.log("End action");
  }


    function displaySettlementReports() {
    
        console.log("::settlementReports::", settlementReports);
        {
          return  settlementReports && settlementReports.reports && settlementReports.reports.length > 0 ? settlementReports.reports.map(report=>{

                return(<>
                {
                <Card style={cardStyle} className="settlement-card">
                <CardHeader title={`ReportId:${report["id"]}`} />
                        <CardContent>
                        <p>{report["createdAt"]}</p>
                        <p>{report["status"]}</p>
                        <p>Updated:{report["updatedAt"]}</p>           
                        </CardContent>
                </Card>
                
                }
                </>)

            }) :<></>
        }
    }


    function displayCard() {
        return (<>
            <Card style={cardStyle} className="full-width-card">
            <CardHeader title="Amazon Settlement Reports" />
            
            {
                checkingKeys ? <>
                                <CardContent>                

                <div className="text-center">
                                    
                        <CircularProgress size={44} color="primary" />
                            </div>
                            </CardContent>
                            <CardActions className="no-drag">            
                            </CardActions>
                </>:<>
                
                <CardContent>                
                <Typography component="p">Import Settlement Reports by uploading the csv files below.</Typography>
                 {
                    loading ? <><div className="text-center">
                                    <CircularProgress size={44} color="primary" />
                                </div>
                                <p className="text-tiny-info text-center">Please wait...</p>
                                <br/>
                              </> : <></>
                }
                {
                    connectionStatus && connectionStatus.status == "success" ? <>Success</> :<></>
                }


            <CsvParser dataHandler={dataHandler} />

            </CardContent>
            <CardActions className="no-drag">            
                {
                    loading ? <>
                    <Button color="primary" onClick={()=>{updateLoading(false); forceUpdate();}}>Cancel</Button>
                    </>:<Button color="primary" onClick={()=>{updateLoading(true); forceUpdate();}}>
                        
                        {/* {
                            connectionStatus && connectionStatus.status == "success" ? "Refresh" : "Connect"
                        } */}
                        
                        
                        </Button>
                }                                
            </CardActions>
            </>

            }

        
        </Card>
    </>)
    }
    
    return (
    <Styles>
        {
            displayCard()
        }
        <AmazonSettlementSelector props={props} />  
        <AmazonSettlementList props={props} batchDeleteItems={batchDeleteItems} />
    </Styles>
)


}

export default AmazonSettlement
