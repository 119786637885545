import React , {useContext, useEffect} from 'react';
import styled from 'styled-components'
import { Auth, API } from "aws-amplify";
import { graphqlOperation } from "aws-amplify";
import * as queries from '../graphql/queries';
import {GetProductDataFromResults} from "./Data";
import TableObject from "./TableObject";
import moment from 'moment';
import UserContext from "../context/user";
import { result } from 'lodash';

const Styles = styled.div`
    text-align:center;
    padding-top:5em;
`

let username = '';
let init = false;
let nextToken = '';
let ordersPerPage = 10;
// let orders = [];

const PRODUCT_LIST_NEXT_TOKEN_LIMIT = 1000;

function Orders(props) {

  console.log("Orders>>>", props);

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [orders, setOrders] = React.useState([]);    
    const [ordersSalesTotal, setOrdersSalesTotal] = React.useState(0);
    const {fetchOrdersBetween } = useContext(UserContext)


    const startFetchOrders = async ()=>{

      let between = [moment("01/01/2023").startOf('day').toISOString(), moment("03/31/2023").endOf('day').toISOString()];
      let result = await fetchOrdersBetween(between);
      console.log("startFetchOrders RESULT:", result);
      setOrders(result);

    }
    useEffect(()=> {

      startFetchOrders();

    }, []);


    if (username == '') {

        if (!init) {
          init = true;          
          // authenticateLocalUser();
        }

      }
    
    // async function authenticateLocalUser() {
    //   console.log("start authenticateLocalUser")
    //    await  Auth.currentAuthenticatedUser({
    //       bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    //     }).then(async user => {
          
    //       console.log("AUTHENTICTED USER", user);         
    //       username = user.username;
          
    //       let data = await startProductsQuery(username);
          
    //       console.log("items:", data.items);

    //       let items = data.items;
          
    //       if (items) {
    //         // let ordersResult = await GetProductDataFromResults(items, orders);      
    //         console.log("SET ORDERS:", items);  
          
    //         setOrders(items);
            
    //         if (data.nextToken) {
    //           nextToken = data.nextToken;
    //           await continueListingProducts();
    //           forceUpdate();
    //         }

    //        forceUpdate()
    //       }      
    //     }).catch(err => console.log("ERROR", err));      
      
    //     console.log("finished authenticateLocalUser")
    //   }
      
      // API.graphql(graphqlOperation(listingsByOwnerType, 
      //   {owner:userId, type:"Settlement", createdAt:{between:between}, limit:1000})).then(async result=>{
      //     console.log("result:", result);
      //     let items = result.data.listingsByOwnerType.items;
      //     if (result.data.listingsByOwnerType.nextToken) {
      //         items = await fetchSettlementsBetweenNextToken(between, items, result.data.listingsByOwnerType.nextToken);
      //     }

      async function startProductsQuery(username) {

        console.log("startProductsQuery:", username);

        let between = [moment("01/01/2021").startOf('day').toISOString(), moment("03/31/2021").endOf('day').toISOString()];
  
        console.log("username:", username);
        console.log("between:", between);
        console.log("props.userProps.owner:", props.userProps.owner);

        let result = await API.graphql(
          graphqlOperation(
            queries.listingsByOwnerType,
            { 
              owner:props.userProps.owner,
              type:"Order",
              createdAt:{between:between},
              limit:1000})
            ).then(async result=>
          {
              console.log("startProductsQuery RESULT DATA ", result.data); 

              if (result.data.listingsByOwnerType.items) {
                  return {items:result.data.listingsByOwnerType.items, nextToken:result.data.listingsByOwnerType.nextToken};
              }
          }).catch( error => {                                       
            if (error.errors[0]) {
              props.toastError(error.errors[0].message);        
            }
            return [];
          });      
        return result;
      }


      async function continueListingProducts() {  

        console.log("continueListing Orders")
        if (nextToken && nextToken != "") {      
         await API.graphql(graphqlOperation(queries.listingsByOwnerType, { type:"Order", nextToken:nextToken, limit:PRODUCT_LIST_NEXT_TOKEN_LIMIT})).then(async response => {      
          let data = response.data.listingsByOwnerType;
          if (data.items) {
               
            setOrders({...orders, ...data.items});
            //orders = await GetProductDataFromResults(data.items, orders);
            }
            if (data.nextToken) {
              nextToken = data.nextToken;
              await continueListingProducts();  
            } else {
             nextToken = "";
            }
          }).catch(err => {
            console.log(err);
            nextToken = "";
          });
        }
      }

    const getFilteredOrders = (orders)=>{
        console.log("orders:", orders);
        if (orders){        
         
          let result = orders.filter(a => (a.OrderState && a.OrderState.toLowerCase() == "tx") || (a.OrderState && a.OrderState.toLowerCase() == "texas"));
          
          console.log("RESUULLLLLT:", result);

          let runningTotal = 0;
          for (let x = 0; x < result.length; x++) {

            let resultItem = result[x];

            if (resultItem.OrderItem) {
              console.log(resultItem.OrderItem);
              
              for (let i = 0; i < resultItem.OrderItem.length; i++) {
                let orderItem = resultItem.OrderItem[i];
                console.log("orderItem:", orderItem);
                if (orderItem.ItemPrice && orderItem.ItemPrice.Amount) {
                  console.log("orderItem.ItemPrice.Amount:", orderItem.ItemPrice.Amount);

                  runningTotal += parseFloat(orderItem.ItemPrice.Amount);
                }
              }
            }
            
  
          }

          //setOrdersSalesTotal(runningTotal);
          console.log("runningTotal:", runningTotal);
          
        return result;
      }
      return null;
    }

return (
    <Styles>
      {
        console.log("DRAW TABLE OBJECT:", orders)
      }
      <p>Total:{ordersSalesTotal}</p>
      {
        orders ? <TableObject data={getFilteredOrders(orders)} type={"Orders"}/> : <></>
      }
    </Styles>
)


}

export default Orders
