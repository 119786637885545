import React, { useEffect, useContext } from 'react';

function AuthorizeUI(props) {
    return (<><div className={"onboarding-container moveInFinished"}>
      <button className="mwsAuthTokenButton" onClick={()=>{                    
                        window.open("https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.3a3036b3-9d22-491f-9cec-2c210577a286&state=stateexample&version=beta", "_blank");                    
                    }}>                    
                        Authorize Your Seller Account                    
                </button>
                </div></>)
}

export default AuthorizeUI;