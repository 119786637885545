

import React, {useEffect, useContext, useState} from 'react';
import styled from 'styled-components'
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { CircularProgress } from '@material-ui/core';
import Typography from "@material-ui/core/Typography";
import { API } from 'aws-amplify';
import CsvParser from "../yayfun/CsvParser";
import { graphqlOperation } from "aws-amplify";
import { createUserItem, updateUserItem} from "../../graphql/mutations";
import moment from 'moment';
import UserContext from "../../context/user";
import { uniqueId } from 'lodash';
import { getUserItem } from '../../graphql/queries';
import Modal from '@material-ui/core/Modal';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TableObject from '../yayfun/TableObject';
import { makeStyles } from '@material-ui/core/styles';
import { CheckInvoiceExists, ConvertCentsToDollarCents, ConvertStringToDollarsCents, CalculateSettlementValues, CreateInvoice, BuildInvoiceData } from '../Helper';
import { DateRangePicker } from 'react-dates';
// import MomentUtils from '@date-io/moment'; // choose your lib
import MomentUTCUtils from '../yayfun/MomentUTCUtils';
import { CSVLink, CSVDownload } from "react-csv";
import {DisplayReportData, DisplayFetchedDataAsInvoice, DisplayFetchedDataAsExpense, DisplayFetchedDataAsRefund, BuildRefundData} from '../Helper';
import UiContext from "../../context/ui";

import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

const useStyles = makeStyles({
    root: {
      background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      borderRadius: 3,
      border: 0,
      color: 'white',
      height: 48,
      padding: '0 30px',
      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    },
    label: {
      textTransform: 'capitalize',
    },
    paperWidthSm: {
        maxWidth:"100%",
        margin:"10em"
    }
  });
  

const Styles = styled.div`
    padding:40px;
`

const delay = ms => new Promise(res => setTimeout(res, ms));

let connectionStatus = {};
let dataColumnNameIndexes = {};
let uniqueids = {};

function AmazonSettlementSelector(props) {

    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [loading, updateLoading]  = useState(false);
    const [viewAsInvoice, setViewAsInvoice]  = useState(false);
    const [viewAsExpense, setViewAsExpense]  = useState(false);
    const [viewAsRefund, setViewAsRefund] = useState(false);


    const [invoices, setInvoices]  = useState({});
    const [expenses, setExpenses]  = useState({});
    const [refunds, setRefunds]  = useState({});

    const [csvData, setCsvData] = useState([]);
    const [csvUnitsSoldData, setCsvUnitsSoldData] = useState([]);
    const { signedIn, user, settlementReports, fetchSettlementsBetween, batchGetItems } = useContext(UserContext)
    const { sendAlertMessage } = useContext(UiContext);


    const [openedReport, setOpenedReport] = useState(null);
    const [open, setOpen] = React.useState(false);
    const [fetching, setFetching] = React.useState(false);
    const [fetchedData, setFetchedData] = React.useState(false);
    const classes = useStyles();
    const [startDateValue, setStartDateValue] = React.useState(null);
    const [endDateValue, setEndDateValue] = React.useState(null);
    const [focusedInput, setFocusedInput] = React.useState(null);
    const [settlementsSelected, setSettlementsSelected] = React.useState([]);
    const [selectedDate, handleDateChange] = useState(moment());
    const [selectedEndDate, handleEndDateChange] = useState(moment());
    const [reportData, setReportData] = useState(null);

    const [creatingInvoices, setCreatingInvoices] = useState(false);
    const [creatingRefunds, setCreatingRefunds] = useState(false);

    // console.log("AmazonSettlementList props", props);

        
    useEffect(() => {
      let localSelectedDate = getFromLocalStorage("selectedDate");
      if (localSelectedDate) {
        handleDateChange(moment(localSelectedDate))
      }
      
      let localSelectedEndDate = getFromLocalStorage("selectedEndDate");
      if (localSelectedEndDate) {
        handleEndDateChange(moment(localSelectedEndDate))
      }

  }, []); //Empty array as second argument

    var cardStyle = {
        transitionDuration: '0.3s',        
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"    
    }

    const handleOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };
            
      const getFromLocalStorage = (key, value)=>{
        return window.localStorage.getItem(key)
      }
      
      const saveToLocalStorage = (key, value)=>{
        window.localStorage.setItem(key, value);
      }

      const fetchSettlementReport = async (report)=>{
        if (!fetching) {
            setFetching(true);
           let reports = await fetchSettlementsBetween([report["settlementStartDate"], report["settlementEndDate"]]);
        //    console.log("fetchSettlementReport BETWEEN:", reports);
           setFetchedData(reports);
           buildInvoices(reports);
           setFetching(false);
        }
      }

  

      const createRefunds = async (refunds) => {

        let keys = Object.keys(refunds);

        console.log("Total:", keys.length);

        for (let i = 0; i < keys.length; i++) {
          let key = keys[i];
          let refund = refunds[key];
          let refundInput = BuildRefundData(key, refund);
          console.log("Start Refund:", key, " - ", "" + i + "/" + keys.length);
          let result = await CreateInvoice(refundInput, true, user, sendAlertMessage);          
          if (result && result.Error) {
            console.log("BREAK OUT");
            break;
          }          
          await delay(1000);
        }
      }



    const checkForInvoice = async (id)=>{
      let quickBooksRealmId = window.localStorage.getItem("quickBooksRealmId");
      let quickBooksToken = window.localStorage.getItem("quickBooksToken");    
      let foundInvoice = false;
      await API.get("LambdaAPIUser","/app/queryForInvoice?token=" + quickBooksToken + "&realmId="+quickBooksRealmId+"&id="+id).then(async response => {
          if (response && response.ErrorResponse) {
              console.log("get order response:", response.ErrorResponse); 
          } else {
              // console.log("get order response:", response); 
              if (response && response.Invoice) {
                  // console.log("Found Invoice:", response.Invoice)
                  foundInvoice = true;
              }
          }
      }).catch(e =>{console.log(e)});

      return foundInvoice;
  }


      const createInvoices = async (invoices) => {
    
    
    
        let keys = Object.keys(invoices);
        console.log("Total:", keys.length);
    
        for (let i = 0; i < keys.length; i++) {
          let key = keys[i];
          let invoice = invoices[key];          
          
          let foundInvoice = await checkForInvoice(key);

          if (!foundInvoice) {
            console.log("Did not find invoice:", key)
            let invoiceInput = BuildInvoiceData(key, invoice);
            console.log("Start   :", key, " - ", "" + i + "/" + keys.length);
            let result = await CreateInvoice(invoiceInput, false, user, sendAlertMessage);          
            
            if (result && result.Error) {
              console.log("BREAK OUT");
              break;
            }          
            await delay(5000);
          } else {
            console.log("Found existing invoice:", key)
          }   
        }
      }



      const buildInvoices = async (report)=>{
        
        console.log("AMAZON SETTLEMENT buildInvoices:", report);
        let currentInvoices = {};
        let currentExpenses = {};
        let currentRefunds = {};
        let settlementPostDateTime = {};

        if (report) {

        let inventoryPlacementFees = {};
        let inventoryFBAFees = {};
        let shippingLabelPurchases = {};
        let settlementPostDateTime = {};

        for (let i = 0; i < report.length; i++) {
          
          let item = report[i];
          let transactionType = item["transactionType"];
          let amountType = item["amountType"];    
          let amountDescription = item["amountDescription"];      
          let orderId = item["orderId"]; 
          let settlementId = item["settlementId"]; 

          if (transactionType == "Order") {

            if (currentInvoices[orderId] == null) {
              currentInvoices[orderId] = [];
            } 

            currentInvoices[orderId].push(item)

            // if (amountDescription == "Principal" || amountDescription == "Shipping") {
            //   if (amountType == "ItemPrice" || amountType == "Promotion" ) {
            //     currentInvoices[orderId].push(item) 
            //   }  
            // }
  
            // if (amountType == "ItemWithheldTax") {
            //   currentInvoices[orderId].push(item) 
            // }
           
            // if (amountType == "ItemFees") {
            //   currentInvoices[orderId].push(item) 
            // }
          }
          
          if (transactionType == "Refund") {
       
            
            if (currentRefunds[orderId] == null) {
              currentRefunds[orderId] = [];
            } 
              
            currentRefunds[orderId].push(item); 

          }

          if (transactionType == "other-transaction") {


            if (amountDescription == "Current Reserve Amount") {


              if (settlementPostDateTime[settlementId] == null) {
                settlementPostDateTime[settlementId] = [];
              } 
              settlementPostDateTime[settlementId].push(item.postedDateTime); 
            }

            if (amountType == "FBA Inventory Reimbursement") {
              if (currentExpenses[settlementId] == null) {
                currentExpenses[settlementId] = [];
              } 
              currentExpenses[settlementId].push(item); 
            } else {
              
              if (amountDescription == "Inventory Placement Service Fee") {

                if (inventoryPlacementFees[settlementId] == null) {
                  inventoryPlacementFees[settlementId] = [];
                }   

                if (item) {
                  inventoryPlacementFees[settlementId].push(item);            
                }
                
              }
    
              if (amountDescription == "Shipping label purchase" || 
              amountDescription == "Shipping label purchase for return") {

            if (shippingLabelPurchases[settlementId] == null) {
              shippingLabelPurchases[settlementId] = [];
            }   
            shippingLabelPurchases[settlementId].push(item);            
          }


              if (amountDescription == "Storage Fee" || amountDescription == "FBAInboundTransportationFee" || amountDescription == "RemovalComplete") {
                if (inventoryFBAFees[settlementId] == null) {
                  inventoryFBAFees[settlementId] = [];
                }   
                inventoryFBAFees[settlementId].push(item);            
              }                
            }
            
      

            // if (amountDescription == "COMPENSATED_CLAWBACK" || 
            //     amountDescription == "COMPENSATED_CLAWBACK") {
            //       currentExpenses[settlementId].push(item) 
            // }

// x-- COMPENSATED_CLAWBACK: -232.54000000000002
// x-- "Inventory Placement Service Fee": -1070.0000000000002
// x-- "Shipping label purchase": -1571.129999999998
// x-- "Shipping label purchase for return": -3.42
// -- "Subscription Fee": -239.94000000000003
// x-- WAREHOUSE_DAMAGE: 50.86
// x-- ​WAREHOUSE_LOST: 185.12
// x-- RemovalComplete: -14.200000000000001
// x-- "Storage Fee": -738.42
// x-- "Current Reserve Amount": -53
// x-- FBAInboundTransportationFee: -1206.4799999999998
// x-- FREE_REPLACEMENT_REFUND_ITEMS: 12
// x-- REVERSAL_REIMBURSEMENT: 929.5300000000001


          }

        }

  
        let settlementKeys = Object.keys(currentExpenses);
        let otherSettlementKeys = Object.keys(shippingLabelPurchases);

        for (let i = 0; i < otherSettlementKeys.length; i++) {
            let current = otherSettlementKeys[i];
            let found = false;
          for (let x = 0; x < settlementKeys.length; x++) {
            let currentCheck = settlementKeys[x];
              if (current == currentCheck) {
                found = true;
              }
          }
          if (!found) {
            settlementKeys.push(current);
            if (currentExpenses[current] == null) {
              currentExpenses[current] = [];
            }
          }
        }

        console.log("settlementKeys:", settlementKeys);

        for (let i = 0; i < settlementKeys.length; i++) {
  
           let key = settlementKeys[i];              
           let fbaFees = inventoryFBAFees[key];
           let placementFees = inventoryPlacementFees[key];
           let labelFees = shippingLabelPurchases[key];
             
          let fbaFeesTotal = 0;
          if (fbaFees) {
            for (let x = 0; x < fbaFees.length; x++) {
              fbaFeesTotal += parseFloat(fbaFees[x].amount)
            }  
          }
           
           let placementFeesTotal = 0;

           if (placementFees) {
            for (let x = 0; x < placementFees.length; x++) {
              placementFeesTotal += parseFloat(placementFees[x].amount);
             }  
           }
  
           let labelPurchasesTotal = 0;
           if (labelFees) {
            for (let x = 0; x < labelFees.length; x++) {
              labelPurchasesTotal += parseFloat(labelFees[x].amount);
            }
           }
  

           let settlementPostedDateTime = "";

           if (settlementPostDateTime[key]) {
            settlementPostedDateTime = settlementPostDateTime[key][0];
           } else {
            settlementPostedDateTime = currentExpenses[key][0] ?  currentExpenses[key][0].postedDateTime : "";
           }
           
           currentExpenses[key].push({
            amountDescription:"Inventory FBA Fees",
            sku:"Amazon Inventory FBA Fee",
            amount:fbaFeesTotal,
            amountDescription:"Amazon Inventory FBA Fee",
            postedDateTime:settlementPostedDateTime
           });
  
           currentExpenses[key].push({
            amountDescription:"Inventory Placement Fees",
            sku:"Amazon Inventory Placement Fee",
            amount:placementFeesTotal,
            amountDescription:"Amazon Inventory Placement Fee",
            type:"Amazon Inventory Placement Fee",

            postedDateTime:settlementPostedDateTime
           });
  
           currentExpenses[key].push({
            amountDescription:"Label Purchases",
            sku:"Amazon Shipping Label Purchase",
            amount:labelPurchasesTotal,
            amountDescription:"Amazon Shipping Label Purchase",
            postedDateTime:settlementPostedDateTime
           });
             
           }        
      }

        await CheckInvoiceExists(currentInvoices, sendAlertMessage);

        setInvoices(currentInvoices);
        setExpenses(currentExpenses);
        setRefunds(currentRefunds);

      }
      
      const displayFetchedData = ()=> {

            return (<TableObject data={fetchedData} type={"Settlement"}/>)
      }


      const buildReportData = (between, reports) => {

        let result = CalculateSettlementValues(reports);
        result.between = between;
        setReportData(result);
        
      }
    
    function displaySettlementReports() {
    
        
        {
          return  settlementReports && settlementReports.length > 0 ? settlementReports.map(report=>{

                return(<>
                        {
                        <Card style={cardStyle} className="standard-card">

                        <CardHeader title={"Settlement Report Selector"} />
                              
                                <CardContent>
                                <p>{report["createdAt"]}</p>
                                <p>{report["status"]}</p>
                                <p>Start:{report["settlementStartDate"]}</p>
                                <p>End:{report["settlementEndDate"]}</p>
                                <p>Total:{report["totalAmount"]}</p>
                                <p>Updated:{report["updatedAt"]}</p>           

                                <Button onClick={()=>{
                                        handleOpen();
                                        setOpenedReport(report["id"]);
                                        fetchSettlementReport(report);
                                }}>
                                View Report
                                </Button>


                                </CardContent>
                        </Card>
                        }</>)

            }) :<></>
        }
    }

    // setFetchedData(reports);
    // buildInvoices(reports);
    // buildReportData(between, reports);

    const createCsvData = async (settlementData) => {


      // console.log("settlements:", settlements);
      // "Purchase Description":"PURCHASE DESCRIPTION",
      let productSkus = settlementData["skus"];


      let items = await batchGetItems(productSkus);

      let csvDataObj = []

      for (let i = 0; i < items.length; i++) {
        let item = items[i];
        
        csvDataObj.push({
          "Name":item.id,
          "SKU":item.asin,
          "Sales Description":item.name == 0 ? "" : item.name.replace(/"/g, '""'),
          "Type": "Inventory",  
          "Income Account":"Sales of Product Income",
          "Expense Account":"Cost of Goods Sold",
          "Inventory Asset Account":"Inventory Asset",          
          "Quantity":0,
          "Taxable":"Yes",
          "Quantity as-of Date":"01/01/2020"
      });
      }
    
      setCsvData(csvDataObj)
    
      let productUnitKeys = Object.keys(settlementData["productUnits"]);
      let csvUnitsSold = [];

      for (let i = 0; i < productUnitKeys.length; i++) {
        let key = productUnitKeys[i];
        csvUnitsSold.push({"ID" : key, "Units" : settlementData["productUnits"][key]})
      }
      
      setCsvUnitsSoldData(csvUnitsSold);

    }

    const displaySettlementReportSelector = ()=>{
            
      
      return <>

      <MuiPickersUtilsProvider utils={MomentUTCUtils}>
      
      <DatePicker value={selectedDate} onChange={handleDateChange} />
      <TimePicker value={selectedDate} onChange={handleDateChange} />
      
      {/* <DateTimePicker value={selectedDate} onChange={handleDateChange} /> */}

    </MuiPickersUtilsProvider>

    <MuiPickersUtilsProvider utils={MomentUTCUtils}>
{/*       
      <DatePicker value={selectedDate} onChange={handleDateChange} />
      <TimePicker value={selectedDate} onChange={handleDateChange} /> */}
      
      <DatePicker value={selectedEndDate} onChange={handleEndDateChange} />
      <TimePicker value={selectedEndDate} onChange={handleEndDateChange} />

      {/* <DateTimePicker value={selectedDate} onChange={handleDateChange} /> */}

    </MuiPickersUtilsProvider>

    <Button onClick={async ()=> {

      
      if (selectedDate && selectedEndDate) {  
        
        saveToLocalStorage("selectedDate", selectedDate.toISOString());
        saveToLocalStorage("selectedEndDate", selectedEndDate.toISOString());

        setFetching(true);
        handleOpen();

        let between = [selectedDate.toISOString(), selectedEndDate.toISOString()];
        let settlements = await fetchSettlementsBetween(between);

        setFetchedData(settlements);
        await buildInvoices(settlements);
        buildReportData(between, settlements);
        setFetching(false);

        setSettlementsSelected(settlements)
        // createCsvData(CalculateSettlementValues(settlements));
        
      }

    }}    
    >
      View Transactions
    </Button>  

    
     { csvData ?  <CSVLink data={csvData} enclosingCharacter={'"'}>Download me</CSVLink> :<></>}


     { csvUnitsSoldData ?  <CSVLink data={csvUnitsSoldData} enclosingCharacter={'"'}>Download Sales</CSVLink> :<></>}
  </>

      
  //    return <>
     
  //    <DateRangePicker
  //     onDatesChange={({startDate, endDate}) => {
                        
  //       // { startDateEntry, endDateEntry }
  //       // console.log("EVT:", evt);
  //       console.log("startDateEntry:", startDate, "endDateEntry:", endDate)
  //     // if (startDate == null || endDate == null)
      
  //     // return;

  //     // let updatedMoment = moment(startDate);
  //     // let startDateTime = updatedMoment.valueOf();
  //     // let endDateTime = moment(endDate).valueOf();

  //     // this.customStartDateTime = startDateTime;
  //     // this.customEndDateTime   = endDateTime;
  //     // this.customUpdatedMoment = updatedMoment;

  //     // this.setState({ date: startDate, endDate: endDate })
        
  //     setStartDateValue(startDate);

  //       setEndDateValue(endDate);


  //     }

  //     }
  //     onFocusChange={(fi) => {
  //     console.log("focused", fi);
  //     // this.setState({ focusedInput: focusedInput })
  //       setFocusedInput(fi);
  //     }
  //     }
  //     isOutsideRange={() => false}
  //     focusedInput={focusedInput}
  //     startDate={startDateValue}
  //     endDate={endDateValue}
  //     startDateId="date_range_picker_start"
  //     endDateId="date_range_picker_end"
  // />
  //   <Button onClick={async ()=> {

  //     console.log("StartDate", startDateValue);
  //     console.log("EndDate", endDateValue);

  //     if (startDateValue && endDateValue) {        
  //       let settlements = await fetchSettlementsBetween([startDateValue.toISOString(), endDateValue.toISOString()]);
  //       setSettlementsSelected(settlements)
  //       calculateSettlementValues(settlements)
  //     }

  //   }}
    
  //   >
  //     View Transactions
  //   </Button>
  // </>


    }


    return (
      <>
    {/* //<Styles> */}

        {
            displaySettlementReportSelector()
        }
  
    <Dialog 

      classes={{
          paperWidthSm: classes.paperWidthSm, // class name, e.g. `classes-nesting-label-x`
        }}

onClose={()=>{
                handleClose();
              }} aria-labelledby="simple-dialog-title" open={open}>
                <DialogContent style={{width:"100%", height:"100%"}}>

<div style={{minWidth:"490px", minHeight:"420px"}}>

              {
                DisplayReportData(reportData)
              }  
              
              <Button onClick={()=>{
                  setViewAsInvoice(!viewAsInvoice)
              }}>
              View Invoices
              </Button>

              <Button onClick={()=>{
                  setViewAsExpense(!viewAsExpense)
              }}>
              View Expenses
              </Button>

              <Button onClick={()=>{
                  setViewAsRefund(!viewAsRefund)
              }}>
              View Refunds
              </Button>



        <div style={{marginLeft:"auto", marginRight:"auto", textAlign:"center"}}>            
            {
                fetchedData ?                 
                
                viewAsInvoice ? 
                <>

            <Button onClick={async ()=> {


                if (!creatingInvoices) {
                  setCreatingInvoices(true);
                  await createInvoices(invoices);
                  setCreatingInvoices(false);
                }
              }}>

              Create New Invoices
              </Button>

                {
                                  
                DisplayFetchedDataAsInvoice(invoices) 
                
              
                }
                </>
                
                :                
                
                viewAsExpense ? <>
                {
                  Object.keys(expenses).length + " Expenses"
                }
                {
                  DisplayFetchedDataAsExpense(expenses)
                }
                </>:<>
                {
                viewAsRefund ? <>

              <Button onClick={async ()=> {


              if (!creatingRefunds) {
                setCreatingRefunds(true);
                await createRefunds(refunds);
                setCreatingRefunds(false);
              }
              }}>

              Create New Refunds
              </Button>


                {
                  DisplayFetchedDataAsRefund(refunds)
                }

                </> :<>{displayFetchedData()} </>

                }

                  

                </>
                                
                : <CircularProgress/>
            }            
        </div>

        
        
</div>
    
                </DialogContent>
              </Dialog>

</>
    // </Styles>
)


}

export default AmazonSettlementSelector
