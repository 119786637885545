import React from 'react';
import styled from 'styled-components';
import AuthorizeUI from './AuthorizeUI';


const Styles = styled.div`

`


function Authorize(props) {

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [finishedInitialSync, updateFinishedInitialSync] = React.useState();

    function displayDashboard() {
        return (<>
        
        Dashboard
        
        </>);
    }

    function updateInitialSync(status) {
        updateFinishedInitialSync(status);
    }

    return (<Styles>
        <div>
            {
             !finishedInitialSync ?
            //   <Onboading updateInitialSyncFunc={updateInitialSync} toastError={props.toastError} toastSuccess={props.toastSuccess} userProps={props.userProps} /> 
                <AuthorizeUI />: 
             displayDashboard()
            }           
        </div>
    </Styles>)

}

export default Authorize