

import React, { useEffect, useContext, useState } from 'react';
import styled from 'styled-components'
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { CircularProgress } from '@material-ui/core';
import Typography from "@material-ui/core/Typography";
import { API } from 'aws-amplify';
import CsvParser from "../yayfun/CsvParser";
import { graphqlOperation } from "aws-amplify";
import { createUserItem, updateUserItem } from "../../graphql/mutations";
import moment from 'moment';
import UserContext from "../../context/user";
import { uniqueId } from 'lodash';
import { getUserItem } from '../../graphql/queries';
import Modal from '@material-ui/core/Modal';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TableObject from '../yayfun/TableObject';
import { makeStyles } from '@material-ui/core/styles';
import { CheckInvoiceExists, ConvertCentsToDollarCents, ConvertStringToDollarsCents, CalculateSettlementValues, DisplayFetchedDataAsInvoice, GetGroupsOfItems} from '../Helper';
import { CSVLink, CSVDownload } from "react-csv";
import QuickbooksLink from "./QuickbooksLink";
import UiContext from '../../context/ui';

// Contains list of all settlements uploaded
// Upload csv file input box
// Date range selector


const useStyles = makeStyles({
  root: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: 48,
    padding: '0 30px',
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  },
  label: {
    textTransform: 'capitalize',
  },
  paperWidthSm: {
    maxWidth: "100%",
    marginTop: "10em"
  }
});


const Styles = styled.div`
    padding:40px;
`

let connectionStatus = {};
let dataColumnNameIndexes = {};
let uniqueids = {};

function AmazonSettlementList(props) {

  console.log("AmazonSettlementList:", props);
  const [, updateState] = useState();
  const { sendAlertMessage } = useContext(UiContext);

  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [loading, updateLoading] = useState(false);
  const [viewAsInvoice, setViewAsInvoice] = useState(false);
  const [invoices, setInvoices] = useState({});
  const [csvData, setCsvData] = useState(false);

  const { signedIn, user, settlementReports, fetchSettlementsBetween, batchGetItems } = useContext(UserContext)
  const [openedReport, setOpenedReport] = useState(null);
  const [reportData, setReportData] = useState(null);

  const [open, setOpen] = React.useState(false);
  const [fetching, setFetching] = React.useState(false);
  const [fetchedData, setFetchedData] = React.useState(false);
  const classes = useStyles();
  const batchDeleteItems = props.batchDeleteItems;

  // console.log("AmazonSettlementList props", props);

  var cardStyle = {
    transitionDuration: '0.3s',
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const createMissingProducts = async (productSkus) => {
    console.log("createMissingProducts:", productSkus);
    // checkForMissingProducts(productSkus);
  }

  const fetchSettlementReport = async (report) => {
    if (!fetching) {
      setFetching(true);
      console.log("reportId:", report["id"], " start:", report["settlementStartDate"], " end:", report["settlementEndDate"])
      let between = [report["settlementStartDate"], report["settlementEndDate"]];
      let reports = await fetchSettlementsBetween(between);
         console.log("fetchSettlementReport BETWEEN:", reports);
      setFetchedData(reports);
      await buildInvoices(reports);
      buildReportData(between, reports);
      setFetching(false);
    }
  }

  const createCsvProducts = async (productSkus) => {

    console.log("createCsvProducts:", productSkus);

    let items = await batchGetItems(productSkus);
    // let results = await createQuickbookItems(items);

    setCsvData(items);


  }


  const buildReportData = (between, reports) => {

    let result = CalculateSettlementValues(reports);
    result.between = between;
    setReportData(result);
    console.log("Report Data:", result);
  }

  
  const displayReportData = () => {

    console.log("displayReportData:", reportData);

    if (reportData == null || reportData == []) {
      return <>
      No report data
      </>
    }

    return (<>
      <div>

        <p className="stlmnt-period">Settlement Period {moment(reportData.between[0]).format("MM/DD/YYYY HH:mm") + " - " + moment(reportData.between[1]).format("MM/DD/YYYY")}</p>


        {/* console.log("Unique Products:", uniqueProducts);
    console.log("Total Sales Count:", totalSalesCount)
    console.log("-------");
    console.log("Total Sales:", totalSales);
    console.log("Total Shipping:", totalShipping);
    console.log("totalOtherSales:",totalOtherSales);
    console.log("totalRefundExpenses:",totalRefundExpenses);
    console.log("-------");
    console.log("totalRefundSales:",totalRefundSales);
    console.log("totalPromotionRebates:", totalPromotionRebates);
    console.log("Total FBA Fees:", totalFBAFees)
    console.log("Total Amazon Fees:", totalAmazonFees) */}

        <div className="stlmnt-block">
          <div className="stlmnt-block-top">Orders</div>
          <div className="stlmnt-block-bottom">{reportData["totalSalesCount"]}</div>
        </div>

        <div className="stlmnt-block">
          <div className="stlmnt-block-top">Products </div>
          <div className="stlmnt-block-bottom">{reportData["products"]}</div>
          {
            csvData ? <CSVLink data={csvData}>Download me</CSVLink> : <></>
          }
        </div>

        <div className="stlmnt-block">
          <div className="stlmnt-block-top">Sales </div>
          <div className="stlmnt-block-bottom">{ConvertStringToDollarsCents(reportData["totalSales"])}</div>
        </div>

        <div className="stlmnt-block">
          <div className="stlmnt-block-top">Shipping </div>
          <div className="stlmnt-block-bottom">{ConvertStringToDollarsCents(reportData["totalShipping"])}</div>
        </div>

        <div className="stlmnt-block">
          <div className="stlmnt-block-top">Other Sales </div>
          <div className="stlmnt-block-bottom">{ConvertStringToDollarsCents(reportData["totalOtherSales"])}</div>
        </div>

        <div className="stlmnt-block">
          <div className="stlmnt-block-top">Refund Expenses </div>
          <div className="stlmnt-block-bottom">{ConvertStringToDollarsCents(reportData["totalRefundExpenses"])}</div>
        </div>

        <div className="stlmnt-block">
          <div className="stlmnt-block-top">Refund Sales </div>
          <div className="stlmnt-block-bottom">{ConvertStringToDollarsCents(reportData["totalRefundSales"])}</div>
        </div>

        <div className="stlmnt-block">
          <div className="stlmnt-block-top">Promo Shipping </div>
          <div className="stlmnt-block-bottom">{ConvertStringToDollarsCents(reportData["totalPromotionShipping"])}</div>
        </div>

        <div className="stlmnt-block">
          <div className="stlmnt-block-top">FBA Fee </div>
          <div className="stlmnt-block-bottom">{ConvertStringToDollarsCents(reportData["totalFBAFees"])}</div>
        </div>

        <div className="stlmnt-block">
          <div className="stlmnt-block-top">Amazon Fees </div>
          <div className="stlmnt-block-bottom">{ConvertStringToDollarsCents(reportData["totalAmazonFees"])}</div>
        </div>

        {/* 
          <Button onClick={()=>{createMissingProducts(reportData["skus"])}}>
              Create Missing Products
            </Button> */}

        <Button onClick={() => {
          createCsvProducts(reportData["skus"])
        }}>
          Create CSV
            </Button>

        <Button onClick={() => {
          setViewAsInvoice(!viewAsInvoice)
        }}>
          {
            viewAsInvoice ? "View Settlement Line Items" : "View Invoices"
          }
        </Button>

      </div>
    </>
    )
  }





  const buildInvoices = async (report) => {

    console.log("Amazon Settlemetn List buildInvoices:", report);

    let currentInvoices = {};

    if (report) {

      for (let i = 0; i < report.length; i++) {

        let item = report[i];

        let transactionType = item["transactionType"];
        let amountType = item["amountType"];
        let amountDescription = item["amountDescription"];
        let orderId = item["orderId"];
        let settlementId = item["settlementId"]
        
        
        if (transactionType == "Order") {

          if (currentInvoices[orderId] == null) {
            currentInvoices[orderId] = [];
          }

          // if (amountDescription == "Principal" || amountDescription == "Shipping" || amountDescription == "Tax" || amountDescription == "ShippingTax") {
          //   if (amountType == "ItemPrice" || amountType == "Promotion") {
          //     currentInvoices[orderId].push(item)
          //   }
          // }

          // if (amountType == "ItemWithheldTax") {
          //   currentInvoices[orderId].push(item)
          // }

          // if (amountType == "ItemFees") {
          //   currentInvoices[orderId].push(item)
          // }

          currentInvoices[orderId].push(item)

        }

        if (transactionType == "other-transaction" && item["amountType"] == "FBA Inventory Reimbursement" && item["amountDescription"] == "FREE_REPLACEMENT_REFUND_ITEMS") {
          console.log("OTHER SALE REFUND REIMBURSEMENT:", item["amount"] );
        //  totalOtherSales += parseFloat(settlement["amount"]);            
        }


        if (transactionType == "Refund") {
          // if (settlement["amountType"] == "ItemPrice" && (settlement["amountDescription"] == "Principal")) {
          //   totalRefundSales += parseFloat(settlement["amount"]);       
          // } else {
          //   totalRefundExpenses += parseFloat(settlement["amount"]);       
          // }
        }


        if (transactionType == "otherTransaction") {


        }



      }

    }


    await CheckInvoiceExists(currentInvoices, sendAlertMessage);

    console.log("currentInvoices:", currentInvoices);

    setInvoices(currentInvoices);

  }

  const displayFetchedData = () => {

    console.log("Display fetched data:", fetchedData);

    return (<TableObject data={fetchedData} type={"Settlement"} />)

  }

  // Expenses
  // Category
  // Cost Of Goods Sold
  // Selling Fees
  // Amazon Referral Fee
  // Closing Fees
  // FBA Transaction Fees
  // FBA Fulfillment Fees
  // Gift Wrap Chargeback
  // Shipping Chargeback
  // FBA Transaction Fee Refunds
  // Gift Wrap Chargeback Refunds
  // Shipping Chargeback Refunds
  // FBA Inventory and Inbound Service Fees
  // 30 Day Storage Fees
  // Removal Order Fees
  // Inbound Shipping Fees
  // Other Expenses
  // Refund Administration Fee
  // MFN Shipping Label Cost
  // Selling Fee Refunds
  // Closing Fee Refunds
  // Amazon Pro Subscription
  // Inventory Placement Service Fees

  // Other
  // Category
  // Sales Tax Collected
  // Marketplace Facilitator Tax
  // Sales Tax Refunds
  // Marketplace Facilitator Tax Refunds
  // Previous Reserve Amount
  // Current Reserve Amount
  // Total


  function displaySettlementReports() {

    // console.log("::settlementReports::", settlementReports);
    {
      return settlementReports && settlementReports.length > 0 ? settlementReports.map(report => {

        return (<>
          {
            <Card style={cardStyle} className="settlement-report-card">
              <CardContent>
                <div className="settlement-report-card-left">
                  <p>Id:{report["id"]}</p>
                  {/* <p>{report["createdAt"]}</p> */}
                  <p>{moment(report["settlementStartDate"]).format("MM/DD/YYYY")} - {moment(report["settlementEndDate"]).format("MM/DD/YYYY")}</p>
                  <p>Total:{report["totalAmount"]}</p>
                  
                  {/* <p>Updated:{report["updatedAt"]}</p> */}
                </div>
                <div className="settlement-report-card-right">

                  <div>
                    <Button onClick={() => {
                      handleOpen();
                      setOpenedReport(report["id"]);
                      fetchSettlementReport(report);
                    }}>
                      View
                                </Button>

                    <Button onClick={ async () => {
                      if (window.confirm("Are you sure you want to delete this?")) {
                        
                        console.log("reportId:", report["id"], " start:", report["settlementStartDate"], " end:", report["settlementEndDate"])
                        let between = [report["settlementStartDate"], report["settlementEndDate"]];
                        let reports = await fetchSettlementsBetween(between);
                        
                        console.log("fetchSettlementReport BETWEEN:", reports);

                        batchDeleteItems(reports);
                      }
                      
                    }}>
                      Delete
                                </Button>
                    {/* <span>{report["status"]}</span> */}

                  </div>

                </div>


              </CardContent>
            </Card>
          }</>)

      }) : <></>
    }
  }



  return (
    <Styles>
      {
        displaySettlementReports()
      }
      {
        settlementReports ? settlementReports.length : <></>
      }

      <Dialog

        classes={{
          paperWidthSm: classes.paperWidthSm, // class name, e.g. `classes-nesting-label-x`
        }}

        onClose={() => {
          handleClose();
        }} aria-labelledby="simple-dialog-title" open={open}>

        <DialogContent style={{ width: "100%", height: "100%" }}>
          <div style={{ minWidth: "490px", minHeight: "420px" }}>
            {
              displayReportData()
            }
            <div style={{ marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>
              {
                fetchedData ? viewAsInvoice ? DisplayFetchedDataAsInvoice(invoices) : displayFetchedData() : <CircularProgress />
              }
            </div>
          </div>

        </DialogContent>
      </Dialog>

    </Styles>
  )


}

export default AmazonSettlementList
