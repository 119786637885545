import React, { useState, useEffect, useRef } from "react";


const UiContext = React.createContext({});

const UiProvider = ({ children }) => {  
  
  const[alertMessage, setAlertMessage] = useState("");  
  const isLoading = useRef(false);  
  const alertMessageQueue = useRef([]);
  
  // useEffect(() => {

  // }, []);

  const contiueAlertMessageQueueCheck = async ()=> {
    if (alertMessageQueue && alertMessageQueue.current.length > 0) {      
      let message = alertMessageQueue.current[0];
     if (message) {
        setAlertMessage(message);      
     } else {
        setAlertMessage("");
     }       
    } else {
      setAlertMessage("");
    }
  }

  const sendAlertMessage = async (message) => {
    
    try {     
        alertMessageQueue.current = [];
        alertMessageQueue.current.push(message);
        contiueAlertMessageQueueCheck();
    } catch (err) {      
      // console.log(err);   
    }
  };

  const closeAlertMessage = async ()=> {
    alertMessageQueue.current.shift();
    contiueAlertMessageQueueCheck();
  }

  const clearMessages = async ()=> {
    alertMessageQueue.current = [];
    contiueAlertMessageQueueCheck();
  }

  const totalMessages = async ()=> {
      return alertMessageQueue.current.length;
  }

  const checkIsLoading = ()=>{
    return isLoading.current;
  }

  const setIsLoading = (value)=>{
    isLoading.current = value;
  }

return (
    <UiContext.Provider
      value={{sendAlertMessage, closeAlertMessage, clearMessages, totalMessages, alertMessage, checkIsLoading, setIsLoading}}>
      {children}
    </UiContext.Provider>
  );
};

export default UiContext;
export {UiProvider};