import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import QuickbooksLink from "./connections/QuickbooksLink";
import moment from 'moment';
import { API } from 'aws-amplify';
import { graphqlOperation } from "aws-amplify";
import {itemsByOwnerId} from "../graphql/queries";
import * as mutations from "../graphql/mutations";

const MASTER_TIME = "1970-01-01T00:00:00.000Z";
/**
* Returns a random number between min (inclusive) and max (exclusive)
*/
export function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}

/**
* Returns a random integer between min (inclusive) and max (inclusive).
* The value is no lower than min (or the next integer greater than min
* if min isn't an integer) and no greater than max (or the next integer
* lower than max if max isn't an integer).
* Using Math.round() will give you a non-uniform distribution!
*/
export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}


export function formatNumberLabel(label) {
  return label.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

  const delay = ms => new Promise(res => setTimeout(res, ms));


export function ConvertStringToDollarsCents(stringAmount) {
  if (stringAmount === "--")
    return stringAmount;
  return parseFloat(stringAmount).toLocaleString("en-US", { style: "currency", currency: "USD" })
}


export function GetShortConditionName(condition) {
  if (condition === "New" || condition === "new") {
    return "N";
  }
  if (condition === "Used" || condition === "used") {
    return "U";
  }
  return condition;
}


export function GetRelativeTime(date) {
  const d = new Date(date);
  let fromNowString = moment(d).fromNow();

  if (fromNowString.includes("years ago")) {
    fromNowString = "N/A";
  }
  return fromNowString;
}

export function ConvertCentsToDollarCents(cents) {
  if (cents) {
    var num = parseInt(cents);
    var dollars = num / 100;
    return dollars.toLocaleString("en-US", { style: "currency", currency: "USD" });
  }
}

export function CapitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function isMobile() {
  if (window.innerWidth <= 1100) {
    return true;
  }
  return false;
}


export function ConvertDimensionUnits(string) {
  if (string === "inches") {
    return "\"";
  }
  if (string === "pounds") {
    return "lbs";
  }
  return string;
}


export const CheckDisabled = (product) => {
  if (product && product.quantity <= 0 && !product.allowPreorder)
    return true;
  if (product && moment().valueOf() < moment(product.releaseDate).valueOf()) {
    if (product.allowPreorder) {
      return false;
    } else {
      return true;
    }
  }
  return false;
}

export const GetGroupsOfItems = (inputArray, batchLimit) => {
  let idGroups = new Array();
  idGroups.push(new Array());
  if (inputArray == undefined)
    return idGroups;
  let currentCount = 0;
  let currentArray = 0;
  for (let i = 0; i < inputArray.length; i++) {
    if (currentCount >= batchLimit) {
      idGroups.push(new Array());
      currentCount = 0;
      currentArray = currentArray + 1
    }
    let item = inputArray[i];
    idGroups[currentArray].push(item);
    currentCount = currentCount + 1;
  }

  return idGroups;
}



export const DisplayReportData = (reportData) => {


  if (reportData == null) {
    return <>
      Loading report data
    </>
  }

  return (<>
    <div>

      <p className="stlmnt-period">Settlement Period {moment(reportData.between[0]).format("MM/DD/YYYY HH:mm A") + " - " + moment(reportData.between[1]).format("MM/DD/YYYY hh:mm A")}</p>

      <div className="stlmnt-block">
        <div className="stlmnt-block-top">Orders</div>
        <div className="stlmnt-block-bottom">{reportData["totalSalesCount"]}</div>
      </div>

      <div className="stlmnt-block">
        <div className="stlmnt-block-top">Products </div>
        <div className="stlmnt-block-bottom">{reportData["products"]}</div>
        {
          // csvData ? <CSVLink data={csvData}>Download me</CSVLink> : <></>
        }
      </div>

      <div className="stlmnt-block">
        <div className="stlmnt-block-top">Product Sales</div>
        <div className="stlmnt-block-bottom">{ConvertStringToDollarsCents(reportData["totalSales"])}</div>
      </div>


      <div className="stlmnt-block">
        <div className="stlmnt-block-top">Refund Sales </div>
        <div className="stlmnt-block-bottom">{ConvertStringToDollarsCents(reportData["totalRefundSales"])}</div>
      </div>

      <div className="stlmnt-block">
        <div className="stlmnt-block-top">Shipping Credits</div>
        <div className="stlmnt-block-bottom">{ConvertStringToDollarsCents(reportData["totalShipping"])}</div>
      </div>

      <div className="stlmnt-block">
        <div className="stlmnt-block-top">Refund Shipping Credits</div>
        <div className="stlmnt-block-bottom">{ConvertStringToDollarsCents(reportData["totalShippingRefund"])}</div>
      </div>


      <div className="stlmnt-block">
        <div className="stlmnt-block-top">Gift Wrap Credits </div>
        <div className="stlmnt-block-bottom">{ConvertStringToDollarsCents(reportData["totalGiftwrap"])}</div>
      </div>


      <div className="stlmnt-block">
        <div className="stlmnt-block-top">Refund Gift Wrap</div>
        <div className="stlmnt-block-bottom">{ConvertStringToDollarsCents(reportData["totalGiftwrapRefund"])}</div>
      </div>

      <div className="stlmnt-block">
        <div className="stlmnt-block-top">Promo Rebates (Shipping)</div>
        <div className="stlmnt-block-bottom">{ConvertStringToDollarsCents(reportData["totalPromotionShipping"])}</div>
      </div>

      <div className="stlmnt-block">
        <div className="stlmnt-block-top">Refund Promo Rebates</div>
        <div className="stlmnt-block-bottom">{ConvertStringToDollarsCents(reportData["totalPromotionShippingRefund"])}</div>
      </div>


      <div className="stlmnt-block">
        <div className="stlmnt-block-top">Sales Tax Collected </div>
        <div className="stlmnt-block-bottom">{ConvertStringToDollarsCents(reportData["totalTax"])}</div>
      </div>

      <div className="stlmnt-block">
        <div className="stlmnt-block-top">Inventory Credit</div>
        <div className="stlmnt-block-bottom">{ConvertStringToDollarsCents(reportData["totalInventoryCredit"])}</div>
      </div>

      <div className="stlmnt-block">
        <div className="stlmnt-block-top">Income Credit</div>
        <div className="stlmnt-block-bottom">{ConvertStringToDollarsCents(reportData["totalIncomeCredit"])}</div>
        {
          reportData["creditsArray"].map(item => {
            return (<p>{ConvertStringToDollarsCents(item)}</p>)
          })
        }
      </div>

      <div className="stlmnt-block">
        <div className="stlmnt-block-top">Income Debit</div>
        <div className="stlmnt-block-bottom">{ConvertStringToDollarsCents(reportData["totalIncomeDebit"])}</div>

        <div className="stlmnt-block-bottom">Refund AFN: {ConvertStringToDollarsCents(reportData["totalRefundSalesAFN"])}</div>
        <div className="stlmnt-block-bottom">Refund MFN: {ConvertStringToDollarsCents(reportData["totalRefundSalesMFN"])}</div>

        {
          reportData["debitsArray"].map(item => {
            return (<p>{ConvertStringToDollarsCents(item)}</p>)
          })
        }

      </div>

      {/* <div className="stlmnt-block">
        <div className="stlmnt-block-top">Other Sales </div>
        <div className="stlmnt-block-bottom">{ConvertStringToDollarsCents(reportData["totalOtherSales"])}</div>
      </div> */}

      {/* <div className="stlmnt-block">
        <div className="stlmnt-block-top">Refund Expenses </div>
        <div className="stlmnt-block-bottom">{ConvertStringToDollarsCents(reportData["totalRefundExpenses"])}</div>
      </div> */}

      <div className="stlmnt-block">
        <div className="stlmnt-block-top">Expense Debit</div>
        <div className="stlmnt-block-bottom">Amazon Commission {ConvertStringToDollarsCents(reportData["totalCommission"])}</div>
        <div className="stlmnt-block-bottom">MFN Commission: {ConvertStringToDollarsCents(reportData["totalCommissionMFN"])}</div>
        <div className="stlmnt-block-bottom">AFN Commission: {ConvertStringToDollarsCents(reportData["totalCommissionAFN"])}</div>
      </div>


      <div className="stlmnt-block">
        <div className="stlmnt-block-top">Refund Commission </div>
        <div className="stlmnt-block-bottom">{ConvertStringToDollarsCents(reportData["totalRefundCommission"])}</div>
      </div>



      <div className="stlmnt-block">
        <div className="stlmnt-block-top">FBA Transaction Fees </div>
        <div className="stlmnt-block-bottom">{ConvertStringToDollarsCents(reportData["totalFBAFees"])}</div>
      </div>


      <div className="stlmnt-block">
        <div className="stlmnt-block-top">FBA Transaction Fee Refunds </div>
        <div className="stlmnt-block-bottom">{ConvertStringToDollarsCents(reportData["totalFBAFeesRefunds"])}</div>
      </div>




      {/* <div className="stlmnt-block">
        <div className="stlmnt-block-top">Expense Credit</div>
        <div className="stlmnt-block-bottom">{ConvertStringToDollarsCents(reportData["totalRefundCommission"])}</div>

      </div> */}





      {/* 
      <div className="stlmnt-block">
        <div className="stlmnt-block-top">Amazon Fees </div>
        <div className="stlmnt-block-bottom">{ConvertStringToDollarsCents(reportData["totalAmazonFees"])}</div>
      </div> */}

      <div className="stlmnt-block">
        <div className="stlmnt-block-top">FBA Inventory Fees </div>
        <div className="stlmnt-block-bottom">{ConvertStringToDollarsCents(reportData["totalFBAInventoryFee"])}</div>
      </div>


      <div className="stlmnt-block">
        <div className="stlmnt-block-top">Shipping Label </div>
        <div className="stlmnt-block-bottom">{ConvertStringToDollarsCents(reportData["totalShippingLabelPurchase"])}</div>
      </div>


      <div className="stlmnt-block">
        <div className="stlmnt-block-top">Service Fee </div>
        <div className="stlmnt-block-bottom">{ConvertStringToDollarsCents(reportData["totalServiceFee"])}</div>
      </div>

      <div className="stlmnt-block">
        <div className="stlmnt-block-top">Refund Commission Fee</div>
        <div className="stlmnt-block-bottom">{ConvertStringToDollarsCents(reportData["totalRefundCommissionFee"])}</div>
      </div>

      <div className="stlmnt-block">
        <div className="stlmnt-block-top">Adjustments</div>
        <div className="stlmnt-block-bottom">{ConvertStringToDollarsCents(reportData["totalAdjustments"])}</div>
      </div>

      {/* <div className="stlmnt-block">
        <div className="stlmnt-block-top">Reversal Reimbursement</div>
        <div className="stlmnt-block-bottom">{ConvertStringToDollarsCents(reportData["totalReversalReimbursement"])}</div>
      </div>
             */}





      {/* 
        <Button onClick={()=>{createMissingProducts(reportData["skus"])}}>
            Create Missing Products
          </Button> */}

      {/* <Button onClick={() => {
        createCsvProducts(reportData["skus"])
      }}>
        Create CSV
          </Button> */}

      {/* <Button onClick={() => {
        setViewAsInvoice(!viewAsInvoice)
      }}>
        {
          viewAsInvoice ? "View Settlement Line Items" : "View Invoices"
        }
      </Button> */}

    </div>
  </>
  )
}

// Order
// Commission: -14507.349999999984
// FBAPerUnitFulfillmentFee: -9782.029999999791
// GiftWrap: 180.12000000000006
// GiftWrapTax: 7.24
// GiftwrapChargeback: -180.12000000000006
// "LowValueGoodsTax-Principal": -4.3
// "LowValueGoodsTax-Shipping": -1.35
// "MarketplaceFacilitatorTax-Other": -7.24
// "MarketplaceFacilitatorTax-Principal": -5985.45999999998
// "MarketplaceFacilitatorTax-Shipping": -25.050000000000008
// Principal: 97815.81000000001
// Shipping: 1447.4499999999991
// ShippingChargeback: -1217.3799999999994
// -- ShippingHB: -34.54
// ShippingTax: 26.40000000000001
// Tax: 5989.759999999983
// VariableClosingFee: -82.79999999999993


// Refund
// -- Commission: 649.0500000000003
// -- GiftWrap: -9.48
// -- GiftWrapTax: -0.43
// -- GiftwrapChargeback: 9.48
// -- "MarketplaceFacilitatorTax-Other": 0.43
// -- "MarketplaceFacilitatorTax-Principal": 304.4599999999999
// -- "MarketplaceFacilitatorTax-RestockingFee": 0
// -- "MarketplaceFacilitatorTax-Shipping": 2.05
// -- Principal: -4326.8799999999965
// -- RefundCommission: -110.55999999999999
// -- RestockingFee: 25
// -- Shipping: -28.319999999999997
// -- ShippingChargeback: 28.319999999999997
// -- ShippingTax: -2.05
// -- Tax: -304.4599999999999
// -- VariableClosingFee: 5.4

// other-transaction
// -- COMPENSATED_CLAWBACK: -232.54000000000002
// -- "Inventory Placement Service Fee": -1070.0000000000002
// -- "Shipping label purchase": -1571.129999999998
// -- "Shipping label purchase for return": -3.42
// -- "Subscription Fee": -239.94000000000003
// -- WAREHOUSE_DAMAGE: 50.86
// -- ​WAREHOUSE_LOST: 185.12
// -- RemovalComplete: -14.200000000000001
// -- "Storage Fee": -738.42
// -- "Current Reserve Amount": -53
// -- FBAInboundTransportationFee: -1206.4799999999998
// -- FREE_REPLACEMENT_REFUND_ITEMS: 12
// -- REVERSAL_REIMBURSEMENT: 929.5300000000001


export const CalculateSettlementValues = (settlements) => {

  if (settlements) {

    let totalSales = 0;
    let totalShipping = 0;
    let totalTax = 0;
    let totalCommission = 0;
    let totalGiftwrap = 0;
    let totalGiftwrapChargeback = 0;

    let totalPromotionShipping = 0;
    let totalShippingChargeback = 0;
    let itemWithheldTaxShipping = 0;
    let itemWithheldTaxPrincipal = 0;
    let itemWithheldTaxOther = 0;

    let totalFBAPerUnitFulfillmentFee = 0;
    let totalInventoryPlacementFee = 0;
    let totalServiceFee = 0;
    let totalSubscriptionFee = 0;
    let totalFBATransportationFee = 0;
    let totalStorageFee = 0;
    let totalRefundExpenses = 0;
    let totalPromotionRebates = 0;
    let totalOtherSales = 0;
    let totalRemovalFee = 0;
    let totalFBAFees = 0;
    let totalFBAFeesRefunds = 0;
    let totalAmazonFees = 0;
    let totalOtherExpenses = 0;
    let totalFBAInventoryFee = 0;
    let totalShippingLabelPurchase = 0;
    let totalReversalReimbursement = 0;
    let totalShippingRefund = 0;
    let totalGiftwrapRefund = 0;
    let totalPromotionShippingRefund = 0;
    let totalIncomeCredit = 0;
    let totalIncomeDebit = 0;
    let totalInventoryCredit = 0;
    let debitsArray = [];
    let creditsArray = [];

    let totalRefundSalesAFN = 0;
    let totalRefundSalesMFN = 0
    let totalCommissionAFN = 0;
    let totalCommissionMFN = 0;

    let totalShippingHBMFN = 0;
    let totalShippingHBAFN = 0;

    let totalAdjustments = 0;

    //Refunds
    let totalRefundSales = 0;
    let totalRefundCommission = 0;
    let totalRefundCommissionFee = 0;
    let totalRefund = 0;
    let products = {};

    let totalSalesCount = 0;
    let total1299Count = 0;

    let amountDescription = {}

    for (let i = 0; i < settlements.length; i++) {

      let settlement = settlements[i];

      if (amountDescription[settlement["transactionType"]] == undefined) {
        amountDescription[settlement["transactionType"]] = {};
      }

      if (amountDescription[settlement["transactionType"]][settlement["amountDescription"]]) {
        amountDescription[settlement["transactionType"]][settlement["amountDescription"]] = amountDescription[settlement["transactionType"]][settlement["amountDescription"]] + parseFloat(settlement["amount"]);
      } else {
        amountDescription[settlement["transactionType"]][settlement["amountDescription"]] = parseFloat(settlement["amount"]);
      }

      // Other transaction
      if (settlement["transactionType"] == "other-transaction") {

        if (settlement["amountType"] == "FBA Inventory Reimbursement" && settlement["amountDescription"] == "FREE_REPLACEMENT_REFUND_ITEMS") {
          totalOtherSales += parseFloat(settlement["amount"]);
          totalInventoryCredit += parseFloat(settlement["amount"]);
          totalIncomeCredit += parseFloat(settlement["amount"]);
        }

        // FBA inventory and inbound services fees
        if (settlement["amountType"] == "other-transaction" && settlement["amountDescription"] == "Storage Fee") {
          totalFBAInventoryFee += parseFloat(settlement["amount"]);
          totalStorageFee += parseFloat(settlement["amount"]);
        }

        if (settlement["amountDescription"] == "FBAInboundTransportationFee") {
          totalFBAInventoryFee += parseFloat(settlement["amount"]);
          totalFBATransportationFee += parseFloat(settlement["amount"]);
        }

        if (settlement["amountDescription"] == "RemovalComplete") {
          totalFBAInventoryFee += parseFloat(settlement["amount"]);
        }

        // //FBA Fees
        // if (settlement["transactionType"] == "other-transaction" && settlement["amountDescription"] == "RemovalComplete") {
        //   // totalFBAInventoryFee += parseFloat(settlement["amount"]);            
        //   totalRemovalFee += parseFloat(settlement["amount"]);            
        //   totalFBAFees += parseFloat(settlement["amount"]);             
        // }

        //Shipping label purchase
        if (settlement["amountType"] == "other-transaction" && settlement["amountDescription"] == "Shipping label purchase") {
          totalShippingLabelPurchase += parseFloat(settlement["amount"]);
        }
        if (settlement["amountType"] == "other-transaction" && settlement["amountDescription"] == "Shipping label purchase for return") {
          totalShippingLabelPurchase += parseFloat(settlement["amount"]);
        }

        // Service Fees
        if (settlement["amountType"] == "other-transaction" && settlement["amountDescription"] == "Inventory Placement Service Fee") {
          // totalFBAInventoryFee += parseFloat(settlement["amount"]);            
          totalInventoryPlacementFee += parseFloat(settlement["amount"]);
          totalServiceFee += parseFloat(settlement["amount"]);
        }

        if (settlement["amountType"] == "other-transaction" && settlement["amountDescription"] == "Subscription Fee") {
          // totalFBAInventoryFee += parseFloat(settlement["amount"]);            
          totalSubscriptionFee += parseFloat(settlement["amount"]);
          totalServiceFee += parseFloat(settlement["amount"]);
        }


        // Adjustments
        if (settlement["amountDescription"] == "COMPENSATED_CLAWBACK") {
          totalAdjustments += parseFloat(settlement["amount"]);
        }

        // FBA Inventory Reimbursement	/ Inventory Credit / REVERSAL_REIMBURSEMENT
        if (settlement["amountDescription"] == "REVERSAL_REIMBURSEMENT") {
          totalInventoryCredit += parseFloat(settlement["amount"]);
          totalIncomeCredit += parseFloat(settlement["amount"]);
        }
        if (settlement["amountDescription"] == "WAREHOUSE_LOST") {
          totalInventoryCredit += parseFloat(settlement["amount"]);
          totalIncomeCredit += parseFloat(settlement["amount"]);
        }
        if (settlement["amountDescription"] == "WAREHOUSE_DAMAGE") {
          totalInventoryCredit += parseFloat(settlement["amount"]);
          totalIncomeCredit += parseFloat(settlement["amount"]);
        }


      }

      //Order

      if (settlement["transactionType"] == "Order") {

        if (settlement["amountType"] == "ItemFees") {

          if (settlement["amountDescription"] == "GiftwrapChargeback") {
            totalGiftwrapChargeback += parseFloat(settlement["amount"]);
            totalAmazonFees += parseFloat(settlement["amount"]);
            totalFBAFees += parseFloat(settlement["amount"]);
          }

          if (settlement["amountDescription"] == "ShippingChargeback") {
            totalShippingChargeback += parseFloat(settlement["amount"]);
            totalAmazonFees += parseFloat(settlement["amount"]);
            totalFBAFees += parseFloat(settlement["amount"]);
          }

          if (settlement["amountDescription"] == "ShippingHB") {
            if (settlement["fulfillmentId"] == "MFN") {
              totalShippingHBMFN += parseFloat(settlement["amount"]);
              totalCommissionMFN += parseFloat(settlement["amount"]);
            } else if (settlement["fulfillmentId"] == "AFN") {
              totalShippingHBAFN += parseFloat(settlement["amount"]);
              totalCommissionAFN += parseFloat(settlement["amount"]);
            }

          }

          if (settlement["amountDescription"] == "VariableClosingFee") {

            totalAmazonFees += parseFloat(settlement["amount"]);
            // totalFBAFees += parseFloat(settlement["amount"]);                       

            totalCommission += parseFloat(settlement["amount"]);
            if (settlement["fulfillmentId"] == "MFN") {
              totalCommissionMFN += parseFloat(settlement["amount"]);

            } else {
              totalCommissionAFN += parseFloat(settlement["amount"]);
            }

          }
        }


        if (settlement["amountType"] == "ItemPrice" && settlement["amountDescription"] == "Principal") {

          let salePrincipal = parseFloat(settlement["amount"]);
          totalSales += salePrincipal;
          totalIncomeCredit += salePrincipal;
          totalSalesCount++;
          let previousQuantity = products[settlement["sku"]];
          if (previousQuantity == undefined) {
            products[settlement["sku"]] = parseInt(settlement["quantityPurchased"]);
          } else {
            products[settlement["sku"]] = parseInt(previousQuantity) + parseInt(settlement["quantityPurchased"]);
          }

        }

        if (settlement["transactionType"] == "Order" && settlement["amountType"] == "ItemPrice" && (settlement["amountDescription"] == "Shipping")) {
          totalShipping += parseFloat(settlement["amount"]);
          totalIncomeCredit += parseFloat(settlement["amount"]);
        }


        if (settlement["transactionType"] == "Order" && settlement["amountType"] == "ItemPrice" && settlement["amountDescription"] == "GiftWrap") {

          totalGiftwrap += parseFloat(settlement["amount"]);
          totalIncomeCredit += parseFloat(settlement["amount"]);
        }

        if (settlement["transactionType"] == "Order" && settlement["amountType"] == "ItemFees" && settlement["amountDescription"] == "Commission") {

          totalCommission += parseFloat(settlement["amount"]);

          if (settlement["fulfillmentId"] == "AFN") {
            totalCommissionAFN += parseFloat(settlement["amount"]);
          } else {
            totalCommissionMFN += parseFloat(settlement["amount"]);
          }

          totalAmazonFees += parseFloat(settlement["amount"]);
        }

        // FBA Fees
        if (settlement["transactionType"] == "Order" && settlement["amountType"] == "ItemFees" && settlement["amountDescription"] == "FBAPerUnitFulfillmentFee") {
          totalFBAPerUnitFulfillmentFee += parseFloat(settlement["amount"]);
          totalAmazonFees += parseFloat(settlement["amount"]);
          totalFBAFees += parseFloat(settlement["amount"]);
        }

        if (settlement["transactionType"] == "Order" && settlement["amountType"] == "ItemPrice" && settlement["amountDescription"] == "Tax") {
          totalTax += parseFloat(settlement["amount"]);
        }

        if (settlement["transactionType"] == "Order" && settlement["amountType"] == "ItemPrice" && settlement["amountDescription"] == "ShippingTax") {
          totalTax += parseFloat(settlement["amount"]);
        }

        if (settlement["transactionType"] == "Order" && settlement["amountType"] == "ItemPrice" && settlement["amountDescription"] == "GiftWrapTax") {
          totalTax += parseFloat(settlement["amount"]);
        }

        // "LowValueGoodsTax-Principal": -4.3
        // "LowValueGoodsTax-Shipping": -1.35
        // "MarketplaceFacilitatorTax-Other": -7.24
        // "MarketplaceFacilitatorTax-Principal": -5985.45999999998
        // "MarketplaceFacilitatorTax-Shipping": -25.050000000000008

        if (settlement["amountType"] == "ItemWithheldTax" && settlement["amountDescription"] == "MarketplaceFacilitatorTax-Shipping") {
          itemWithheldTaxShipping += parseFloat(settlement["amount"]);
        }

        if (settlement["amountType"] == "ItemWithheldTax" && settlement["amountDescription"] == "MarketplaceFacilitatorTax-Principal") {
          itemWithheldTaxPrincipal += parseFloat(settlement["amount"]);
        }

        if (settlement["amountType"] == "ItemWithheldTax" && settlement["amountDescription"] == "MarketplaceFacilitatorTax-Other") {
          itemWithheldTaxOther += parseFloat(settlement["amount"]);
        }


        if (settlement["amountType"] == "ItemFees" && settlement["amountDescription"] == "GiftwrapChargeback") {
          totalGiftwrapChargeback += parseFloat(settlement["amount"]);
        }

        if (settlement["amountType"] == "Promotion" && settlement["amountDescription"] == "Shipping") {
          totalPromotionShipping += parseFloat(settlement["amount"]);
          totalPromotionRebates += parseFloat(settlement["amount"]);
          totalIncomeDebit += parseFloat(settlement["amount"]);
        }

      }



      // if (settlement["amountType"] == "FBA Inventory Reimbursement" && settlement["amountDescription"] == "REVERSAL_REIMBURSEMENT") {        
      //   totalReversalReimbursement += parseFloat(settlement["amount"]); 
      // }



      //Refunds
      if (settlement["transactionType"] == "Refund") {

        if (settlement["amountType"] == "ItemFees" && settlement["amountDescription"] == "Commission") {
          totalRefundCommission += parseFloat(settlement["amount"]);
        }

        if (settlement["amountDescription"] == "VariableClosingFee") {
          totalRefundCommission += parseFloat(settlement["amount"]);
        }


        if (settlement["amountType"] == "ItemFees" && settlement["amountDescription"] == "RefundCommission") {
          totalRefundCommissionFee += parseFloat(settlement["amount"]);
        }

        if (settlement["amountDescription"] == "GiftwrapChargeback") {
          totalFBAFeesRefunds += parseFloat(settlement["amount"]);
        }

        if (settlement["amountDescription"] == "ShippingChargeback") {
          totalFBAFeesRefunds += parseFloat(settlement["amount"]);
        }


        if (settlement["amountType"] == "ItemPrice" && settlement["amountDescription"] == "Shipping") {
          totalShippingRefund += parseFloat(settlement["amount"]);
          totalIncomeDebit += parseFloat(settlement["amount"]);
        }

        if (settlement["amountType"] == "Promotion" && settlement["amountDescription"] == "Shipping") {
          totalPromotionShippingRefund += parseFloat(settlement["amount"]);
          totalIncomeCredit += parseFloat(settlement["amount"]);
        }

        if (settlement["amountType"] == "ItemPrice" && settlement["amountDescription"] == "GiftWrap") {
          totalGiftwrapRefund += parseFloat(settlement["amount"]);
          totalIncomeDebit += parseFloat(settlement["amount"]);
        }
        // RestockingFee
        if (settlement["amountType"] == "ItemPrice" && settlement["amountDescription"] == "RestockingFee") {
          totalRefundSalesAFN += parseFloat(settlement["amount"]);
          totalRefundSales += parseFloat(settlement["amount"]);
          totalIncomeDebit += parseFloat(settlement["amount"]);
        }

        if (settlement["amountType"] == "ItemPrice" && (settlement["amountDescription"] == "Principal")) {

          if (settlement["fulfillmentId"] == "AFN") {
            totalRefundSalesAFN += parseFloat(settlement["amount"]);
          } else {
            totalRefundSalesMFN += parseFloat(settlement["amount"]);
          }
          totalRefundSales += parseFloat(settlement["amount"]);
          totalIncomeDebit += parseFloat(settlement["amount"]);
        }
      }
    }

    let uniqueProducts = Object.keys(products).length;

    debitsArray.push(parseFloat(totalRefundSales));
    debitsArray.push(parseFloat(totalShippingRefund));
    debitsArray.push(parseFloat(totalGiftwrapRefund));
    debitsArray.push(parseFloat(totalPromotionShipping));

    creditsArray.push(parseFloat(totalSales));
    creditsArray.push(parseFloat(totalInventoryCredit));
    creditsArray.push(parseFloat(totalShipping));
    creditsArray.push(parseFloat(totalGiftwrap));
    creditsArray.push(parseFloat(totalPromotionShippingRefund));


    // console.log("Unique Products:", uniqueProducts);
    // console.log("Products:", products);
    // console.log("Total Sales Count:", totalSalesCount)
    // console.log("-------");
    // console.log("Total Sales:", totalSales);
    // console.log("Total Shipping:", totalShipping);
    // console.log("totalOtherSales:", totalOtherSales);
    // console.log("totalRefundExpenses:", totalRefundExpenses);
    // console.log("-------");
    // console.log("totalRefundSales:", totalRefundSales);
    // console.log("totalPromotionRebates:", totalPromotionRebates);
    // console.log("Total FBA Fees:", totalFBAFees)
    // console.log("Total Amazon Fees:", totalAmazonFees)

    // console.log("amountDescription:", amountDescription);

    return {
      products: uniqueProducts,
      skus: Object.keys(products),
      productUnits: products,
      totalSalesCount: totalSalesCount,
      totalSales: totalSales,
      totalShipping, totalShipping,
      totalTax: totalTax,
      totalCommission: totalCommission,
      totalOtherSales: totalOtherSales,
      totalPromotionRebates: totalPromotionRebates,
      totalFBAFees: totalFBAFees,
      totalFBAPerUnitFulfillmentFee: totalFBAPerUnitFulfillmentFee,
      totalPromotionShipping, totalPromotionShipping,
      totalInventoryPlacementFee: totalInventoryPlacementFee,
      totalFBATransportationFee: totalFBATransportationFee,
      totalStorageFee: totalStorageFee,
      totalRefundSales: totalRefundSales,
      totalRefundSalesAFN: totalRefundSalesAFN,
      totalRefundSalesMFN: totalRefundSalesMFN,
      totalRefundExpenses: totalRefundExpenses,
      totalAmazonFees: totalAmazonFees,
      totalGiftwrap: totalGiftwrap,
      totalFBAInventoryFee: totalFBAInventoryFee,
      totalShippingLabelPurchase: totalShippingLabelPurchase,
      totalRefundCommission: totalRefundCommission,
      totalReversalReimbursement: totalReversalReimbursement,
      totalShippingRefund: totalShippingRefund,
      totalGiftwrapRefund: totalGiftwrapRefund,
      totalPromotionShippingRefund: totalPromotionShippingRefund,
      totalRefundCommissionFee: totalRefundCommissionFee,
      totalIncomeCredit: totalIncomeCredit,
      totalIncomeDebit: totalIncomeDebit,
      totalInventoryCredit: totalInventoryCredit,
      debitsArray: debitsArray,
      creditsArray: creditsArray,
      totalCommissionAFN: totalCommissionAFN,
      totalCommissionMFN: totalCommissionMFN,
      totalAdjustments: totalAdjustments,
      itemWithheldTaxOther: itemWithheldTaxOther,
      totalServiceFee: totalServiceFee,
      totalFBAFeesRefunds: totalFBAFeesRefunds
    }

  }

  return null;
}

var cardStyle = {
  transitionDuration: '0.3s',
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between"
}

const displayLineItem = (name, value) => {

  if (isNaN(value)) {
    return (<p>
      <span className="column">
        {name}
      </span>
      <span className="column right">
        {value}
      </span>
    </p>)
  }

  return parseFloat(value) != 0 ? <p>
    <span className="column">
      {name}
    </span>
    <span className="column right">
      {
        ConvertStringToDollarsCents(parseFloat(value))
      }
    </span>
  </p> : <></>
}

//  Amazon Income
// 	Income	Sales of Product Income			
// Amazon Gift Wrap Credits
// 	Income	Sales of Product Income			
// Amazon Other Credits
// 	Income	Sales of Product Income			
// Amazon Promotional Rebates
// 	Income	Sales of Product Income			
// Amazon Refunds
// 	Income	Sales of Product Income			
// Amazon Sales
// 	Income	Sales of Product Income			
// Amazon Shipping Credits


// - Referral Fee

// - FBA Fee
// - Principal
// - Variable Closing Fee & Fixed Closing Fee

// - Shipping Charge
// - Shipping Chargeback

// - Amazon Shipping Promo

// - Gift Wrap Charge
// - Gift Wrap Chargeback

// - Gift Wrap Commission

// - Tax
// - Shipping Tax
// - Gift Wrap Tax

// - Sales Tax Service Fee
// - Marketplace Facilitator Tax


// CS Error Items
// Incorrect Fees Items
// Goodwill
// Clawback
// CReturn Wrong Item
// Shipping Holdback Fee
// High Volume Listing Fee
// Non-subscription Fee Adjustment
// (to be continued)

export const BuildRefundData  = (key, invoiceItems) => {

  let invoice = {
    id: key,
    settlementId: key,
    totalAmt: 0,
    skus: [],
    items: [],
    totalRefundSales: 0,
    totalRefundTax: 0,
    totalRefundExpenses:0,
    totalRefundCommission:0,
    totalRefundCommissionFee:0,
    totalFBAFeesRefunds:0,
    totalShippingRefund:0,
    totalPromotionShippingRefund:0,
    totalGiftwrapRefund:0,
    totalRestockingFee:0,
    totalRefundSalesAFN:0,
    totalRefundSalesMFN:0,
    itemWithheldTaxShipping:0,
    itemWithheldTaxPrincipal:0,
    itemWithheldTaxOther:0
  }

  for (let i = 0; i < invoiceItems.length; i++) {
    
    let settlement = invoiceItems[i];

    if (settlement["transactionType"] == "Refund") {

      if (settlement["amountType"] == "ItemFees" && settlement["amountDescription"] == "Commission") {
        invoice.totalRefundCommission += parseFloat(settlement["amount"]);
      }


      if (settlement["amountDescription"] == "VariableClosingFee") {
        invoice.totalRefundCommission += parseFloat(settlement["amount"]);
      }

      if (settlement["amountType"] == "ItemFees" && settlement["amountDescription"] == "RefundCommission") {
        invoice.totalRefundCommissionFee += parseFloat(settlement["amount"]);
      }

      if (settlement["amountDescription"] == "GiftwrapChargeback") {
        invoice.totalFBAFeesRefunds += parseFloat(settlement["amount"]);
      }

      if (settlement["amountDescription"] == "ShippingChargeback") {
        invoice.totalFBAFeesRefunds += parseFloat(settlement["amount"]);
      }

      if (settlement["amountType"] == "ItemPrice" && settlement["amountDescription"] == "Shipping") {
        invoice.totalShippingRefund += parseFloat(settlement["amount"]);
        // invoice.totalIncomeDebit += parseFloat(settlement["amount"]);
      }

      if (settlement["amountType"] == "Promotion" && settlement["amountDescription"] == "Shipping") {
        invoice.totalPromotionShippingRefund += parseFloat(settlement["amount"]);
        // totalIncomeCredit += parseFloat(settlement["amount"]);
      }

      if (settlement["amountType"] == "ItemPrice" && settlement["amountDescription"] == "GiftWrap") {
        invoice.totalGiftwrapRefund += parseFloat(settlement["amount"]);
        // totalIncomeDebit += parseFloat(settlement["amount"]);
      }
      // RestockingFee
      if (settlement["amountType"] == "ItemPrice" && settlement["amountDescription"] == "RestockingFee") {
        // totalRefundSalesAFN += parseFloat(settlement["amount"]);
        invoice.totalRestockingFee +=  parseFloat(settlement["amount"]);
        // totalRefundSales += parseFloat(settlement["amount"]);
        // totalIncomeDebit += parseFloat(settlement["amount"]);
      }

      if (settlement["amountType"] == "ItemWithheldTax" && settlement["amountDescription"] == "MarketplaceFacilitatorTax-Shipping") {
        invoice.itemWithheldTaxShipping += parseFloat(settlement["amount"]);
      }

      if (settlement["amountType"] == "ItemWithheldTax" && settlement["amountDescription"] == "MarketplaceFacilitatorTax-Principal") {
        invoice.itemWithheldTaxPrincipal += parseFloat(settlement["amount"]);
      }

      if (settlement["amountType"] == "ItemWithheldTax" && settlement["amountDescription"] == "MarketplaceFacilitatorTax-Other") {
        invoice.itemWithheldTaxOther += parseFloat(settlement["amount"]);
      }


      if (settlement["amountType"] == "ItemPrice" && settlement["amountDescription"] == "Tax") {
        invoice.totalRefundTax += parseFloat(settlement["amount"]);
      }
      
      if (settlement["amountType"] == "ItemPrice" && settlement["amountDescription"] == "GiftWrapTax") {
        invoice.totalRefundTax += parseFloat(settlement["amount"]);
      }

      
      if (settlement["amountType"] == "ItemPrice" && (settlement["amountDescription"] == "Principal")) {

        if (settlement["fulfillmentId"] == "AFN") {
          invoice.totalRefundSalesAFN += parseFloat(settlement["amount"]);
        } else {
          invoice.totalRefundSalesMFN += parseFloat(settlement["amount"]);
        }
        invoice.totalRefundSales += parseFloat(settlement["amount"]);
        // totalIncomeDebit += parseFloat(settlement["amount"]);
        invoice.postedDateTime = settlement["postedDateTime"];
        invoice.skus.push({ sku: settlement["sku"], qty: 1, chargeAmount: parseFloat(settlement["amount"]) })

      }

    }

  }

  return invoice;
}


const BuildExpenseData = (key, invoiceItems) => {

  let expense = {
    id: key,
    settlementId: key,
    totalAmt: 0,
    skus: [],
    items: []
  }

  let totalAmount = 0;

  for (let i = 0; i < invoiceItems.length; i++) {
    let invoiceItem = invoiceItems[i];

    // expense.settlementId = invoiceItem.settlementId;
    // expense.postedDateTime = invoiceItem["postedDateTime"];
    // expense.totalAmt = parseFloat(invoiceItem["amount"]);
    // expense.amountDescription = invoiceItem["amountDescription"];
    // expense.sku = invoiceItem["sku"];
    // expense.orderId = invoiceItem["orderId"];
    totalAmount += parseFloat(invoiceItem["amount"]);

    expense.items.push({
      postedDateTime: invoiceItem["postedDateTime"],
      totalAmt: parseFloat(invoiceItem["amount"]),
      amountDescription : invoiceItem["amountDescription"],
      sku: invoiceItem["sku"],
      orderId: invoiceItem["orderId"]
    });

  }


  return expense;
}


export const BuildInvoiceData = (key, invoiceItems) => {
  let invoice = {
    id: key,
    hasQuickbooksInvoice: false, 
    settlementId: 0,
    productCharge: 0,
    shippingHB: 0,
    shippingCharge: 0,
    shippingChargeback: 0,
    promotionCharge: 0,
    postedDateTime: 0,
    fbaFulfillmentFee: 0,
    amazonReferralFee: 0,
    amazonClosingFee: 0,
    fbaFees: 0,
    giftWrap: 0,
    giftWrapChargeback: 0,
    giftWrapCommission: 0,
    itemTax: 0,
    itemTaxShipping: 0,
    itemTaxGiftwrap: 0,
    itemTaxWithheld: 0,
    itemTaxWithheldShipping: 0,
    itemTaxWithheldOther: 0,
    totalPromotionShipping: 0,
    totalRefundCommission: 0,
    totalRefundSales: 0,
    totalRefundExpenses: 0,
    totalRevenue: 0,
    totalFees: 0,
    skus: []
  }

  let productCharge = 0;
  let shippingCharge = 0;
  let shippingChargeback = 0;
  let promotionCharge = 0;

  // console.log("BuildInvoiceData:", invoiceItems);

  if (invoiceItems) {
    
    for (let i = 0; i < invoiceItems.length; i++) {
      let invoiceItem = invoiceItems[i];
      invoice.settlementId = invoiceItem.settlementId;
  
      //FBAInboundTransportationFee
      //Storage Fee
      //Inventory Placement Service Fee
      //RemovalComplete
      if (invoiceItem["hasQuickbooksInvoice"] == true) {
        invoice.hasQuickbooksInvoice = true;
        invoice.qbInvoice = invoiceItem["qbInvoice"];
      }

      if (invoiceItem["transactionType"] == "other-transaction" && invoiceItem["amountType"] == "other-transaction") {
        if (invoiceItem["amountDescription"] == "FBAInboundTransportationFee" || invoiceItem["amountDescription"] == "Storage Fee" || invoiceItem["amountDescription"] == "Inventory Placement Service Fee" || invoiceItem["amountDescription"] == "RemovalComplete") {
          let fbaFee = parseFloat(invoiceItem["amount"]);
          invoice.fbaFees += fbaFee;
        }
      }
  
      //Refund
      if (invoiceItem["transactionType"] == "Refund") {
        if (invoiceItem["amountType"] == "ItemPrice" && (invoiceItem["amountDescription"] == "Principal")) {
          invoice.totalRefundSales += parseFloat(invoiceItem["amount"]);
        } else {
          invoice.totalRefundExpenses += parseFloat(invoiceItem["amount"]);
        }
      }
  
  
      // Refund Commission
      if (invoiceItem["amountType"] == "ItemFees" && invoiceItem["amountDescription"] == "RefundCommission") {
        invoice.totalRefundCommission += parseFloat(invoiceItem["amount"]);
      }
  
      if (invoiceItem["amountType"] == "ItemFees" && invoiceItem["amountDescription"] == "ShippingHB") {
  
        let shippingHB = parseFloat(invoiceItem["amount"]);
        invoice.shippingHB += shippingHB;
        invoice.totalFees += shippingHB;
  
        // if (settlement["fulfillmentId"] == "MFN") {
        //   totalShippingHBMFN += parseFloat(settlement["amount"]);
        //   totalCommissionMFN += parseFloat(settlement["amount"]);
        // } else if (settlement["fulfillmentId"] == "AFN") {
        //   totalShippingHBAFN += parseFloat(settlement["amount"]);
        //   totalCommissionAFN += parseFloat(settlement["amount"]);
        // }
  
      }
  
      // Order
      if (invoiceItem["transactionType"] == "Order") {
  
  
        // FBA Fees
        if (invoiceItem["amountType"] == "ItemFees" && invoiceItem["amountDescription"] == "FBAPerUnitFulfillmentFee") {
          let fbaFulfillmentFee = parseFloat(invoiceItem["amount"]);
          invoice.fbaFulfillmentFee += fbaFulfillmentFee;
          // invoice.totalRevenue += fbaFulfillmentFee
          invoice.totalFees += fbaFulfillmentFee;
        }
  
        // Commission / Referral Fee
        if (invoiceItem["amountType"] == "ItemFees" && invoiceItem["amountDescription"] == "Commission") {
          let amazonReferralFee = parseFloat(invoiceItem["amount"]);
          invoice.amazonReferralFee += amazonReferralFee;
          // invoice.totalRevenue += amazonReferralFee
          invoice.totalFees += amazonReferralFee;
        }
  
        // Giftwrap Commission - for merchant fulfilled 
        if (invoiceItem["amountType"] == "ItemFees" && invoiceItem["amountDescription"] == "GiftWrapCommission") {
          let giftWrapCommission = parseFloat(invoiceItem["amount"]);
          invoice.giftWrapCommission += giftWrapCommission;
          // invoice.totalRevenue += giftWrapCommission
          invoice.totalFees += giftWrapCommission;
        }
  
        // Closing Fee - for media items
        if (invoiceItem["amountType"] == "ItemFees" && (invoiceItem["amountDescription"] == "VariableClosingFee" || invoiceItem["amountDescription"] == "FixedClosingFee")) {
          let amazonClosingFee = parseFloat(invoiceItem["amount"]);
          invoice.amazonClosingFee += amazonClosingFee;
  
          // invoice.totalRevenue += amazonClosingFee;
          invoice.totalFees += amazonClosingFee;
        }
  
        // Shipping
        // if (invoiceItem["amountType"] == "Promotion" && invoiceItem["amountDescription"] == "Shipping") {
        //   let totalPromotionShipping = parseFloat(invoiceItem["amount"]);            
        //   // invoice.totalRevenue += totalPromotionShipping;
        //   invoice.totalFees += totalPromotionShipping;
        // }
  
        // if (settlement["transactionType"] == "Order" && settlement["amountType"] == "ItemPrice" && settlement["amountDescription"] == "GiftWrap") {
  
        // Giftwrap
        if (invoiceItem["amountType"] == "ItemPrice" && invoiceItem["amountDescription"] == "GiftWrap") {
          let giftWrap = parseFloat(invoiceItem["amount"]);
          invoice.giftWrap += giftWrap;
          invoice.totalRevenue += giftWrap;
        }
        if (invoiceItem["amountType"] == "ItemFees") {
          //Giftwrap chargeback                  
          if (invoiceItem["amountDescription"] == "GiftwrapChargeback") {
            let giftWrapChargeback = parseFloat(invoiceItem["amount"]);
            invoice.giftWrapChargeback += giftWrapChargeback;
            // invoice.totalRevenue = giftWrapChargeback;
            invoice.totalFees += giftWrapChargeback;
          }
          //Shipping chargeback                  
          if (invoiceItem["amountDescription"] == "ShippingChargeback") {
            let shippingChargeback = parseFloat(invoiceItem["amount"]);
            invoice.shippingChargeback += shippingChargeback;
            // invoice.totalRevenue += shippingChargeback;
            invoice.totalFees += shippingChargeback;
          }
        }
  
        // Tax
        if (invoiceItem["amountType"] == "ItemPrice" && invoiceItem["amountDescription"] == "Tax") {
          let itemTax = parseFloat(invoiceItem["amount"]);
          invoice.itemTax += itemTax;
          // invoice.totalRevenue += itemTax;
        }
  
  
        // Shipping Tax
        if (invoiceItem["amountType"] == "ItemPrice" && invoiceItem["amountDescription"] == "ShippingTax") {
          let itemTaxShipping = parseFloat(invoiceItem["amount"]);
          invoice.itemTaxShipping += itemTaxShipping;
          // invoice.totalRevenue += itemTaxShipping;
        }
  
  
        if (invoiceItem["amountType"] == "ItemWithheldTax" && invoiceItem["amountDescription"] == "LowValueGoodsTax-Shipping") {
          
          let itemTaxWithheldShipping = parseFloat(invoiceItem["amount"]);
          invoice.itemTaxWithheldShipping += itemTaxWithheldShipping;
          // invoice.totalRevenue += itemTaxWithheldShipping;
        }
        if (invoiceItem["amountType"] == "ItemWithheldTax" && invoiceItem["amountDescription"] == "LowValueGoodsTax-Principal") {
            
          let itemTaxWithheld = parseFloat(invoiceItem["amount"]);
          invoice.itemTaxWithheld += itemTaxWithheld;
          // invoice.totalRevenue += itemTaxWithheld;
        }
  
  
        // Giftwrap Tax
        if (invoiceItem["amountType"] == "ItemPrice" && invoiceItem["amountDescription"] == "GiftWrapTax") {
          let itemTaxGiftwrap = parseFloat(invoiceItem["amount"]);
          invoice.itemTaxGiftwrap += itemTaxGiftwrap;
          // invoice.totalRevenue += itemTaxGiftwrap;
        }
  
  
        // MarketplaceFacilitatorTax-Principal
        if (invoiceItem["amountType"] == "ItemWithheldTax" && invoiceItem["amountDescription"] == "MarketplaceFacilitatorTax-Principal") {
          let itemTaxWithheld = parseFloat(invoiceItem["amount"]);
          invoice.itemTaxWithheld += itemTaxWithheld;
          // invoice.totalRevenue += itemTaxWithheld;
        }
  
        // MarketplaceFacilitatorTax-Shipping
        if (invoiceItem["amountType"] == "ItemWithheldTax" && invoiceItem["amountDescription"] == "MarketplaceFacilitatorTax-Shipping") {
          let itemTaxWithheldShipping = parseFloat(invoiceItem["amount"]);
          invoice.itemTaxWithheldShipping += itemTaxWithheldShipping;
          // invoice.totalRevenue += itemTaxWithheldShipping;
        }
  
        if (invoiceItem["amountType"] == "ItemWithheldTax" && invoiceItem["amountDescription"] == "MarketplaceFacilitatorTax-Other") {
          let itemTaxWithheldOther = parseFloat(invoiceItem["amount"]);
          invoice.itemTaxWithheldOther += itemTaxWithheldOther;
          // invoice.totalRevenue += itemTaxWithheldOther;
        }
  
  
        // Item price
        if (invoiceItem["amountType"] == "ItemPrice" && invoiceItem["amountDescription"] == "Principal") {
  
          let productChargeAmt = parseFloat(invoiceItem["amount"]);
          invoice.productCharge += productChargeAmt;
          invoice.totalRevenue += productChargeAmt;
          invoice.postedDateTime = invoiceItem["postedDateTime"];
  
          // If the settlement item is a principal then add it as an item sku
          // if (invoice.skus[invoiceItem["sku"]] == undefined) {
          //   invoice.skus[invoiceItem["sku"]] = [];
          // }
          // Quickbooks sales line item example
          // {
          //   "Description": "Rock Fountain", 
          //   "DetailType": "SalesItemLineDetail", 
          //   "SalesItemLineDetail": {
          //     "TaxCodeRef": {
          //       "value": "TAX"
          //     }, 
          //     "Qty": 1, 
          //     "UnitPrice": 275, 
          //     "ItemRef": {
          //       "name": "Rock Fountain", 
          //       "value": "5"
          //     }
          //   }, 
          //   "LineNum": 1, 
          //   "Amount": 275.0, 
          //   "Id": "1"
          // }, 
          invoice.skus.push({ sku: invoiceItem["sku"], qty: invoiceItem["quantityPurchased"], chargeAmount: productChargeAmt })
        }
  
        // Shipping Promotion
        if (invoiceItem["amountType"] == "ItemPrice" || invoiceItem["amountType"] == "Promotion") {
          if (invoiceItem["amountDescription"] == "Shipping") {
            if (invoiceItem["amountType"] == "Promotion") {
              let promotionCharge = parseFloat(invoiceItem["amount"]);
              invoice.promotionCharge += promotionCharge;
              invoice.totalFees += promotionCharge;
            } else {
              let shippingCharge = parseFloat(invoiceItem["amount"]);
              invoice.shippingCharge += shippingCharge;
              invoice.totalRevenue += shippingCharge;
            }
          }
        }
      }
  
    }
  
  }

  
  return invoice;
}

export const DisplayFetchedDataAsInvoice = (invoices) => {

  let keys = Object.keys(invoices);

  return keys.map(key => {

    let invoiceItems = invoices[key];
    let invoice = BuildInvoiceData(key, invoiceItems);

    // let invoice = {
    //   id: key,
    //   settlementId: 0,
    //   productCharge: 0,
    //   shippingCharge: 0,
    //   shippingChargeback: 0,
    //   promotionCharge: 0,
    //   postedDateTime: 0,
    //   fbaFulfillmentFee: 0,
    //   amazonReferralFee: 0,
    //   amazonClosingFee: 0,
    //   fbaFees: 0,
    //   giftWrap: 0,
    //   giftWrapChargeback: 0,
    //   giftWrapCommission: 0,
    //   itemTax: 0,
    //   itemTaxWithheld: 0,
    //   itemTaxShipping: 0,
    //   itemTaxGiftwrap:0,
    //   itemTaxWithheldShipping: 0,
    //   itemTaxWithheldOther:0,
    //   totalPromotionShipping: 0,
    //   totalRefundCommission: 0,
    //   totalRefundSales: 0,
    //   totalRefundExpenses: 0,        
    //   totalRevenue: 0,
    //   totalFees: 0,
    //   skus: []
    // }

    // for (let i = 0; i < invoiceItems.length; i++) {
    //   let invoiceItem = invoiceItems[i];
    //   invoice.settlementId = invoiceItem.settlementId;

    //   //FBAInboundTransportationFee
    //   //Storage Fee
    //   //Inventory Placement Service Fee
    //   //RemovalComplete
    //   if (invoiceItem["transactionType"] == "other-transaction" && invoiceItem["amountType"] == "other-transaction") {
    //     if (invoiceItem["amountDescription"] == "FBAInboundTransportationFee" || invoiceItem["amountDescription"] == "Storage Fee" || invoiceItem["amountDescription"] == "Inventory Placement Service Fee" || invoiceItem["amountDescription"] == "RemovalComplete") {
    //       let fbaFee = parseFloat(invoiceItem["amount"]);
    //       invoice.fbaFees += fbaFee;
    //     }
    //   }

    //   //Refund
    //   if (invoiceItem["transactionType"] == "Refund") {
    //     if (invoiceItem["amountType"] == "ItemPrice" && (invoiceItem["amountDescription"] == "Principal")) {
    //       invoice.totalRefundSales += parseFloat(invoiceItem["amount"]);
    //     } else {
    //       invoice.totalRefundExpenses += parseFloat(invoiceItem["amount"]);
    //     }
    //   }


    //   // Refund Commission
    //   if (invoiceItem["amountType"] == "ItemFees" && invoiceItem["amountDescription"] == "RefundCommission") {
    //     invoice.totalRefundCommission += parseFloat(invoiceItem["amount"]);
    //   }

    //   // Order
    //   if (invoiceItem["transactionType"] == "Order") {

    //     if (key == "114-9508397-2899414") {
    //       console.log("invoiceItem:", invoiceItem);
    //     }

    //     // FBA Fees
    //     if (invoiceItem["amountType"] == "ItemFees" && invoiceItem["amountDescription"] == "FBAPerUnitFulfillmentFee") {
    //       let fbaFulfillmentFee = parseFloat(invoiceItem["amount"]);
    //       invoice.fbaFulfillmentFee += fbaFulfillmentFee;
    //       invoice.totalRevenue += fbaFulfillmentFee
    //       invoice.totalFees += fbaFulfillmentFee;
    //     }

    //     // Commission / Referral Fee
    //     if (invoiceItem["amountType"] == "ItemFees" && invoiceItem["amountDescription"] == "Commission") {
    //       let amazonReferralFee = parseFloat(invoiceItem["amount"]);
    //       invoice.amazonReferralFee += amazonReferralFee;
    //       invoice.totalRevenue += amazonReferralFee
    //       invoice.totalFees += amazonReferralFee;
    //     }

    //     // Giftwrap Commission - for merchant fulfilled 
    //     if (invoiceItem["amountType"] == "ItemFees" && invoiceItem["amountDescription"] == "GiftWrapCommission") {
    //       let giftWrapCommission = parseFloat(invoiceItem["amount"]);
    //       invoice.giftWrapCommission += giftWrapCommission;
    //       invoice.totalRevenue += giftWrapCommission
    //       invoice.totalFees += giftWrapCommission;
    //     }

    //     // Closing Fee - for media items
    //     if (invoiceItem["amountType"] == "ItemFees" && (invoiceItem["amountDescription"] == "VariableClosingFee" || invoiceItem["amountDescription"] == "FixedClosingFee")) {
    //       let amazonClosingFee = parseFloat(invoiceItem["amount"]);
    //       invoice.amazonClosingFee += amazonClosingFee;
    //       invoice.totalRevenue += amazonClosingFee;
    //       invoice.totalFees += amazonClosingFee;
    //     }

    //     // Shipping
    //     if (invoiceItem["amountType"] == "Promotion" && invoiceItem["amountDescription"] == "Shipping") {
    //       let totalPromotionShipping = parseFloat(invoiceItem["amount"]);            
    //       invoice.totalRevenue += totalPromotionShipping;
    //       invoice.totalFees += totalPromotionShipping;
    //     }

    //     // if (settlement["transactionType"] == "Order" && settlement["amountType"] == "ItemPrice" && settlement["amountDescription"] == "GiftWrap") {

    //     // Giftwrap
    //     if (invoiceItem["amountType"] == "ItemPrice" && invoiceItem["amountDescription"] == "GiftWrap") {
    //       let giftWrap = parseFloat(invoiceItem["amount"]);
    //       invoice.giftWrap += giftWrap;
    //       invoice.totalRevenue += giftWrap;
    //     }
    //     if (invoiceItem["amountType"] == "ItemFees") {
    //       //Giftwrap chargeback                  
    //       if (invoiceItem["amountDescription"] == "GiftwrapChargeback") {
    //         let giftWrapChargeback = parseFloat(invoiceItem["amount"]);
    //         invoice.giftWrapChargeback += giftWrapChargeback;
    //         invoice.totalRevenue = giftWrapChargeback;
    //         invoice.totalFees += giftWrapChargeback;
    //       }
    //       //Shipping chargeback                  
    //       if (invoiceItem["amountDescription"] == "ShippingChargeback") {
    //         let shippingChargeback = parseFloat(invoiceItem["amount"]);
    //         invoice.shippingChargeback += shippingChargeback;
    //         invoice.totalRevenue += shippingChargeback;
    //         invoice.totalFees += shippingChargeback;
    //       }
    //     }

    //     // Tax
    //     if (invoiceItem["amountType"] == "ItemPrice" && invoiceItem["amountDescription"] == "Tax") {
    //       let itemTax = parseFloat(invoiceItem["amount"]);
    //       invoice.itemTax += itemTax;
    //       invoice.totalRevenue += itemTax;
    //     }


    //     // Shipping Tax
    //     if (invoiceItem["amountType"] == "ItemPrice" && invoiceItem["amountDescription"] == "ShippingTax") {
    //       let itemTaxShipping = parseFloat(invoiceItem["amount"]);
    //       invoice.itemTaxShipping += itemTaxShipping;
    //       invoice.totalRevenue += itemTaxShipping;
    //     }



    //     // Giftwrap Tax
    //     if (invoiceItem["amountType"] == "ItemPrice" && invoiceItem["amountDescription"] == "GiftWrapTax") {
    //       let itemTaxGiftwrap = parseFloat(invoiceItem["amount"]);
    //       invoice.itemTaxGiftwrap += itemTaxGiftwrap;
    //       invoice.totalRevenue += itemTaxGiftwrap;
    //     }


    //     // MarketplaceFacilitatorTax-Principal
    //     if (invoiceItem["amountType"] == "ItemWithheldTax" && invoiceItem["amountDescription"] == "MarketplaceFacilitatorTax-Principal") {
    //       let itemTaxWithheld = parseFloat(invoiceItem["amount"]);
    //       invoice.itemTaxWithheld += itemTaxWithheld;
    //       invoice.totalRevenue += itemTaxWithheld;
    //     }

    //     // MarketplaceFacilitatorTax-Shipping
    //     if (invoiceItem["amountType"] == "ItemWithheldTax" && invoiceItem["amountDescription"] == "MarketplaceFacilitatorTax-Shipping") {
    //       let itemTaxWithheldShipping = parseFloat(invoiceItem["amount"]);
    //       invoice.itemTaxWithheldShipping += itemTaxWithheldShipping;
    //       invoice.totalRevenue += itemTaxWithheldShipping;
    //     }

    //     if (invoiceItem["amountType"] == "ItemWithheldTax" && invoiceItem["amountDescription"] == "MarketplaceFacilitatorTax-Other") {
    //       let itemTaxWithheldOther = parseFloat(invoiceItem["amount"]);
    //       invoice.itemTaxWithheldOther += itemTaxWithheldOther;
    //       invoice.totalRevenue += itemTaxWithheldOther;
    //     }

    //     if (invoiceItem["amountType"] == "ItemWithheldTax" && invoiceItem["amountDescription"] == "LowValueGoodsTax-Shipping") {
    //       let itemTaxWithheldShipping = parseFloat(invoiceItem["amount"]);
    //       invoice.itemTaxWithheldShipping += itemTaxWithheldShipping;
    //       invoice.totalRevenue += itemTaxWithheldShipping;
    //     }
    //     if (invoiceItem["amountType"] == "ItemWithheldTax" && invoiceItem["amountDescription"] == "LowValueGoodsTax-Principal") {
    //       let itemTaxWithheld = parseFloat(invoiceItem["amount"]);
    //       invoice.itemTaxWithheld += itemTaxWithheld;
    //       invoice.totalRevenue += itemTaxWithheld;
    //     }


    //     // Item price
    //     if (invoiceItem["amountType"] == "ItemPrice" && invoiceItem["amountDescription"] == "Principal") {
    //       let productChargeAmt = parseFloat(invoiceItem["amount"]);
    //       invoice.productCharge += productChargeAmt;
    //       invoice.totalRevenue += productChargeAmt;
    //       invoice.postedDateTime = invoiceItem["postedDateTime"];

    //       // If the settlement item is a principal then add it as an item sku
    //       // if (invoice.skus[invoiceItem["sku"]] == undefined) {
    //       //   invoice.skus[invoiceItem["sku"]] = [];
    //       // }
    //       // Quickbooks sales line item example
    //       // {
    //       //   "Description": "Rock Fountain", 
    //       //   "DetailType": "SalesItemLineDetail", 
    //       //   "SalesItemLineDetail": {
    //       //     "TaxCodeRef": {
    //       //       "value": "TAX"
    //       //     }, 
    //       //     "Qty": 1, 
    //       //     "UnitPrice": 275, 
    //       //     "ItemRef": {
    //       //       "name": "Rock Fountain", 
    //       //       "value": "5"
    //       //     }
    //       //   }, 
    //       //   "LineNum": 1, 
    //       //   "Amount": 275.0, 
    //       //   "Id": "1"
    //       // }, 
    //       invoice.skus.push({sku:invoiceItem["sku"], qty:invoiceItem["quantityPurchased"], chargeAmount:productChargeAmt })
    //     }

    //     // Shipping Promotion
    //     if (invoiceItem["amountType"] == "ItemPrice" || invoiceItem["amountType"] == "Promotion") {
    //       if (invoiceItem["amountDescription"] == "Shipping") {
    //         if (invoiceItem["amountType"] == "Promotion") {
    //           invoice.promotionCharge += parseFloat(invoiceItem["amount"]);
    //           invoice.totalRevenue += promotionCharge;
    //         } else {
    //           invoice.shippingCharge += parseFloat(invoiceItem["amount"]);
    //           invoice.totalRevenue += shippingCharge;
    //         }
    //       }
    //     }
    //   }
    // }

    // if (key == "112-9704321-2469000") {
    //   // console.log("invoiceItem:", invoiceItem);
    //   console.log("invoice:", invoice);
    //   console.log("invoiceItems:", invoice.giftWrap);
    //   console.log("invoiceItems:", invoice.invoiceItems);
    //   console.log("postedDateTime:", invoice.postedDateTime);
    //   console.log("shippingCharge:", invoice.shippingCharge);
    //   console.log("promotionCharge:", invoice.promotionCharge);
    //   console.log("fbaFulfillmentFee:", invoice.fbaFulfillmentFee);
    //   console.log("amazonReferralFee:", invoice.amazonReferralFee);
    //   console.log("itemTax:", invoice.itemTax);
    //   console.log("itemTaxWithheld:", invoice.itemTaxWithheld);


    // }


    return <Card style={cardStyle} className={"invoice-card" + invoice.skus && invoice.skus.length > 1 ? " more items" : ""}>

      <CardContent>

        <div className="invoice-block">
          <div style={{ padding: "3em" }}>
            <div>

              <p>
                <span className="column-small">
                  Order Id:
                      </span>
                {
                  <a href={"https://sellercentral.amazon.com/orders-v3/order/" + key} target="_blank">{key}</a>
                }
              </p>


              <p>
                <span className="column-small">
                  Settlement Id:
                      </span>
                {
                  invoice.settlementId
                }
              </p>

            </div>

            <div>
              <p>
                <span className="column-small">
                  Order Date:
                      </span>
                {
                  moment(invoice.postedDateTime.replace(" UTC", "")).format("MM/DD/YYYY HH:mm")
                }
              </p>

              <div>
                <p>
                  <span className="column-small">
                    Skus:
                      </span>
                  {
                    invoice.skus.map(sku => {
                      return <>
                        {sku.sku}
                      </>
                    })
                  }
                </p>

                <p>
                  <span className="column-small">
                    SyncToken:
                      </span>
                      {
                        invoice.qbInvoice && invoice.qbInvoice[0] ?   invoice.qbInvoice[0].SyncToken : "-"
                      }      
                </p>
                

              </div>

              <p className="label">Income</p>

              {
                invoice.skus.map(sku => {
                  return <p>
                    <span className="column">{sku.sku}</span>
                    <span className="column right">
                      {
                        ConvertStringToDollarsCents(parseFloat(sku.chargeAmount))
                      }
                    </span>
                  </p>
                })
              }

              {
                // displayLineItem("Sales", invoice.productCharge)
              }

              {
                displayLineItem("Shipping Credit", invoice.shippingCharge)
              }

              {
                displayLineItem("Refund Commission", invoice.totalRefundCommission)
              }

              {
                displayLineItem("Gift Wrap", invoice.giftWrap)
              }


              <p className="label">Expenses</p>

              {
                displayLineItem("Amazon Referral Fee", invoice.amazonReferralFee)
              }

              {
                // displayLineItem("Gift Wrap Commission", invoice.giftWrapCommission)
              }

              {
                displayLineItem("FBA Fulfillment Fee", invoice.fbaFulfillmentFee)
              }
              {
                displayLineItem("Other Fees", invoice.amazonClosingFee + invoice.shippingHB)
              }
              {
                displayLineItem("Shipping Promotion", invoice.promotionCharge)
              }

              {
                displayLineItem("Shipping Chargeback", invoice.shippingChargeback)
              }

              {
                displayLineItem("Gift Wrap Chargeback", invoice.giftWrapChargeback)
              }

              <p className="label">Taxes</p>

              {
                displayLineItem("Item Tax", invoice.itemTax)
              }

              {
                displayLineItem("Shipping Tax", invoice.itemTaxShipping)
              }

              {
                displayLineItem("Giftwrap Tax", invoice.itemTaxGiftwrap)
              }
              {
                displayLineItem("Marketplace Facilitator Tax Principal", invoice.itemTaxWithheld)
              }
              {
                displayLineItem("Marketplace Facilitator Tax Shipping", invoice.itemTaxWithheldShipping)
              }
              {
                displayLineItem("Marketplace Facilitator Tax Other", invoice.itemTaxWithheldOther)
              }

              <p className="label">Totals</p>
              {
                displayLineItem("Revenue", invoice.totalRevenue)
              }
              {
                displayLineItem("Fees", invoice.totalFees)
              }
              {
                displayLineItem("Net", (parseFloat(invoice.totalRevenue) + parseFloat(invoice.totalFees)))
              }
            </div>

            <QuickbooksLink invoice={invoice} />

            
          </div>

        </div>
      </CardContent>
    </Card>
  })
}



export const DisplayFetchedDataAsRefund = (invoices) => {

  let keys = Object.keys(invoices);

  

  return keys.map(key => {

    let invoiceItems = invoices[key];

    let invoice = BuildRefundData(key, invoiceItems);

    

    return <Card style={cardStyle} className={"invoice-card"}>

      <CardContent>

      <div className="invoice-block">
          <div style={{ padding: "3em" }}>
            <div>

              <p>
                <span className="column-small">
                  Order Id:
                      </span>
                {
                  <a href={"https://sellercentral.amazon.com/orders-v3/order/" + key} target="_blank">{key}</a>
                }
              </p>


              <p>
                <span className="column-small">
                  Settlement Id:
                      </span>
                {
                  invoice.settlementId
                }
              </p>

            </div>

            <div>
              <p>
                <span className="column-small">
                  Order Date:
                      </span>
                {
                  invoice.postedDateTime ? moment(invoice.postedDateTime.replace(" UTC", "")).format("MM/DD/YYYY HH:mm") : ""                
                }
              </p>

              <div>
                <p>
                  <span className="column-small">
                    Skus:
                      </span>
                  {
                    invoice.skus.map(sku => {
                      return <>
                        {sku.sku}
                      </>
                    })
                  }
                </p>
                
             
              </div>

              
              <p className="label">Refunds</p>

              {
                invoice.skus.map(sku => {
                  return <p>
                    <span className="column">{sku.sku}</span>
                    <span className="column right">
                      {
                        ConvertStringToDollarsCents(parseFloat(sku.chargeAmount))
                      }
                    </span>
                  </p>
                })
              }

              {
                displayLineItem("Tax Collected Refund", invoice.totalRefundTax)
              }
              {
                displayLineItem("Marketplace Facilitator Tax Principal", invoice.itemWithheldTaxPrincipal)
              }
              {
                displayLineItem("Marketplace Facilitator Tax Shipping", invoice.itemWithheldTaxShipping)
              }
              {
                displayLineItem("Marketplace Facilitator Tax Other", invoice.itemWithheldTaxOther)
              }

              {
                displayLineItem("Refund Commission", invoice.totalRefundCommission)
              }

              {
                displayLineItem("Gift Wrap Refund", invoice.totalGiftwrapRefund)
              }

              {
                displayLineItem("FBA Refund", invoice.totalFBAFeesRefunds)
              }


              <p className="label">Fees</p>

              {
                displayLineItem("Restocking Fee", invoice.totalRestockingFee)
              }

              {
                displayLineItem("Refund Commission Fee", invoice.totalRefundCommissionFee)
              }

              <p className="label">Totals</p>

              {
                displayLineItem("Net", (parseFloat(invoice.totalRefundSales) + parseFloat(invoice.totalRestockingFee) + parseFloat(invoice.totalRefundCommission) + parseFloat(invoice.totalRefundCommissionFee)))
              }
            </div>

            <QuickbooksLink invoice={invoice} isRefund={true} />

          </div>

        </div>
     
      </CardContent>
    </Card>
  })
}


export const DisplayFetchedDataAsExpense = (invoices) => {

  let keys = Object.keys(invoices);

  

  return keys.map(key => {

    let invoiceItems = invoices[key];

    let invoice = BuildExpenseData(key, invoiceItems);

    

    return <Card style={cardStyle} className={"invoice-card"}>

      <CardContent>

        <div style={{textAlign:"left"}}>
          <div style={{ padding: "3em" }}>
            <div>

              <p>
                <span className="column-small">
                  Settlement Id:
                      </span>
                {
                  invoice.settlementId
                }
              </p>

            </div>

            {

              invoice.items ? <>

                {
                  invoice.items.map(item => {
                    return         <div className="invoice-block">

                      {item.sku}

                      <p>
                        <span className="column-small">
                          Order Id:
                      </span>
                        {
                          <a href={"https://sellercentral.amazon.com/orders-v3/order/" + item.orderId} target="_blank">{item.orderId}</a>
                        }
                      </p>

                      <div>
                        <p>
                          <span className="column-small">
                            Posted Date:
                      </span>
                          {
                            item.postedDateTime ? moment(item.postedDateTime.replace(" UTC", "")).format("MM/DD/YYYY HH:mm") : ""
                          }
                        </p>

                        <p className="label">Expense Details</p>

                        {
                          displayLineItem("Description", item.amountDescription)
                        }

                        {
                          displayLineItem("Sku", item.sku)
                        }

                        {
                          displayLineItem("Amount", item.totalAmt)
                        }

                       <QuickbooksLink invoice={item} isExpense={true} />

                      </div>


                    </div>
                  })
                }

              </> : <></>
            }

          </div>

        </div>
      </CardContent>
    </Card>
  })
}


export const GetDbItemObject = (item, itemType) => {
  //Listings and Orders always replace the existing values.
  //We can capture the changes with a dynamo db event

  // console.log("getDbItemObject:", item);

  if (itemType == "Listing") {
    let updateItem = {
      // "owner": process.env.owner,
      "type": "Listing",
      "type#createdAt#id": "Listing" + "#" + MASTER_TIME + "#" + item["seller-sku"],
      "id": item["seller-sku"],
      "createdAt": MASTER_TIME,
      "name": item['item-name'] ? item['item-name'] : " ",
      // createdAt:item.createdAt ? item.createdAt : moment().toISOString(),
      asin: item["asin1"],
      condition: item["item-condition"],
      price: item["price"] ? item["price"] : null,
      listingId: item["listing-id"],
      quantity: item["quantity"] ? parseInt(item["quantity"]) : 0,
      imageURL: item["image-url"],
      openDate: item["open-date"] ? item["open-date"] : null,
      itemIsMarketplace: item["item-is-marketplace"] ? item["item-is-marketplace"] : null,
      productIdType: item["product-id-type"] ? item["product-id-type"] : null,
      productId: item["product-id"] ? item["product-id"] : null,
      itemNote: item["item-note"] ? item["item-note"] : null,
      itemCondition: item["item-condition"],
      asin1: item["asin1"] ? item["asin1"] : null,
      asin2: item["asin2"] ? item["asin2"] : null,
      asin3: item["asin3"] ? item["asin3"] : null,
      willShipInternationally: item["will-ship-internationally"] ? item["will-ship-internationally"] : null,
      expeditedShipping: item["expedited-shipping"] ? item["expedited-shipping"] : null,
      bidForFeaturedPlacement: item["bid-for-featured-placement"] ? item["bid-for-featured-placement"] : null,
      addDelete: item["add-delete"] ? item["add-delete"] : null,
      pendingQuantity: item["pending-quantity"] ? parseInt(item["pending-quantity"]) : 0,
      fulfillmentChannel: item["fulfillment-channel"] ? item["fulfillment-channel"] : null,
      merchantShippingGroup: item["merchant-shipping-group"] ? item["merchant-shipping-group"] : null,
      status: item["status"] ? item["status"] : null
    }
    if (item["fnsku"]) {
      updateItem.fnsku = item["fnsku"];
      updateItem.afnListingExists = item["afn-listing-exists"];
      updateItem.mfnListingExists = item["mfn-listing-exists"];
      updateItem.mfnFulfillableQuantity = item["mfn-fulfillable-quantity"];
      updateItem.afnWarehouseQuantity = item["afn-warehouse-quantity"];
      updateItem.afnFulfillableQuantity = item["afn-fulfillable-quantity"];
      updateItem.afnUnsellableQuantity = item["afn-unsellable-quantity"];
      updateItem.afnReservedQuantity = item["afn-reserved-quantity"];
      updateItem.afnTotalQuantity = item["afn-total-quantity"];
      updateItem.perUnitVolume = item["per-unit-volume"] ? item["per-unit-volume"] : 0;
      updateItem.afnInboundWorkingQuantity = item["afn-inbound-working-quantity"];
      updateItem.afnInboundShippedQuantity = item["afn-inbound-shipped-quantity"];
      updateItem.afnInboundReceivingQuantity = item["afn-inbound-receiving-quantity"];
      updateItem.afnReservedFutureSupply = item["afn-reserved-future-supply"];
      updateItem.afnResearchingQuantity = item["afn-researching-quantity"];
      updateItem.afnFutureSupplyBuyable = item["afn-future-supply-buyable"];
    }

    return updateItem;
  }

  if (itemType == "MerchantListing") {
    let updateItem = {
      "owner": process.env.owner,
      "type": "Listing",
      "type#createdAt#id": "Listing" + "#" + MASTER_TIME + "#" + item["seller-sku"],
      "id": item["seller-sku"],
      "createdAt": MASTER_TIME,
      "name": item['item-name'] ? item['item-name'] : " ",
      // createdAt:item.createdAt ? item.createdAt : moment().toISOString(),
      asin: item["asin1"],
      condition: item["item-condition"],
      price: item["price"] ? item["price"] : null,
      listingId: item["listing-id"],
      quantity: item["quantity"] ? parseInt(item["quantity"]) : 0,
      imageURL: item["image-url"],
      openDate: item["open-date"] ? item["open-date"] : null,
      itemIsMarketplace: item["item-is-marketplace"] ? item["item-is-marketplace"] : null,
      productIdType: item["product-id-type"] ? item["product-id-type"] : null,
      productId: item["product-id"] ? item["product-id"] : null,
      itemNote: item["item-note"] ? item["item-note"] : null,
      itemCondition: item["item-condition"],
      asin1: item["asin1"] ? item["asin1"] : null,
      asin2: item["asin2"] ? item["asin2"] : null,
      asin3: item["asin3"] ? item["asin3"] : null,
      willShipInternationally: item["will-ship-internationally"] ? item["will-ship-internationally"] : null,
      expeditedShipping: item["expedited-shipping"] ? item["expedited-shipping"] : null,
      bidForFeaturedPlacement: item["bid-for-featured-placement"] ? item["bid-for-featured-placement"] : null,
      addDelete: item["add-delete"] ? item["add-delete"] : null,
      pendingQuantity: item["pending-quantity"] ? parseInt(item["pending-quantity"]) : 0,
      fulfillmentChannel: item["fulfillment-channel"] ? item["fulfillment-channel"] : null,
      merchantShippingGroup: item["merchant-shipping-group"] ? item["merchant-shipping-group"] : null,
      status: item["status"] ? item["status"] : null
    }

    return updateItem;

  }

  if (itemType == "FBAListing") {

    let updateItem = {
      "owner": process.env.owner,
      "type": "Listing",
      "type#createdAt#id": "Listing" + "#" + MASTER_TIME + "#" + item["sku"],
      "id": item["sku"],
      "createdAt": MASTER_TIME,
      "name": item['product-name'] ? item['product-name'] : " ",
      // createdAt:item.createdAt ? item.createdAt : moment().toISOString(),
      asin: item["asin"],
      condition: item["condition"],
      price: item["your-price"] ? item["your-price"] : null,
      listingId: item["listing-id"],
      quantity: item["quantity"] ? parseInt(item["quantity"]) : 0,
      openDate: item["open-date"] ? item["open-date"] : null,
      itemIsMarketplace: item["item-is-marketplace"] ? item["item-is-marketplace"] : null,
      productIdType: item["product-id-type"] ? item["product-id-type"] : null,
      productId: item["product-id"] ? item["product-id"] : null,
      itemNote: item["item-note"] ? item["item-note"] : null,
      itemCondition: item["item-condition"],
      asin1: item["asin1"] ? item["asin1"] : null,
      asin2: item["asin2"] ? item["asin2"] : null,
      asin3: item["asin3"] ? item["asin3"] : null,
      willShipInternationally: item["will-ship-internationally"] ? item["will-ship-internationally"] : null,
      expeditedShipping: item["expedited-shipping"] ? item["expedited-shipping"] : null,
      bidForFeaturedPlacement: item["bid-for-featured-placement"] ? item["bid-for-featured-placement"] : null,
      addDelete: item["add-delete"] ? item["add-delete"] : null,
      pendingQuantity: item["pending-quantity"] ? parseInt(item["pending-quantity"]) : 0,
      fulfillmentChannel: item["fulfillment-channel"] ? item["fulfillment-channel"] : null,
      merchantShippingGroup: item["merchant-shipping-group"] ? item["merchant-shipping-group"] : null,
      status: item["status"] ? item["status"] : null
    }

    if (item.fnsku) {
      updateItem.fnsku = item["fnsku"];
      updateItem.afnListingExists = item["afn-listing-exists"];
      updateItem.mfnListingExists = item["mfn-listing-exists"];
      updateItem.mfnFulfillableQuantity = item["mfn-fulfillable-quantity"];
      updateItem.afnWarehouseQuantity = item["afn-warehouse-quantity"];
      updateItem.afnFulfillableQuantity = item["afn-fulfillable-quantity"];
      updateItem.afnUnsellableQuantity = item["afn-unsellable-quantity"];
      updateItem.afnReservedQuantity = item["afn-reserved-quantity"];
      updateItem.afnTotalQuantity = item["afn-total-quantity"];
      updateItem.perUnitVolume = item["per-unit-volume"] ? item["per-unit-volume"] : 0;
      updateItem.afnInboundWorkingQuantity = item["afn-inbound-working-quantity"];
      updateItem.afnInboundShippedQuantity = item["afn-inbound-shipped-quantity"];
      updateItem.afnInboundReceivingQuantity = item["afn-inbound-receiving-quantity"];
      updateItem.afnReservedFutureSupply = item["afn-reserved-future-supply"];
      updateItem.afnResearchingQuantity = item["afn-researching-quantity"];
      updateItem.afnFutureSupplyBuyable = item["afn-future-supply-buyable"];
    }
    return updateItem;
  }

  if (itemType == "Order") {

    let purchaseDate = item["PurchaseDate"];
    let purchaseDateTime = moment(purchaseDate).startOf('day');
    let todayTime = moment().startOf('day');
    let dayDiff = todayTime.diff(purchaseDateTime, 'day');

    // If the the purchase date is more than 90 days do not save PII            
    let updateItem = {
      "owner": process.env.owner,
      "type": "Order",
      "createdAt": item['PurchaseDate'],
      // "type#createdAt#id": "Order" + "#" + item['PurchaseDate'] + "#" + item['AmazonOrderId'], // Not needed
      "id": item['AmazonOrderId'],
      IsBusinessOrder: item['IsBusinessOrder'],
      IsPrime: item['IsPrime'],
      IsReplacementOrder: item['IsReplacementOrder'],
      IsGlobalExpressEnabled: item['IsGlobalExpressEnabled'],
      IsSoldByAB: item['IsSoldByAB'],
      IsPremiumOrder: item['IsPremiumOrder'],
      LatestShipDate: item['LatestShipDate'],
      OrderType: item['OrderType'],
      PurchaseDate: item['PurchaseDate'],
      AmazonOrderId: item['AmazonOrderId'],
      LastUpdateDate: item['LastUpdateDate'],
      NumberOfItemsShipped: item['NumberOfItemsShipped'],
      ShipServiceLevel: item['ShipServiceLevel'],
      OrderItem: item['OrderItem'],
      OrderStatus: item['OrderStatus'],

      OrderZipCode: item.ShippingAddress && item.ShippingAddress.PostalCode ? item.ShippingAddress.PostalCode : "",
      OrderState: item.ShippingAddress && item.ShippingAddress.StateOrRegion ? item.ShippingAddress.StateOrRegion : "",
      OrderCity: item.ShippingAddress && item.ShippingAddress.City ? item.ShippingAddress.City : "",
      OrderCountry: item.ShippingAddress && item.ShippingAddress.CountryCode ? item.ShippingAddress.CountryCode : "",

      SalesChannel: item['SalesChannel'],
      NumberOfItemsUnshipped: item['NumberOfItemsUnshipped'],
      PaymentMethod: item['PaymentMethod'],
      PaymentMethodDetails: item['PaymentMethodDetails'],
      EarliestShipDate: item['EarliestShipDate'],
      MarketplaceId: item['MarketplaceId'],
      ShipmentServiceLevelCategory: item['ShipmentServiceLevelCategory'],
      FulfillmentChannel: item['FulfillmentChannel'],
      ShippingAddress: dayDiff > 90 ? null : item['ShippingAddress'],
      SellerOrderId: item['SellerOrderId']
    }
    return updateItem;
  }

}


export const CreateInvoice = async (invoiceInput, isRefund, user, sendAlertMessage) => {

  
  const createInvoice = async (invoiceInput, isRefund, user, sendAlertMessage)=>{
 
    
    const createRefundQuickbookInput = async (invoiceInput, item, refundTypeFound)=>{
  
      // let orderState = item.OrderState;
      // let orderZipCode = item.OrderZipCode;
      // let orderCity = item.OrderCity;
      // let orderCountry = item.OrderCountry;
      // let orderFulfillmentChannel = item.FulfillmentChannel;
      let orderItem = item.OrderItem;
   
      if (invoiceInput.skus) {
          for (let y = 0; y < invoiceInput.skus.length; y++) {
              let sku = invoiceInput.skus[y].sku;
              for (let x = 0; x < orderItem.length; x++) {
                  let item = orderItem[x];
                  if (item.SellerSKU == sku) {
                      invoiceInput.skus[y].asin = item.ASIN;                                            
                      invoiceInput.skus[y].title = item.Title;
                  }
              }                                    
          }                                
      }
  
      // invoiceInput.orderFulfillmentChannel = orderFulfillmentChannel;
  
      let quickBooksRealmId = window.localStorage.getItem("quickBooksRealmId");
      let quickBooksToken = window.localStorage.getItem("quickBooksToken");    
      
      if (quickBooksToken && quickBooksToken != undefined && quickBooksToken != "undefined" && quickBooksToken.length > 1) {
          quickBooksRealmId = quickBooksRealmId.replace(/\"/g,"");           
          await createRefundFromInput(invoiceInput, refundTypeFound, quickBooksRealmId, quickBooksToken);            
      }
  
  }
  
  const createInvoiceQuickbookInput = async (invoiceInput, item, invoiceTypeFound)=>{
  
    let orderState = item.OrderState;
    let orderZipCode = item.OrderZipCode;
    let orderCity = item.OrderCity;
    let orderCountry = item.OrderCountry;
    let orderFulfillmentChannel = item.FulfillmentChannel;
    let orderItem = item.OrderItem;
    // let orderItem = [];

    if (orderItem) {
      invoiceInput.ShipAddr = {
        City:orderCity,
        Country:orderCountry,
        PostalCode:orderZipCode,
        CountrySubDivisionCode:orderState,
  
    }
  
    if (orderFulfillmentChannel == "MFN") {
        invoiceInput.ShipFromAddr = {
            Line1:orderFulfillmentChannel,
            Line2:"",
            City:"Austin",
            CountrySubDivisionCode:"TX",
            PostalCode:"78731",
            Lat:"",
            Long:""                                
        }
    
    }else {
        invoiceInput.ShipFromAddr = {
            Line1:orderFulfillmentChannel,
            Line2:"",
            City:"",
            CountrySubDivisionCode:"",
            PostalCode:"",
            Lat:"",
            Long:""                                
        }
    
    }
  
    if (invoiceInput.skus) {
        for (let y = 0; y < invoiceInput.skus.length; y++) {
            let sku = invoiceInput.skus[y].sku;
            for (let x = 0; x < orderItem.length; x++) {
                let item = orderItem[x];
                if (item.SellerSKU == sku) {
                    invoiceInput.skus[y].asin = item.ASIN;                                            
                    invoiceInput.skus[y].title = item.Title;
                }
            }                                    
        }                                
    }
  
    invoiceInput.orderFulfillmentChannel = orderFulfillmentChannel;
  
    let quickBooksRealmId = window.localStorage.getItem("quickBooksRealmId");
    let quickBooksToken = window.localStorage.getItem("quickBooksToken");    
    
      if (quickBooksToken && quickBooksToken != undefined && quickBooksToken != "undefined" && quickBooksToken.length > 1) {
          quickBooksRealmId = quickBooksRealmId.replace(/\"/g,"");               
          let result = await createInvoiceFromInput(invoiceInput, invoiceTypeFound, quickBooksRealmId, quickBooksToken);
          return result;
      }

    }else {
      console.log("Did not find order items:", invoiceInput.id);
    }
  

  
  }
  
  const createOrder = async (orderInput, item) => {
  
    
    if (orderInput, item) {
  
        let order = GetDbItemObject(orderInput, "Order");
    
        // let orderItemArray = [];
        // for (let i = 0; i < result.result.length; i++) {
        //   let item = result.result[i];
          let orderItem = {
            ASIN: item.ASIN,
            IsGift: item.IsGift,
            IsTransparency: item.IsTransparency,
            ConditionId: item.ConditionId,
            ConditionSubtypeId: item.ConditionSubtypeId,
            ConditionNote: item.ConditionNote,
            OrderItemId: item.OrderItemId,
            GiftWrapPrice: item.GiftWrapPrice,
            GiftWrapTax: item.GiftWrapTax,
            ProductInfo: item.ProductInfo,
            TaxCollection: item.TaxCollection ? {
              ResponsibleParty: item.TaxCollection.ResponsibleParty,
              Model: item.TaxCollection.Model
            } : null,
            QuantityOrdered: item.QuantityOrdered,
            QuantityShipped: item.QuantityShipped,
            Title: item.Title,
            ShippingPrice: item.ShippingPrice,
            ShippingTax: item.ShippingTax,
            ShippingDiscount: item.ShippingDiscount,
            ShippingDiscountTax: item.ShippingDiscountTax,
            PromotionDiscount: item.PromotionDiscount,
            SellerSKU: item.SellerSKU,
            SerialNumberRequired: item.SerialNumberRequired,
            PromotionDiscountTax: item.PromotionDiscountTax,
            ItemPrice: item.ItemPrice ? {
              Amount: item.ItemPrice.Amount,
              CurrencyCode: item.ItemPrice.CurrencyCode
            } : null,
            ItemTax: item.ItemTax ? {
              Amount: item.ItemTax.Amount,
              CurrencyCode: item.ItemTax.CurrencyCode
            } : null,
            GiftMessageText: item.GiftMessageText,
          }
  
          if (item.PromotionIds) {
            let promotionIdsArray = [];
            for (let i = 0; i < item.PromotionIds.length; i++) {
              promotionIdsArray.push({ PromotionId: item.PromotionIds[i] })
            }
            orderItem['PromotionIds'] = promotionIdsArray;
          }
          
          let keys = Object.keys(orderItem);
          for (let i = 0; i < keys.length; i++) {
            if (orderItem[keys[i]]) {
              if (keys[i] == 'IsGift' || keys[i] == 'IsTransparency') {
                orderItem[keys[i]] = parseInt(orderItem[keys[i]]) >= 1 ? false : true;
              }
            } else {
              delete (orderItem[keys[i]])
            }
        }
        //   }
  
          
        //orderItemArray.push(orderItem);
  
        order.OrderItem = [orderItem];
  
        if (order.ShippingAddress) {                
            // Zip code and state for tax obligation calculations
            order['OrderZipCode'] = order.ShippingAddress.PostalCode ? order.ShippingAddress.PostalCode : "";
            order['OrderState'] = order.ShippingAddress.StateOrRegion ? order.ShippingAddress.StateOrRegion : "";
            order['OrderCountry'] = order.ShippingAddress.CountryCode ? order.ShippingAddress.CountryCode : "";
            order['OrderCity'] = order.ShippingAddress.City ? order.ShippingAddress.City : "";
          } 
  
        
        return order;
    }
  
    return null;
  }
  
  const createQuickbooksInvoiceFromCreatedOrder = async(invoiceInput, itemOrder) => {
    let result =  await createInvoiceQuickbookInput(invoiceInput, itemOrder, false );
    return result;
  }
  
  const createInvoiceFromInput = async (invoiceInput, invoiceTypeFound, quickBooksRealmId, quickBooksToken)=>{
  
    
    let result = await API.get("LambdaAPIUser","/app/createInvoice?token=" + quickBooksToken + "&realmId="+quickBooksRealmId+"&invoice="+encodeURIComponent(JSON.stringify(invoiceInput))).then(async response => {
  
        if (response.Error) {
            let errorJson = JSON.parse(response.Error)
            sendAlertMessage("There was an issue creating the invoice " + invoiceInput.id );
            return {Error:'Unauthenticated'};
        } else {
                    
            if (response.body) {
                let invoiceBody = JSON.parse(response.body);
                
                if (invoiceBody) {
                    let invoice = invoiceBody.Invoice;
                    if (invoice) {
  
                            delete(invoiceInput.ShipAddr);
                            delete(invoiceInput.ShipFromAddr);
  
                            if (invoiceTypeFound) {         
  
                                await API.graphql(graphqlOperation(mutations.updateUserItem, {input: 
                                    {
                                        owner:user.sub,
                                        ...invoiceInput,
                                        type:"Invoice",
                                        id:invoiceInput.id,
                                        invoiceId:invoice.Id,
                                        createdAt:moment(invoiceInput.postedDateTime.replace(" UTC", "")).toISOString(),
                                    }})).then(result=>{               
                                      
                                      }
                                        ).catch(error => {
                                        console.log("ERROR");
                                        console.log(error);
                                });
                            }else {
  
                                await API.graphql(graphqlOperation(mutations.createUserItem, {input: 
                                    {
                                        owner:user.sub,
                                        ...invoiceInput,
                                        type:"Invoice",
                                        id:invoiceInput.id,
                                        invoiceId:invoice.Id,
                                        createdAt:moment(invoiceInput.postedDateTime.replace(" UTC", "")).toISOString(),
                                    }})).then(result=>{               
                                      
                                      }
                                        ).catch(error => {
                                        console.log("ERROR");
                                        console.log(error);
                                });
                            }                    
                    }
                }
            }
        }
  
        if (response.error) {
  
            if (response.error && response.error.error == "Unauthorized") {
                // getAccessToken();    
            }
            if (response.error && response.error.error == "Forbidden") {
                // getAccessToken();    
            }
        }
    }).catch(e=>{
        console.log("ERROR FOUND:",e);            
    });
    
      return result;
    }
  
    const createRefundFromInput = async (invoiceInput, refundTypeFound,  quickBooksRealmId, quickBooksToken)=>{
  
    
    await API.get("LambdaAPIUser","/app/createRefund?token=" + quickBooksToken + "&realmId="+quickBooksRealmId+"&invoice="+encodeURIComponent(JSON.stringify(invoiceInput))).then(async response => {
  
        if (response.Error) {
            sendAlertMessage("There was an issue creating the invoice", invoiceInput.id);
            return {Error:'Unauthenticated'}
        } else {
                    
            if (response.body) {
                let invoiceBody = JSON.parse(response.body);
                
                if (invoiceBody) {
                    let invoice = invoiceBody.Invoice;
                    if (invoice) {
  
                            delete(invoiceInput.ShipAddr);
                            delete(invoiceInput.ShipFromAddr);
  
                            if (refundTypeFound) {         
  
                                await API.graphql(graphqlOperation(mutations.updateUserItem, {input: 
                                    {
                                        owner:user.sub,
                                        ...invoiceInput,
                                        type:"Refund",
                                        id:invoiceInput.id,
                                        invoiceId:invoice.Id,
                                        createdAt:moment(invoiceInput.postedDateTime.replace(" UTC", "")).toISOString(),
                                    }})).then(result=>{               
                                        
                                    }).catch(error => {
                                        console.log("ERROR");
                                        console.log(error);
                                });
  
                            }else {
  
                                await API.graphql(graphqlOperation(mutations.createUserItem, {input: 
                                    {
                                        owner:user.sub,
                                        ...invoiceInput,
                                        type:"Refund",
                                        id:invoiceInput.id,
                                        invoiceId:invoice.Id,
                                        createdAt:moment(invoiceInput.postedDateTime.replace(" UTC", "")).toISOString(),
                                    }})).then(result=>{               

                                      }
                                        ).catch(error => {
                                        console.log("ERROR");
                                        console.log(error);
                                });
                            }                    
                    }
                }
            }
        }
  
        if (response.error) {
  
            if (response.error && response.error.error == "Unauthorized") {
                // getAccessToken();    
            }
            if (response.error && response.error.error == "Forbidden") {
                // getAccessToken();    
            }
        }
    }).catch(e=>{
        console.log("ERROR FOUND:",e);            
    });
    
    }
  
    let result = await API.graphql(graphqlOperation(itemsByOwnerId, {id:invoiceInput.id})).then(async response=>{ 
  
        if (response.data.itemsByOwnerId) {
  
            let orderTypeFound = false;
            let invoiceTypeFound = false;
            let refundTypeFound = false;
            let invoiceId = 0;
            let itemOrder = null;
  
  
            for (let i = 0; i < response.data.itemsByOwnerId.items.length; i++) {
                let item = response.data.itemsByOwnerId.items[i];                    
                if (item.type == "Order") {
                    orderTypeFound = true;
                    itemOrder = item;
                }
                if (item.type == "Invoice") {
                    invoiceTypeFound = true;
                    invoiceId = item.invoiceId
                }
                if (item.type == "Refund") {
                    refundTypeFound = true;
                    invoiceId = item.orderId;
                }
            }
  
            if (orderTypeFound) {
                
                if (isRefund) {
                    await createRefundQuickbookInput(invoiceInput, itemOrder, refundTypeFound );
                }else {
                  if (!invoiceTypeFound) {
                    let createResult =  await createInvoiceQuickbookInput(invoiceInput, itemOrder, invoiceTypeFound );
                    return createResult;
                  } else {
                    //console.log("Invoice Found:", invoiceId);
                  }                    
                }
            } else {
  
                let postMsg = {
                    body: {
                        MWSAuthToken:"amzn.mws.8aaffafc-261b-087-b310-8dd98eeec4c5",
                        SellerId:"A3LW268EJNMT20",
                        AmazonOrderId:invoiceInput.id
                    }
                }
  
                
                await delay(1000);

                const result = await API.post("LambdaAPIUser", "/app/GetOrder", postMsg).then(async response => {
  
                    if (response.ErrorResponse) {
                        console.log("get order response:", response.ErrorResponse); 
                        if (response.ErrorResponse.Error) {
                          if (response.ErrorResponse.Error.Code == "RequestThrottled") {
                            await delay(240000);
                          }
                        }
                    } else {
  
                        if (response.GetOrderResponse && response.GetOrderResponse.GetOrderResult && response.GetOrderResponse.GetOrderResult.Orders && response.GetOrderResponse.GetOrderResult.Orders.Order) {
  
                            const resultListOrders = await API.post("LambdaAPIUser", "/app/ListOrderItems", postMsg).then(async responseListOrder => {
    
                                if (responseListOrder.ListOrderItemsResponse && responseListOrder.ListOrderItemsResponse.ListOrderItemsResult && responseListOrder.ListOrderItemsResponse.ListOrderItemsResult.OrderItems)
                                {
                                    let orderInput = await createOrder(response.GetOrderResponse.GetOrderResult.Orders.Order, responseListOrder.ListOrderItemsResponse.ListOrderItemsResult.OrderItems.OrderItem)
                                    
                                    return await API.graphql(graphqlOperation(mutations.createUserItem, {input: 
                                        {
                                            ...orderInput,
                                            owner:user.sub,
                                            type:"Order"
                                        }})).then(async responseResult =>{               
                                            
                                            if (isRefund) {
                                              let resultRefund = await createQuickbooksInvoiceFromCreatedOrder(invoiceInput, responseResult.data.createUserItem);
                                              return resultRefund;
                                            }else {
                                                let resultInvoice = await createQuickbooksInvoiceFromCreatedOrder(invoiceInput, responseResult.data.createUserItem);
                                                return resultInvoice;
                                            }
  
                                            }).catch(error => {
                                            console.log("ERROR creating order");
                                            console.log(error);
                                    });
  
                                }
                                return resultListOrders;

                            }).catch(e=>{
                                console.log("Error:", e);
                            })
                        }
                        
  
                    }
                }).catch(e =>{
                    console.log(e);
                });

                return result;
            }  
        
         }      
        
        return response;

    }).catch(e=>{
        console.log("There was an error", e);
    })
  
    return result;
  
  }

  let result = await createInvoice(invoiceInput, isRefund, user, sendAlertMessage);
  return result;
}


export const CheckInvoiceExists = async (currentInvoices, sendAlertMessage)=> {
  //Check invoices for existing quickbooks invoices

  let invoiceIds = Object.keys(currentInvoices);

  let groups = GetGroupsOfItems(invoiceIds, 30);
  for (let i = 0; i < groups.length; i++) {
  
    //console.log("Start group:", i+1);
    let ids = groups[i];
    let quickBooksRealmId = window.localStorage.getItem("quickBooksRealmId");
    let quickBooksToken = window.localStorage.getItem("quickBooksToken");    
    let result = await API.get("LambdaAPIUser","/app/batchQueryForInvoice?token=" + quickBooksToken + "&realmId=" + quickBooksRealmId + "&ids=" + JSON.stringify(ids)).then(async response => {
        if (response.error) {              
            if (sendAlertMessage) {
              sendAlertMessage(response.error)
            }
        } else {
            // console.log("batchQueryForInvoice response:", response); 

          for (let y = 0; y < response.length; y++) {
              let queryResponse = response[y];
              let id = queryResponse["bId"];
              
              let invoiceExists = queryResponse && queryResponse.QueryResponse && queryResponse.QueryResponse.Invoice ? true : false;
              
              if (invoiceExists) {
                for (let x = 0; x < invoiceIds.length; x++) {
                  let invoice = currentInvoices[invoiceIds[x]];
                  // console.log("Invoice - ", invoice);
                    if (invoice && invoice[0] && invoice[0].orderId == id) {
                      //console.log("Found match - ", invoice[0].orderId)
                      currentInvoices[id].push({hasQuickbooksInvoice:true, qbInvoice:queryResponse.QueryResponse.Invoice});
                    }
                }
              }
          }
        }
        return response;
    }).catch(e =>{console.log(e); return null;});
    }
  }




