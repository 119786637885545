
import React, { Component } from "react";
import { Icon, Flag } from 'semantic-ui-react';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import moment from 'moment-timezone';

import { AmplifyAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';
import { styled } from '@material-ui/styles';

const MyButton = styled(Button)({
    backgroundColor:"#2185d0", 
    "&:hover": {
        //you want this to be the same as the backgroundColor above
        backgroundColor: "#2185d0"
    }
});


export default class AccountButton extends Component {

  constructor(props) {
    super(props);      
    this.state = {
        isMenuOpen : false,
        isMessagesOpen : false
    }

    moment.updateLocale('en', {
        relativeTime: {
          future: 'in %s',
          past: '%s ago',
          s: 'a few seconds',
          ss: '%d seconds',
          m: '1 m',
          mm: '%d minutes',
          h: '1 h',
          hh: '%d hours',
          d: '1 d',
          dd: '%d days',
          M: '1 month',
          MM: '%d months',
          y: '1 y',
          yy: '%d years'
        }
      });
      
    this.menuTarget = null;
    this.messagesTarget = null;
    
    this.userSetting = props.userSetting;
    this.handleChange = this.handleChange.bind(this);
    this.handleMessagesClose = this.handleMessagesClose.bind(this);
    this.updateUserSetting = this.updateUserSetting.bind(this);

    console.log(this.userSetting);

}

      
  getRelativeTime(date) {
    const d = new Date(date);
    return moment(d).fromNow();
  }


    handleChange(event, {name, value}) { 
        this.setState({ [name]: value });
        this.props.searchTerm.term = value;
      }

      handleMenu = event => {
        if (this.state.isMenuOpen) {
          this.setState({isMenuOpen:false});  
          this.menuTarget = null;
        } else {
          this.setState({isMenuOpen:true});  
          this.menuTarget = event.currentTarget;
        }    
      };
      
      handleMessages = event => {
        
        if (this.state.isMessagesOpen) {
          this.setState({isMessagesOpen:false});  
          this.messagesTarget = null;
        } else {
          this.setState({isMessagesOpen:true});  
          this.messagesTarget = event.currentTarget;
        }    
      };


      handleClose = event => {
        this.menuTarget = null;
        this.setState({isMenuOpen:false});  
      };

      handleMessagesClose = event => {
        this.messagesTarget = null;
        this.setState({isMessagesOpen:false});  
      };
      
updateUserSetting(userSetting) {
    this.userSetting = userSetting;
    this.setState({dirty:!this.state.dirty});
}
      
    render() {
        return(       
            <>
          <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={this.handleMessages}
                color="white"                
              >
                <Icon style={{fontSize:"0.8em", color:"white"}} className="bell "/>
          </IconButton>

          <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={this.handleMenu}
                color="inherit"
              >
              <AccountCircle />    

          </IconButton>
            
            <div>
            <Popover 
            style={{zIndex:1500}}
            open={this.state.isMenuOpen}
            anchorEl={this.menuTarget}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            onClose={this.handleClose}
            >

<div>
  
  <div style={{minWidth:"300px", padding:"20px"}}>

  <div style={{textAlign:"center" , paddingBottom:"20px"}}>
  
    <img src="images/icons/account_icon.svg" style={{height:"60px", width:"60px"}}/>
    <p className="smallBoldText" style={{textAlign:"center"}}><span>{this.userSetting ? this.userSetting.email : ""}</span></p>

  </div>


  <p className="smallBoldText">Marketplace <span className="smallBoldTextMinor"><Flag name="us"/></span></p>
  <p className="smallBoldText">Active Listings <span className="smallBoldTextMinor">{this.userSetting ? this.userSetting.totalListingsFBAActive : ""}</span></p>
  <p className="smallBoldText">Total Listings <span className="smallBoldTextMinor">{this.userSetting ? this.userSetting.totalListings : ""}</span></p>
  <p className="smallBoldText">Total Products <span className="smallBoldTextMinor">{this.userSetting ? this.userSetting.totalProducts : ""}</span></p>
  <p className="smallBoldText">Last Updated  
  <span className="smallBoldTextMinor">{this.userSetting ? this.getRelativeTime(this.userSetting.lastCheckedAllListingsTime) : ""}</span>
    </p>
    <p className="smallBoldText">Time Zone  
  <span className="smallBoldTextMinor">{moment.tz.guess()}</span>
    </p>

    <p>{React.version}</p>
    
  <div style={{textAlign:"center", paddingTop:"30px"}}>

    <AmplifyAuthenticator>
      <AmplifySignOut buttonText="Sign Out"></AmplifySignOut>
  </AmplifyAuthenticator>
                    
    </div>

  </div>

  </div>

</Popover>


<Popover 
    
    style={{zIndex:1500}}
            open={this.state.isMessagesOpen}
            anchorEl={this.messagesTarget}
  anchorOrigin={{
    vertical: 'top',
    horizontal: 'left',
  }}
  transformOrigin={{
    vertical: 'top',
    horizontal: 'right',
  }}
  onClose={this.handleMessagesClose}
>

<div style={{padding:"40px"}}>

<p>0 Alerts</p>
</div>

</Popover>
                
       </div>
       </>
        );
      }
}
