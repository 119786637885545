/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUserItem = /* GraphQL */ `
  query GetUserItem(
    $owner: String!
    $type: String!
    $createdAt: AWSDateTime!
    $id: ID!
  ) {
    getUserItem(owner: $owner, type: $type, createdAt: $createdAt, id: $id) {
      owner
      type
      id
      createdAt
      updatedAt
      lastChecked
      mwsAuthToken
      sellerId
      phone
      name
      messageType
      message
      status
      asin
      parentAsin
      content
      binding
      brand
      shippingWeight
      shippingLength
      shippingWidth
      shippingHeight
      shippingZip
      rating
      ranking
      salesRank {
        ProductCategoryId
        Rank
      }
      imageURL
      images {
        url
        alt
        title
        fileName
        source
      }
      description
      upc
      suppliers {
        name
        id
        url
        productCheck
        inStock
        buyBox
        buyBoxLastChecked
        buyBoxVenderName
        price
        lastChecked
        subscribed
        lastNotified
        sku
        dpci
        tcin
        lowestPricedOffers
        lowestPricedOffersLastChecked
        searchTerms
        ebayLastChecked
        ebayPrice
      }
      supressed
      fnsku
      condition
      listingName
      price
      minPrice
      maxPrice
      mfnListingExists
      mfnFulfillableQuantity
      afnListingExists
      afnWarehouseQuantity
      afnFulfillableQuantity
      afnUnsellableQuantity
      afnReservedQuantity
      afnTotalQuantity
      perUnitVolume
      afnInboundWorkingQuantity
      afnInboundShippedQuantity
      afnInboundReceivingQuantity
      afnResearchingQuantity
      afnReservedFutureSupply
      afnFutureSupplyBuyable
      listingId
      quantity
      openDate
      itemIsMarketplace
      productIdType
      productId
      itemNote
      itemCondition
      asin1
      asin2
      asin3
      willShipInternationally
      expeditedShipping
      bidForFeaturedPlacement
      addDelete
      pendingQuantity
      fulfillmentChannel
      merchantShippingGroup
      fee
      feeDetail
      feeUpdated
      priceHistoryFile {
        bucket
        region
        key
      }
      offerHistoryFile {
        bucket
        region
        key
      }
      repricingEnabled
      repricingStrategy
      activeSupplier
      activeDatePurchased
      activeCostPerUnit
      fbaStorage {
        asin
        fnsku
        fulfillmentCenter
        countryCode
        longestSide
        medianSide
        shortestSide
        measurementUnits
        weight
        weightUnits
        itemVolume
        volumeUnits
        productSizeTier
        averageQuantityOnHand
        averageQuantityPendingRemoval
        estimatedTotalItemVolume
        monthOfCharge
        storageRate
        currency
        estimatedMonthlyStorageFee
        dangerousGoodsStorageType
        eligibleForInventoryDiscount
        qualifiesForInventoryDiscount
      }
      salesHistory {
        last3DaysSales
        last7DaysSales
        last15DaysSales
        last30DaysSales
        last60DaysSales
        last90DaysSales
      }
      conditionString
      totalSupplyQuantity
      inStockSupplyQuantity
      earliestAvailability
      supplyDetail {
        quantity
        supplyType
        earliestAvailableToPick
        latestAvailableToPick
      }
      restockReport {
        Country
        ProductName
        FNSKU
        MerchantSKU
        ASIN
        Condition
        Supplier
        SupplierPartNo
        CurrencyCode
        Price
        SalesLast30Days
        UnitsSoldLast30Days
        TotalUnits
        Inbound
        Available
        FCTransfer
        FCProcessing
        CustomerOrder
        Unfulfillable
        FulfilledBy
        DaysOfSupply
        Alert
        RecommendedReplenQty
        RecommendedShipDate
        InventoryLevelThresholdCurrentMonth
        CurrentMonthVeryLowInvenThreshold
        CurrentMonthMinimumInvenThreshold
        CurrentMonthMaximumInvenThreshold
        CurrentMonthVeryHighInvenThreshold
        InventoryLevelThresholdPublishedNextMonth
        NextMonthVeryLowInvenThreshold
        NextMonthMinimumInvenThreshold
        NextMonthMaximumInvenThreshold
        NextMonthVeryHighInvenThreshold
      }
      AmazonOrderId
      PurchaseDate
      EarliestShipDate
      FulfillmentChannel
      IsFinancialEvent
      IsBusinessOrder
      IsGlobalExpressEnabled
      IsPremiumOrder
      IsPrime
      IsReplacementOrder
      IsSoldByAB
      LastUpdateDate
      LatestShipDate
      MarketplaceId
      NumberOfItemsShipped
      NumberOfItemsUnshipped
      OrderItem {
        ASIN
        IsGift
        IsTransparency
        ConditionId
        ConditionSubtypeId
        ConditionNote
        OrderItemId
        GiftWrapPrice
        GiftWrapTax
        ProductInfo {
          NumberOfItems
        }
        TaxCollection {
          ResponsibleParty
          Model
        }
        QuantityOrdered
        QuantityShipped
        Title
        ShippingPrice {
          Amount
          CurrencyCode
        }
        ShippingTax {
          Amount
          CurrencyCode
        }
        ShippingDiscount {
          Amount
          CurrencyCode
        }
        ShippingDiscountTax {
          Amount
          CurrencyCode
        }
        PromotionDiscount {
          Amount
          CurrencyCode
        }
        SellerSKU
        SerialNumberRequired
        PromotionDiscountTax {
          Amount
          CurrencyCode
        }
        ItemPrice {
          Amount
          CurrencyCode
        }
        ItemTax {
          Amount
          CurrencyCode
        }
        GiftMessageText
        PromotionIds {
          PromotionId
        }
      }
      OrderStatus
      OrderTotal {
        Amount
        CurrencyCode
      }
      OrderType
      PaymentMethod
      PaymentMethodDetails {
        PaymentMethodDetail
      }
      SalesChannel
      SellerOrderId
      ShipServiceLevel
      ShipmentServiceLevelCategory
      ShippingAddress {
        AddressLine1
        AddressLine2
        AddressLine3
        City
        CountryCode
        County
        District
        Name
        Municipality
        PostalCode
        StateOrRegion
        isAddressSharingConfidential
      }
      Marketplace
      EstimatedShippingCostUPSData
      OrderZipCode
      OrderState
      OrderCity
      OrderCountry
      settlementId
      settlementStartDate
      settlementEndDate
      depositDate
      totalAmount
      currency
      transactionType
      orderId
      merchantOrderId
      adjustmentId
      shipmentId
      marketplaceName
      amountType
      amountDescription
      amount
      fulfillmentId
      postedDate
      postedDateTime
      orderItemCode
      merchantOrderItemId
      merchantAdjustmentItemId
      sku
      quantityPurchased
      promotionId
      invoiceId
      invoiceSyncToken
      hasQuickbooksInvoice
      amazonClosingFee
      amazonReferralFee
      fbaFees
      fbaFulfillmentFee
      giftWrap
      giftWrapChargeback
      giftWrapCommission
      itemTax
      itemTaxGiftwrap
      itemTaxShipping
      itemTaxWithheld
      itemTaxWithheldOther
      itemTaxWithheldShipping
      orderFulfillmentChannel
      productCharge
      promotionCharge
      shippingHB
      shippingCharge
      shippingChargeback
      skus {
        asin
        title
        sku
        qty
        chargeAmount
      }
      totalFees
      totalPromotionShipping
      totalRefundCommission
      totalRefundExpenses
      totalRefundSales
      totalRevenue
    }
  }
`;
export const listUserItems = /* GraphQL */ `
  query ListUserItems(
    $owner: String
    $typeCreatedAtId: ModelUserItemPrimaryCompositeKeyConditionInput
    $filter: ModelUserItemFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserItems(
      owner: $owner
      typeCreatedAtId: $typeCreatedAtId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        owner
        type
        id
        createdAt
        updatedAt
        lastChecked
        mwsAuthToken
        sellerId
        phone
        name
        messageType
        message
        status
        asin
        parentAsin
        content
        binding
        brand
        shippingWeight
        shippingLength
        shippingWidth
        shippingHeight
        shippingZip
        rating
        ranking
        salesRank {
          ProductCategoryId
          Rank
        }
        imageURL
        images {
          url
          alt
          title
          fileName
          source
        }
        description
        upc
        suppliers {
          name
          id
          url
          productCheck
          inStock
          buyBox
          buyBoxLastChecked
          buyBoxVenderName
          price
          lastChecked
          subscribed
          lastNotified
          sku
          dpci
          tcin
          lowestPricedOffers
          lowestPricedOffersLastChecked
          searchTerms
          ebayLastChecked
          ebayPrice
        }
        supressed
        fnsku
        condition
        listingName
        price
        minPrice
        maxPrice
        mfnListingExists
        mfnFulfillableQuantity
        afnListingExists
        afnWarehouseQuantity
        afnFulfillableQuantity
        afnUnsellableQuantity
        afnReservedQuantity
        afnTotalQuantity
        perUnitVolume
        afnInboundWorkingQuantity
        afnInboundShippedQuantity
        afnInboundReceivingQuantity
        afnResearchingQuantity
        afnReservedFutureSupply
        afnFutureSupplyBuyable
        listingId
        quantity
        openDate
        itemIsMarketplace
        productIdType
        productId
        itemNote
        itemCondition
        asin1
        asin2
        asin3
        willShipInternationally
        expeditedShipping
        bidForFeaturedPlacement
        addDelete
        pendingQuantity
        fulfillmentChannel
        merchantShippingGroup
        fee
        feeDetail
        feeUpdated
        priceHistoryFile {
          bucket
          region
          key
        }
        offerHistoryFile {
          bucket
          region
          key
        }
        repricingEnabled
        repricingStrategy
        activeSupplier
        activeDatePurchased
        activeCostPerUnit
        fbaStorage {
          asin
          fnsku
          fulfillmentCenter
          countryCode
          longestSide
          medianSide
          shortestSide
          measurementUnits
          weight
          weightUnits
          itemVolume
          volumeUnits
          productSizeTier
          averageQuantityOnHand
          averageQuantityPendingRemoval
          estimatedTotalItemVolume
          monthOfCharge
          storageRate
          currency
          estimatedMonthlyStorageFee
          dangerousGoodsStorageType
          eligibleForInventoryDiscount
          qualifiesForInventoryDiscount
        }
        salesHistory {
          last3DaysSales
          last7DaysSales
          last15DaysSales
          last30DaysSales
          last60DaysSales
          last90DaysSales
        }
        conditionString
        totalSupplyQuantity
        inStockSupplyQuantity
        earliestAvailability
        supplyDetail {
          quantity
          supplyType
          earliestAvailableToPick
          latestAvailableToPick
        }
        restockReport {
          Country
          ProductName
          FNSKU
          MerchantSKU
          ASIN
          Condition
          Supplier
          SupplierPartNo
          CurrencyCode
          Price
          SalesLast30Days
          UnitsSoldLast30Days
          TotalUnits
          Inbound
          Available
          FCTransfer
          FCProcessing
          CustomerOrder
          Unfulfillable
          FulfilledBy
          DaysOfSupply
          Alert
          RecommendedReplenQty
          RecommendedShipDate
          InventoryLevelThresholdCurrentMonth
          CurrentMonthVeryLowInvenThreshold
          CurrentMonthMinimumInvenThreshold
          CurrentMonthMaximumInvenThreshold
          CurrentMonthVeryHighInvenThreshold
          InventoryLevelThresholdPublishedNextMonth
          NextMonthVeryLowInvenThreshold
          NextMonthMinimumInvenThreshold
          NextMonthMaximumInvenThreshold
          NextMonthVeryHighInvenThreshold
        }
        AmazonOrderId
        PurchaseDate
        EarliestShipDate
        FulfillmentChannel
        IsFinancialEvent
        IsBusinessOrder
        IsGlobalExpressEnabled
        IsPremiumOrder
        IsPrime
        IsReplacementOrder
        IsSoldByAB
        LastUpdateDate
        LatestShipDate
        MarketplaceId
        NumberOfItemsShipped
        NumberOfItemsUnshipped
        OrderItem {
          ASIN
          IsGift
          IsTransparency
          ConditionId
          ConditionSubtypeId
          ConditionNote
          OrderItemId
          GiftWrapPrice
          GiftWrapTax
          QuantityOrdered
          QuantityShipped
          Title
          SellerSKU
          SerialNumberRequired
          GiftMessageText
        }
        OrderStatus
        OrderTotal {
          Amount
          CurrencyCode
        }
        OrderType
        PaymentMethod
        PaymentMethodDetails {
          PaymentMethodDetail
        }
        SalesChannel
        SellerOrderId
        ShipServiceLevel
        ShipmentServiceLevelCategory
        ShippingAddress {
          AddressLine1
          AddressLine2
          AddressLine3
          City
          CountryCode
          County
          District
          Name
          Municipality
          PostalCode
          StateOrRegion
          isAddressSharingConfidential
        }
        Marketplace
        EstimatedShippingCostUPSData
        OrderZipCode
        OrderState
        OrderCity
        OrderCountry
        settlementId
        settlementStartDate
        settlementEndDate
        depositDate
        totalAmount
        currency
        transactionType
        orderId
        merchantOrderId
        adjustmentId
        shipmentId
        marketplaceName
        amountType
        amountDescription
        amount
        fulfillmentId
        postedDate
        postedDateTime
        orderItemCode
        merchantOrderItemId
        merchantAdjustmentItemId
        sku
        quantityPurchased
        promotionId
        invoiceId
        invoiceSyncToken
        hasQuickbooksInvoice
        amazonClosingFee
        amazonReferralFee
        fbaFees
        fbaFulfillmentFee
        giftWrap
        giftWrapChargeback
        giftWrapCommission
        itemTax
        itemTaxGiftwrap
        itemTaxShipping
        itemTaxWithheld
        itemTaxWithheldOther
        itemTaxWithheldShipping
        orderFulfillmentChannel
        productCharge
        promotionCharge
        shippingHB
        shippingCharge
        shippingChargeback
        skus {
          asin
          title
          sku
          qty
          chargeAmount
        }
        totalFees
        totalPromotionShipping
        totalRefundCommission
        totalRefundExpenses
        totalRefundSales
        totalRevenue
      }
      nextToken
    }
  }
`;
export const listingsByOwnerType = /* GraphQL */ `
  query ListingsByOwnerType(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listingsByOwnerType(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        owner
        type
        id
        createdAt
        updatedAt
        lastChecked
        mwsAuthToken
        sellerId
        phone
        name
        messageType
        message
        status
        asin
        parentAsin
        content
        binding
        brand
        shippingWeight
        shippingLength
        shippingWidth
        shippingHeight
        shippingZip
        rating
        ranking
        salesRank {
          ProductCategoryId
          Rank
        }
        imageURL
        images {
          url
          alt
          title
          fileName
          source
        }
        description
        upc
        suppliers {
          name
          id
          url
          productCheck
          inStock
          buyBox
          buyBoxLastChecked
          buyBoxVenderName
          price
          lastChecked
          subscribed
          lastNotified
          sku
          dpci
          tcin
          lowestPricedOffers
          lowestPricedOffersLastChecked
          searchTerms
          ebayLastChecked
          ebayPrice
        }
        supressed
        fnsku
        condition
        listingName
        price
        minPrice
        maxPrice
        mfnListingExists
        mfnFulfillableQuantity
        afnListingExists
        afnWarehouseQuantity
        afnFulfillableQuantity
        afnUnsellableQuantity
        afnReservedQuantity
        afnTotalQuantity
        perUnitVolume
        afnInboundWorkingQuantity
        afnInboundShippedQuantity
        afnInboundReceivingQuantity
        afnResearchingQuantity
        afnReservedFutureSupply
        afnFutureSupplyBuyable
        listingId
        quantity
        openDate
        itemIsMarketplace
        productIdType
        productId
        itemNote
        itemCondition
        asin1
        asin2
        asin3
        willShipInternationally
        expeditedShipping
        bidForFeaturedPlacement
        addDelete
        pendingQuantity
        fulfillmentChannel
        merchantShippingGroup
        fee
        feeDetail
        feeUpdated
        priceHistoryFile {
          bucket
          region
          key
        }
        offerHistoryFile {
          bucket
          region
          key
        }
        repricingEnabled
        repricingStrategy
        activeSupplier
        activeDatePurchased
        activeCostPerUnit
        fbaStorage {
          asin
          fnsku
          fulfillmentCenter
          countryCode
          longestSide
          medianSide
          shortestSide
          measurementUnits
          weight
          weightUnits
          itemVolume
          volumeUnits
          productSizeTier
          averageQuantityOnHand
          averageQuantityPendingRemoval
          estimatedTotalItemVolume
          monthOfCharge
          storageRate
          currency
          estimatedMonthlyStorageFee
          dangerousGoodsStorageType
          eligibleForInventoryDiscount
          qualifiesForInventoryDiscount
        }
        salesHistory {
          last3DaysSales
          last7DaysSales
          last15DaysSales
          last30DaysSales
          last60DaysSales
          last90DaysSales
        }
        conditionString
        totalSupplyQuantity
        inStockSupplyQuantity
        earliestAvailability
        supplyDetail {
          quantity
          supplyType
          earliestAvailableToPick
          latestAvailableToPick
        }
        restockReport {
          Country
          ProductName
          FNSKU
          MerchantSKU
          ASIN
          Condition
          Supplier
          SupplierPartNo
          CurrencyCode
          Price
          SalesLast30Days
          UnitsSoldLast30Days
          TotalUnits
          Inbound
          Available
          FCTransfer
          FCProcessing
          CustomerOrder
          Unfulfillable
          FulfilledBy
          DaysOfSupply
          Alert
          RecommendedReplenQty
          RecommendedShipDate
          InventoryLevelThresholdCurrentMonth
          CurrentMonthVeryLowInvenThreshold
          CurrentMonthMinimumInvenThreshold
          CurrentMonthMaximumInvenThreshold
          CurrentMonthVeryHighInvenThreshold
          InventoryLevelThresholdPublishedNextMonth
          NextMonthVeryLowInvenThreshold
          NextMonthMinimumInvenThreshold
          NextMonthMaximumInvenThreshold
          NextMonthVeryHighInvenThreshold
        }
        AmazonOrderId
        PurchaseDate
        EarliestShipDate
        FulfillmentChannel
        IsFinancialEvent
        IsBusinessOrder
        IsGlobalExpressEnabled
        IsPremiumOrder
        IsPrime
        IsReplacementOrder
        IsSoldByAB
        LastUpdateDate
        LatestShipDate
        MarketplaceId
        NumberOfItemsShipped
        NumberOfItemsUnshipped
        OrderItem {
          ItemPrice {
            Amount
            CurrencyCode
          }
          ASIN
          IsGift
          IsTransparency
          ConditionId
          ConditionSubtypeId
          ConditionNote
          OrderItemId
          GiftWrapPrice
          GiftWrapTax
          QuantityOrdered
          QuantityShipped
          Title
          SellerSKU
          SerialNumberRequired
          GiftMessageText
        }
        OrderStatus
        OrderTotal {
          Amount
          CurrencyCode
        }
        OrderType
        PaymentMethod
        PaymentMethodDetails {
          PaymentMethodDetail
        }
        SalesChannel
        SellerOrderId
        ShipServiceLevel
        ShipmentServiceLevelCategory
        ShippingAddress {
          AddressLine1
          AddressLine2
          AddressLine3
          City
          CountryCode
          County
          District
          Name
          Municipality
          PostalCode
          StateOrRegion
          isAddressSharingConfidential
        }
        Marketplace
        EstimatedShippingCostUPSData
        OrderZipCode
        OrderState
        OrderCity
        OrderCountry
        settlementId
        settlementStartDate
        settlementEndDate
        depositDate
        totalAmount
        currency
        transactionType
        orderId
        merchantOrderId
        adjustmentId
        shipmentId
        marketplaceName
        amountType
        amountDescription
        amount
        fulfillmentId
        postedDate
        postedDateTime
        orderItemCode
        merchantOrderItemId
        merchantAdjustmentItemId
        sku
        quantityPurchased
        promotionId
        invoiceId
        invoiceSyncToken
        hasQuickbooksInvoice
        amazonClosingFee
        amazonReferralFee
        fbaFees
        fbaFulfillmentFee
        giftWrap
        giftWrapChargeback
        giftWrapCommission
        itemTax
        itemTaxGiftwrap
        itemTaxShipping
        itemTaxWithheld
        itemTaxWithheldOther
        itemTaxWithheldShipping
        orderFulfillmentChannel
        productCharge
        promotionCharge
        shippingHB
        shippingCharge
        shippingChargeback
        skus {
          asin
          title
          sku
          qty
          chargeAmount
        }
        totalFees
        totalPromotionShipping
        totalRefundCommission
        totalRefundExpenses
        totalRefundSales
        totalRevenue
      }
      nextToken
    }
  }
`;
export const itemsByOwnerId = /* GraphQL */ `
  query ItemsByOwnerId(
    $id: ID
    $sortDirection: ModelSortDirection
    $filter: ModelUserItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemsByOwnerId(
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        owner
        type
        id
        createdAt
        updatedAt
        lastChecked
        mwsAuthToken
        sellerId
        phone
        name
        messageType
        message
        status
        asin
        parentAsin
        content
        binding
        brand
        shippingWeight
        shippingLength
        shippingWidth
        shippingHeight
        shippingZip
        rating
        ranking
        salesRank {
          ProductCategoryId
          Rank
        }
        imageURL
        images {
          url
          alt
          title
          fileName
          source
        }
        description
        upc
        suppliers {
          name
          id
          url
          productCheck
          inStock
          buyBox
          buyBoxLastChecked
          buyBoxVenderName
          price
          lastChecked
          subscribed
          lastNotified
          sku
          dpci
          tcin
          lowestPricedOffers
          lowestPricedOffersLastChecked
          searchTerms
          ebayLastChecked
          ebayPrice
        }
        supressed
        fnsku
        condition
        listingName
        price
        minPrice
        maxPrice
        mfnListingExists
        mfnFulfillableQuantity
        afnListingExists
        afnWarehouseQuantity
        afnFulfillableQuantity
        afnUnsellableQuantity
        afnReservedQuantity
        afnTotalQuantity
        perUnitVolume
        afnInboundWorkingQuantity
        afnInboundShippedQuantity
        afnInboundReceivingQuantity
        afnResearchingQuantity
        afnReservedFutureSupply
        afnFutureSupplyBuyable
        listingId
        quantity
        openDate
        itemIsMarketplace
        productIdType
        productId
        itemNote
        itemCondition
        asin1
        asin2
        asin3
        willShipInternationally
        expeditedShipping
        bidForFeaturedPlacement
        addDelete
        pendingQuantity
        fulfillmentChannel
        merchantShippingGroup
        fee
        feeDetail
        feeUpdated
        priceHistoryFile {
          bucket
          region
          key
        }
        offerHistoryFile {
          bucket
          region
          key
        }
        repricingEnabled
        repricingStrategy
        activeSupplier
        activeDatePurchased
        activeCostPerUnit
        fbaStorage {
          asin
          fnsku
          fulfillmentCenter
          countryCode
          longestSide
          medianSide
          shortestSide
          measurementUnits
          weight
          weightUnits
          itemVolume
          volumeUnits
          productSizeTier
          averageQuantityOnHand
          averageQuantityPendingRemoval
          estimatedTotalItemVolume
          monthOfCharge
          storageRate
          currency
          estimatedMonthlyStorageFee
          dangerousGoodsStorageType
          eligibleForInventoryDiscount
          qualifiesForInventoryDiscount
        }
        salesHistory {
          last3DaysSales
          last7DaysSales
          last15DaysSales
          last30DaysSales
          last60DaysSales
          last90DaysSales
        }
        conditionString
        totalSupplyQuantity
        inStockSupplyQuantity
        earliestAvailability
        supplyDetail {
          quantity
          supplyType
          earliestAvailableToPick
          latestAvailableToPick
        }
        restockReport {
          Country
          ProductName
          FNSKU
          MerchantSKU
          ASIN
          Condition
          Supplier
          SupplierPartNo
          CurrencyCode
          Price
          SalesLast30Days
          UnitsSoldLast30Days
          TotalUnits
          Inbound
          Available
          FCTransfer
          FCProcessing
          CustomerOrder
          Unfulfillable
          FulfilledBy
          DaysOfSupply
          Alert
          RecommendedReplenQty
          RecommendedShipDate
          InventoryLevelThresholdCurrentMonth
          CurrentMonthVeryLowInvenThreshold
          CurrentMonthMinimumInvenThreshold
          CurrentMonthMaximumInvenThreshold
          CurrentMonthVeryHighInvenThreshold
          InventoryLevelThresholdPublishedNextMonth
          NextMonthVeryLowInvenThreshold
          NextMonthMinimumInvenThreshold
          NextMonthMaximumInvenThreshold
          NextMonthVeryHighInvenThreshold
        }
        AmazonOrderId
        PurchaseDate
        EarliestShipDate
        FulfillmentChannel
        IsFinancialEvent
        IsBusinessOrder
        IsGlobalExpressEnabled
        IsPremiumOrder
        IsPrime
        IsReplacementOrder
        IsSoldByAB
        LastUpdateDate
        LatestShipDate
        MarketplaceId
        NumberOfItemsShipped
        NumberOfItemsUnshipped
        OrderItem {
          ASIN
          IsGift
          IsTransparency
          ConditionId
          ConditionSubtypeId
          ConditionNote
          OrderItemId
          GiftWrapPrice
          GiftWrapTax
          QuantityOrdered
          QuantityShipped
          Title
          SellerSKU
          SerialNumberRequired
          GiftMessageText
        }
        OrderStatus
        OrderTotal {
          Amount
          CurrencyCode
        }
        OrderType
        PaymentMethod
        PaymentMethodDetails {
          PaymentMethodDetail
        }
        SalesChannel
        SellerOrderId
        ShipServiceLevel
        ShipmentServiceLevelCategory
        ShippingAddress {
          AddressLine1
          AddressLine2
          AddressLine3
          City
          CountryCode
          County
          District
          Name
          Municipality
          PostalCode
          StateOrRegion
          isAddressSharingConfidential
        }
        Marketplace
        EstimatedShippingCostUPSData
        OrderZipCode
        OrderState
        OrderCity
        OrderCountry
        settlementId
        settlementStartDate
        settlementEndDate
        depositDate
        totalAmount
        currency
        transactionType
        orderId
        merchantOrderId
        adjustmentId
        shipmentId
        marketplaceName
        amountType
        amountDescription
        amount
        fulfillmentId
        postedDate
        postedDateTime
        orderItemCode
        merchantOrderItemId
        merchantAdjustmentItemId
        sku
        quantityPurchased
        promotionId
        invoiceId
        invoiceSyncToken
        hasQuickbooksInvoice
        amazonClosingFee
        amazonReferralFee
        fbaFees
        fbaFulfillmentFee
        giftWrap
        giftWrapChargeback
        giftWrapCommission
        itemTax
        itemTaxGiftwrap
        itemTaxShipping
        itemTaxWithheld
        itemTaxWithheldOther
        itemTaxWithheldShipping
        orderFulfillmentChannel
        productCharge
        promotionCharge
        shippingHB
        shippingCharge
        shippingChargeback
        skus {
          asin
          title
          sku
          qty
          chargeAmount
        }
        totalFees
        totalPromotionShipping
        totalRefundCommission
        totalRefundExpenses
        totalRefundSales
        totalRevenue
      }
      nextToken
    }
  }
`;
